import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import BikeDetailCard from '../../components/bike-detail-card';
import { withDashboard } from '../../hoc';
import './styles.scss';
import Statsbox from '../../components/stats-box';
import { Link } from 'react-router-dom';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';

function BikeDetailPage() {
  const { bikeId } = useParams<{ bikeId: string }>();

  return (
    <Container>
      <Row className="mb-3">
        <Col sm={3} className="mb-3">
          <Link to={`/servicing-appointments?bike=${bikeId}`}>
            <Statsbox
              bgClass="dark"
              title="Servicing history"
              faIconName="fas fa-tools"
              iconColorClass="text-warning"
            />
          </Link>
        </Col>
        <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level0]}>
          <Col sm={3} className="mb-3">
            <Link to={`/vehicle-logs?bike=${bikeId}&sort=-createdAt`}>
              <Statsbox
                bgClass="dark"
                title="Bike logs"
                faIconName="fas fa-clipboard-list"
                iconColorClass="text-warning"
              />
            </Link>
          </Col>
        </AuthorizeView>
        <Col sm={3} className="mb-3">
          <Link to={`/bikes/${bikeId}/ownership`}>
            <Statsbox
              bgClass="dark"
              title="Ownership logs"
              faIconName="fas fa-user-friends"
              iconColorClass="text-warning"
            />
          </Link>
        </Col>
        <Col sm={3} className="mb-3">
          <Link to={`/bikes/${bikeId}/battery-swaps`}>
            <Statsbox
              bgClass="dark"
              title="Battery swap logs"
              faIconName="fas fa-battery-full"
              iconColorClass="text-warning"
            />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <BikeDetailCard
            id={bikeId as string}
            showBatteryDetail
            showLocationDetail
            showTelemetryDetail
            showCustomerDetail
            showVehiclePrivateDetail
          />
        </Col>
      </Row>
    </Container>
  );
}

export default withDashboard(BikeDetailPage);
