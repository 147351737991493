import React from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import { get } from '../../utils/yatriSiteAxios';

const ExportBookingsModal = ({
  openExportModal,
  setOpenExportModal,
  searchParams
}: {
  openExportModal: boolean;
  setOpenExportModal: (toggle: boolean) => void;
  searchParams: URLSearchParams;
}): JSX.Element | null => {
  function downloadBookingCSV() {
    get(`/bookings/download/csv?${searchParams.toString()}`).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'bookings.csv');
      link.click();
    });
  }

  return openExportModal ? (
    <Modal size="lg" show onHide={() => setOpenExportModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Export bookings</Modal.Title>
      </Modal.Header>
      <Modal.Body>Do you want to export current page to a CSV file?</Modal.Body>
      <ModalFooter>
        <button className="btn btn-primary" onClick={downloadBookingCSV}>
          Export bookings
        </button>
      </ModalFooter>
    </Modal>
  ) : null;
};

export default ExportBookingsModal;
