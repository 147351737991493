import React from 'react';
import { Alert } from '../alerts';
import { Input } from '../form';

const ColorsField = ({
  colors,
  handleChange
}: {
  colors: string[];
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
}): JSX.Element => {
  function onFieldChange(color: string, index?: number) {
    let value = [];
    if (typeof index === 'number') {
      const newColors = [...colors];
      newColors[index] = color;
      value = newColors;
    } else value = [...colors, color];

    const event = {
      currentTarget: {
        name: 'colors',
        value
      }
    };

    handleChange(event as any);
  }

  function removeFieldAtIndex(index: number) {
    const newColors = [...colors];
    newColors.splice(index, 1);
    const value = newColors;

    const event = {
      currentTarget: {
        name: 'colors',
        value
      }
    };

    handleChange(event as any);
  }

  return (
    <div className="body-card">
      <div className="mb-3">
        <div>
          <label>Colors</label>
          <Alert variant="warning" small>
            Adding and updating colors can result in broken user bike images. Make sure to consult
            with admin &amp; mobile dev team beforehand.
          </Alert>

          {colors.length < 1 && (
            <p style={{ marginBottom: '1rem' }} className="text-muted">
              No entry yet. Click on the button below to add one.
            </p>
          )}
        </div>
        {colors.map((color, index) => (
          <div key={index} style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <Input
                noFloat
                onChange={(e: any) => onFieldChange(e.currentTarget.value, index)}
                type="text"
                name="colors"
                value={color}
                placeholder="Color"
                required
              ></Input>
            </div>
            <button
              className="btn btn-chromeless"
              onClick={() => removeFieldAtIndex(index)}
              style={{ marginBottom: '1rem' }}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        ))}
        <button type="button" className="btn btn-dark" onClick={() => onFieldChange('')}>
          <i className="fas fa-plus"></i> Add Color
        </button>
      </div>
    </div>
  );
};

export default ColorsField;
