import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { Battery } from '../../interfaces';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import Paginate from '../../components/paginate';
import { SearchFields } from '../../components/search-box';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';

function BatteriesPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fetching, response] = usePromiseFetch('/batteries?sort=-createdAt', searchParams);

  if (fetching) return <TableLoader />;

  const { batteries, size, total }: { batteries: Battery[]; size: number; total: number } = response
    ? response
    : {
        batteries: [],
        size: 40,
        total: 0
      };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Batteries</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.productionMaintainer]}>
              <div className="head-right">
                <Link to="/batteries/add" className="btn btn-primary">
                  Add battery
                </Link>
              </div>
            </AuthorizeView>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[
              { label: 'Id', name: '_id' },
              { label: 'Identifier', name: 'identifier' }
            ]}
          />
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover>
              <thead className="table-light">
                <tr>
                  <th>Code</th>
                  <th>Identifier</th>
                  <th>Current charge (%)</th>
                  <th>SoH (%)</th>
                  <th>Charging status</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {batteries.map(({ _id, identifier, isCharging, isEnabled, soh, soc }) => (
                  <tr key={_id}>
                    <td>
                      <small>{_id}</small>
                    </td>
                    <td>{identifier}</td>
                    <td>{soc}%</td>
                    <td>{soh}%</td>
                    <td>
                      {isCharging ? (
                        <div className="badge rounded-pill bg-success">Charging</div>
                      ) : (
                        <div className="badge rounded-pill bg-warning">Idle</div>
                      )}
                    </td>
                    <td>
                      {isEnabled ? (
                        <div className="badge rounded-pill bg-success">Enabled</div>
                      ) : (
                        <div className="badge rounded-pill bg-danger">Disabled</div>
                      )}
                    </td>
                    <td>
                      <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.productionMaintainer]}>
                        <Link className="btn btn-sm" to={`/batteries/${_id}/edit`}>
                          <i className="fas fa-pencil-alt"></i>
                        </Link>
                      </AuthorizeView>
                      &nbsp;
                      <Link className="btn btn-sm" to={`/batteries/${_id}/logs`}>
                        <i className="fas fa-digital-tachograph"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(BatteriesPage);
