import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Customer } from '../../interfaces';
import { get } from '../../utils/axios';
import { Alert } from '../alerts';
import Loader from '../commons/Loader';

const fetchCustomer = async (customerId: string, cb: (data: any, error?: any) => void) => {
  get(`/customers/${customerId}`)
    .then(({ data }) => {
      cb(data);
    })
    .catch((err) => {
      cb(null, err);
    });
};

const debouncedFetchCustomer = debounce((query, cb) => {
  fetchCustomer(query, cb);
}, 500);

const CustomerPeepCard = ({
  heading,
  customerId,
  callback
}: {
  heading?: string;
  customerId?: string;
  callback?: (customer?: Customer) => void;
}): JSX.Element => {
  const [customerData, setCustomerData] = useState<{
    customer: null | Customer;
    error: string;
    fetching: boolean;
  }>({
    customer: null,
    error: '',
    fetching: false
  });

  useEffect(() => {
    if (!customerId) return;
    setCustomerData({ ...customerData, fetching: true, error: '' });
    debouncedFetchCustomer(customerId, (data: any, err: any) => {
      if (err)
        setCustomerData({
          ...customerData,
          customer: null,
          fetching: false,
          error: 'Customer with that id not found'
        });
      else
        setCustomerData({
          ...customerData,
          customer: data?.data?.customer,
          fetching: false,
          error: ''
        });
      if (callback) callback(data?.data?.customer);
    });
  }, [customerId]);

  return (
    <div className="body-card">
      {heading && (
        <h5
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
            margin: '-1rem -1rem 1rem',
            padding: ' 0.5rem 1rem',
            borderBottom: '1px solid rgba(0, 0, 0, 0.125)'
          }}
        >
          {heading}
        </h5>
      )}
      {customerData.error && <Alert variant="danger">{customerData.error}</Alert>}

      {customerData.fetching ? (
        <Loader />
      ) : customerData.customer ? (
        <ul className="list-unstyled">
          {customerData.customer.bikes.length <= 0 && (
            <Alert variant="warning">User does not owns a yatri vehicle</Alert>
          )}
          <li>
            <Link className="link" to={`/customers/${customerId}`}>
              <strong>
                <i className="fas fa-user me-2" /> {customerData.customer.name}
              </strong>
            </Link>
          </li>
          <li>
            <i className="fas fa-envelope me-2" /> {customerData.customer.email}
          </li>
          <li>
            <i className="fas fa-phone-alt me-2" /> {customerData.customer.phoneNumber}
          </li>
        </ul>
      ) : (
        'No customer'
      )}
    </div>
  );
};

export default CustomerPeepCard;
