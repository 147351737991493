import React, { SyntheticEvent } from 'react';
import { Settingprops } from '../../interfaces';
import { Input, Form } from '../form';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: Settingprops;
}

const SettingsForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps } = props;
  const { fastChargingCostPerEUnit, acChargingCostPerMinute, currentPetrolPrice } = formProps;
  return (
    <Form submitting={submitting} onSubmit={onSubmit}>
      <div className="body-card mb-3">
        <label>Fast charging cost per unit</label>
        <div className="mb-2 text-muted">
          <small>Provide a fast charging cost per unit for Yatri fast chargers.</small>
        </div>
        <Input
          onChange={handleChange}
          label="Fast charging electric unit cost*"
          type="number"
          name="fastChargingCostPerEUnit"
          value={fastChargingCostPerEUnit}
          placeholder="Fast charging electric unit cost"
          required
        />
      </div>
      <div className="body-card mb-3">
        <label>AC charging cost per minute</label>
        <div className="mb-2 text-muted">
          <small>Provide a AC charging cost per minute for Yatri AC chargers.</small>
        </div>
        <Input
          onChange={handleChange}
          label="AC charging cost per minute*"
          type="number"
          name="acChargingCostPerMinute"
          value={acChargingCostPerMinute}
          placeholder="AC charging cost per minute"
          required
        />
      </div>
      <div className="body-card mb-3">
        <label>Current petrol price*</label>
        <div className="mb-2 text-muted">
          <small>Provide the latest petrol price in Nepal.</small>
        </div>
        <Input
          onChange={handleChange}
          label="Current petrol price*"
          type="number"
          name="currentPetrolPrice"
          value={currentPetrolPrice}
          placeholder="Current petrol price"
          required
        />
      </div>
    </Form>
  );
};

export default SettingsForm;
