import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Bike } from '../../interfaces';
import Loader from '../commons/Loader';
import NotFound from '../commons/NotFound';
import { AxiosResponse } from 'axios';
import { get } from '../../utils/axios';
import { Accordion, Card } from 'react-bootstrap';
import BatteryDetailCard from '../battery-detail-card';
import BikeLocationCard from '../bike-location-card';
import BikeTelemetryCard from '../bike-telemetry-card';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';
import HiddenKey from '../hidden-key';
import CustomerPeepCard from '../customer-peep-card';
import Clipboard from '../clipboard';
import { getDateOnly } from '../../utils/date';
import { camelCaseToSpaceSeprated } from '../../utils';
import { Alert } from '../alerts';
import UpdateVehicleSoftwareVersion from '../../pages/bike-detail/UpdateVehicleSoftwareVersion';

interface Props {
  fullWidth?: boolean;
  id: string;
  showBatteryDetail?: boolean;
  showTelemetryDetail?: boolean;
  showLocationDetail?: boolean;
  showCustomerDetail?: boolean;
  showVehiclePrivateDetail?: boolean;
}

const BikeDetailCard = ({
  fullWidth,
  id,
  showBatteryDetail,
  showTelemetryDetail,
  showLocationDetail,
  showCustomerDetail,
  showVehiclePrivateDetail
}: Props): JSX.Element => {
  const [fetching, setFetching] = useState(true);
  const [bike, setBike] = useState<Bike | null>(null);

  useEffect(() => {
    get(`/bikes/${id}`)
      .then((response: AxiosResponse) => {
        setBike(response.data.data.bike);
        setFetching(false);
      })
      .catch(() => setFetching(false));
  }, []);

  if (fetching) return <Loader />;
  if (!bike) return <NotFound />;

  const {
    _id,
    uuid,
    bikeModel,
    bikeName,
    customer,
    apiKey,
    battery,
    bikeImage,
    mfd,
    vinNumber,
    chargerNumber,
    controllerNumber,
    motorNumber,
    simNumber,
    keyNumber,
    vcuSoftwareVersion,
    createdBy,
    updatedBy,
    configuration,
    isCellularBalanceLow
  } = bike;
  return (
    <div className="row">
      <div className={fullWidth ? '' : 'col-lg-7'}>
        {isCellularBalanceLow && <Alert variant="warning">Low cellular balance</Alert>}

        <Card className="mb-3">
          <Card.Header as="h5">{bikeName}</Card.Header>
          <Card.Img variant="top" src={bikeImage} />
          <Card.Body>
            <ul className="list-unstyled">
              <li>
                <Clipboard inlineTitle="Bike id:" text={_id} />
              </li>
              <li>
                <Clipboard inlineTitle="UUID:" text={uuid} />
              </li>
              <li>
                Bike model:{' '}
                <Link className="link" to={`/bike-models/${bikeModel}`}>
                  {bikeModel}
                </Link>
              </li>
              <li>
                Customer id:{' '}
                {customer ? (
                  <Link className="link" to={`/customers/${customer}`}>
                    {customer}
                  </Link>
                ) : (
                  'NA'
                )}
              </li>
              <li>
                Created by:{' '}
                {createdBy ? (
                  <Link className="link" to={`/staffs?_id=${createdBy}`}>
                    user
                  </Link>
                ) : (
                  'NA'
                )}
              </li>
              {updatedBy && (
                <li>
                  Last updated by:{' '}
                  <Link className="link" to={`/staffs?_id=${updatedBy}`}>
                    user
                  </Link>
                </li>
              )}
            </ul>
          </Card.Body>
        </Card>
      </div>
      <div className="col-lg-5">
        {customer && showCustomerDetail && (
          <div className="mb-3">
            <CustomerPeepCard customerId={customer} />
          </div>
        )}
        {showVehiclePrivateDetail && (
          <div className="mb-3">
            <div className="body-card">
              <ul className="list-unstyled">
                <li>
                  <strong>Created by:</strong>{' '}
                  {createdBy ? (
                    <Link className="link" to={`/staffs?_id=${createdBy}`}>
                      user
                    </Link>
                  ) : (
                    'NA'
                  )}
                </li>
                {updatedBy && (
                  <li>
                    <strong>Last updated by:</strong>{' '}
                    <Link className="link" to={`/staffs?_id=${updatedBy}`}>
                      user
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
        {showBatteryDetail && (
          <div className="mb-3">
            <BatteryDetailCard id={battery as string} />
          </div>
        )}

        {showVehiclePrivateDetail && (
          <div className="mb-3">
            <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1]}>
              <Card>
                <Card.Body>
                  <HiddenKey title="Bike API key (embedded)" hiddenText={apiKey as string} />
                </Card.Body>
              </Card>
            </AuthorizeView>
          </div>
        )}
      </div>

      <div className={fullWidth ? '' : 'col-lg-7'}>
        <ul className="list-group my-3">
          <li className="list-group-item bg-light">
            <h5>Specifications</h5>
          </li>
          <li className="list-group-item">
            MFD: <b>{getDateOnly(mfd as string)}</b>
          </li>
          <li className="list-group-item">
            VIN Number: <b>{vinNumber}</b>
          </li>
          <li className="list-group-item">
            Charger Number: <b>{chargerNumber ?? 'NA'}</b>
          </li>
          <li className="list-group-item">
            Controller Number: <b>{controllerNumber}</b>
          </li>
          <li className="list-group-item">
            Motor Number: <b>{motorNumber}</b>
          </li>
          <li className="list-group-item">
            SIM Number: <b>{simNumber ?? 'NA'}</b>
          </li>
          <li className="list-group-item">
            Key Number: <b>{keyNumber ?? 'NA'}</b>
          </li>
        </ul>
        <ul className="list-group my-3">
          <li className="list-group-item bg-light">
            <strong>Current VCU software version: v{vcuSoftwareVersion?.split('-')[0]}</strong>
            {vcuSoftwareVersion?.split('-')[vcuSoftwareVersion.split('-').length - 1] === 'D' && (
              <div>
                <span className="badge bg-warning rounded-pill">Diagnostic mode</span>
              </div>
            )}
          </li>
          <li className="list-group-item">
            <ul className="neutralize">
              <li>
                <strong>Updated boards:</strong>
                <ul>
                  {vcuSoftwareVersion
                    ?.split('-')[1]
                    .split(',')
                    .map((board) => (
                      <li key={board}>{`${board.split('=')[0]} • ${board.split('=')[1]}`}</li>
                    ))}
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      {showVehiclePrivateDetail && configuration && (
        <div className="col-5">
          <ul className="list-group my-3">
            <li className="list-group-item bg-light">
              <h5>Configuration</h5>
            </li>

            {Object.entries(configuration)
              .filter(([key]) => key !== '_id')
              .map(([key, value]) => (
                <li key={key} className="list-group-item">
                  {camelCaseToSpaceSeprated(key)}: <b>{value}</b>
                </li>
              ))}
          </ul>
        </div>
      )}

      {showTelemetryDetail && <BikeTelemetryCard bikeId={_id as string} />}

      {showLocationDetail && (
        <div className="col-12">
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1]}>
            <br />
            <BikeLocationCard bikeId={_id as string} />
          </AuthorizeView>
        </div>
      )}

      <Accordion className="mt-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <b>
              <i className="fas fa-cog"></i> Advance bike settings
            </b>
          </Accordion.Header>
          <Accordion.Body style={{ padding: 0 }}>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <UpdateVehicleSoftwareVersion bikeId={id as string} />
              </li>
              <li className="list-group-item">
                <Link to={`/bikes/${id}/edit`}>Bike specification</Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default BikeDetailCard;
