import React from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { usePromiseFetch } from '../../hooks';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { SearchFields } from '../../components/search-box';

function LowBalancePage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set('isCellularBalanceLow', 'true');
    setSearchParams(updatedParams, { replace: true });
  }, []);

  // Memoize the params object to prevent unnecessary re-renders
  const fetchParams = React.useMemo(() => {
    const params = Object.fromEntries(searchParams.entries());
    params.isCellularBalanceLow = 'true';
    return new URLSearchParams(params);
  }, [searchParams]);

  const [fetching, response, error] = usePromiseFetch('/lowbalance', fetchParams);

  if (fetching) return <TableLoader />;
  if (!response || error) return <Navigate to="/error" />;

  const { devices, size, total }: { devices: any[]; size: number; total: number } = response
    ? response
    : {
        stations: [],
        size: 40,
        total: 0
      };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Low Balance Devices</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[
              { label: 'id', name: '_id' },
              {
                label: 'Device Type',
                name: 'deviceType',
                type: 'dropDown',
                options: [
                  {
                    label: 'All',
                    value: ''
                  },
                  {
                    label: 'bike',
                    value: 'bike'
                  },
                  {
                    label: 'charger',
                    value: 'charger'
                  }
                ]
              }
            ]}
          />
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover>
              <thead className="table-light">
                <tr>
                  <th>Id</th>
                  <th>CurrentBalance</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {devices.map(({ _id, uuid, type, isEnabled, currentBalanceInSim }) => (
                  <tr key={_id}>
                    <td>
                      <small>{_id}</small>
                    </td>
                    <td>
                      <small>{currentBalanceInSim}</small>
                    </td>
                    <td>
                      <small>{type}</small>
                    </td>
                    <td>
                      <Link
                        className="btn btn-sm"
                        to={`/${type === 'charger' ? 'charge-stations' : 'bikes'}/${_id}`}
                      >
                        <i className="fas fa-eye"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(LowBalancePage);
