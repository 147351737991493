import axios, { AxiosResponse } from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_YATRI_SITE_API_URL
});

export function get(url: string, params = {}, headers = {}): Promise<AxiosResponse> {
  return instance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_YATRI_SITE_API_ACCESS_TOKEN}`,
      ...headers
    }
  });
}

export function post(url: string, body: any): Promise<AxiosResponse> {
  return instance.post(url, body, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_YATRI_SITE_API_ACCESS_TOKEN}`
    }
  });
}

export function patch(url: string, body: any): Promise<AxiosResponse> {
  return instance.patch(url, body, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_YATRI_SITE_API_ACCESS_TOKEN}`
    }
  });
}

export function put(url: string, body: any): Promise<AxiosResponse> {
  return instance.put(url, body, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_YATRI_SITE_API_ACCESS_TOKEN}`
    }
  });
}
