import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { get, patch } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import Loader from '../../components/commons/Loader';
import { Col, Row } from 'react-bootstrap';
import { ChargeStation, EChargeStationStatus } from '../../interfaces';
import ChargeStationForm from '../../components/charge-station-form';
import { formatAxiosError, scrollToTop } from '../../utils';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';

const UpdateChargeStation = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Partial<ChargeStation>>({
    isEnabled: true,
    status: EChargeStationStatus.online,
    softwareVersion: '',
    remarks: ''
  });
  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    get(`/charge-stations/${id}`)
      .then((response: AxiosResponse) => {
        setFormProps(response.data.data.station);
        setFetching(false);
      })
      .catch((error) => {
        setFetchError(error);
        setFetching(false);
      });
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    patch(`/charge-stations/${id}`, formProps)
      .then((response: AxiosResponse) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/charge-stations/${response.data.data.station._id}`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        scrollToTop();
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;

    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  if (fetching) return <Loader />;
  if (fetchError) return <Navigate to="/error" />;

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Update Charge Station</h1>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <ChargeStationForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></ChargeStationForm>{' '}
    </div>
  );
};

export default withDashboard(UpdateChargeStation);
