import React from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const COLORS = ['#00C49F', '#FFBB28', '#db2c2c'];

const VehicleModeOdoChart = ({
  vehicleModel,
  data
}: {
  vehicleModel: string;
  data: { name: string; value: number | string }[];
}): JSX.Element => (
  <div>
    <h5 className="text-center">{vehicleModel} modes odometer (km)</h5>
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data}
          cx="50%"
          cy="50%"
          fill="#8884d8"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  </div>
);

export default VehicleModeOdoChart;
