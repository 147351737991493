import React, { SyntheticEvent } from 'react';
import { BikeModel } from '../../interfaces';
import { Form, SelectInput, Input } from '../form';
import Configurator from './configurator';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: {
    bikeModelId: string;
    batteryId: string;
    bikeColor: string;
    mfd: string;
    vinNumber: string;
    chargerNumber: string | undefined;
    controllerNumber: string;
    motorNumber: string;
    simNumber: string | undefined;
    keyNumber: string | undefined;
    configuration: { [key: string]: any };
  };
  bikeModels: BikeModel[];
}

const BikeForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, bikeModels, submitting, formProps } = props;
  const {
    bikeModelId,
    batteryId,
    bikeColor,
    mfd,
    vinNumber,
    chargerNumber,
    controllerNumber,
    motorNumber,
    simNumber,
    keyNumber,
    configuration
  } = formProps;

  const colorOptions = bikeModels
    .find((b) => b._id === bikeModelId)
    ?.colors?.map((color: any) => ({ label: color, value: color }));

  return (
    <Form submitting={submitting} onSubmit={onSubmit}>
      <div className="body-card">
        <SelectInput
          label="Bike model*"
          name="bikeModelId"
          value={bikeModelId}
          onChange={handleChange}
          options={[
            { label: '-', value: '' },
            ...bikeModels.map((model) => ({
              label: model._id as string,
              value: model._id as string
            }))
          ]}
          required
        />
        <SelectInput
          label="Bike Color*"
          name="bikeColor"
          value={bikeColor}
          onChange={handleChange}
          options={
            colorOptions
              ? [{ label: '-', value: '' }, ...colorOptions]
              : [{ label: '-', value: '' }]
          }
          required
        />
      </div>
      <div className="body-card">
        <Input
          onChange={handleChange}
          label="Manufactured date*"
          type="date"
          name="mfd"
          value={mfd}
          required
        />
      </div>
      <div className="body-card">
        <Input
          onChange={handleChange}
          label="Battery id*"
          type="text"
          name="batteryId"
          value={batteryId}
          placeholder="Battery id"
          required
        />
        <Input
          onChange={handleChange}
          label="VIN number*"
          type="text"
          name="vinNumber"
          value={vinNumber}
          placeholder="VIN number"
          required
        />
        <Input
          onChange={handleChange}
          label="Charger number*"
          type="text"
          name="chargerNumber"
          value={chargerNumber}
          placeholder="Charger number"
        />
        <Input
          onChange={handleChange}
          label="Controller number*"
          type="text"
          name="controllerNumber"
          value={controllerNumber}
          placeholder="Controller number"
          required
        />
        <Input
          onChange={handleChange}
          label="Motor number*"
          type="text"
          name="motorNumber"
          value={motorNumber}
          placeholder="Motor number"
          required
        />
        <Input
          onChange={handleChange}
          label="SIM number"
          type="text"
          name="simNumber"
          value={simNumber}
          placeholder="SIM number"
        />
        <Input
          onChange={handleChange}
          label="Digital key number"
          type="text"
          name="keyNumber"
          value={keyNumber}
          placeholder="Key number"
        />
      </div>
      <Configurator
        configuration={configuration}
        handleChange={handleChange}
        bikeModelId={bikeModelId}
      />
    </Form>
  );
};

export default BikeForm;
