import React, { SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import { Checkbox, Form, Input } from '../../components/form';
import CustomModal from '../../components/modal';
import { formatAxiosError } from '../../utils';
import { post } from '../../utils/axios';

interface Props {
  servicingId: string;
  serviceCenterId: string;
  status: string;
}
const AppointmentCompleteButton = ({
  servicingId,
  serviceCenterId,
  status
}: Props): JSX.Element => {
  const [formProps, setFormProps] = useState({ currentOdo: 0, notifyCustomer: false });
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    post(`/service-centers/${serviceCenterId}/appointments/${servicingId}/payment`, formProps)
      .then(() => location.reload())
      .catch((error) => {
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        setSubmitting(false);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <CustomModal
      ModalLauncher={(props: any) => (
        <button
          className="btn btn-success"
          disabled={status === 'Cancelled' || status === 'Completed'}
          {...props}
        >
          Complete Appointment
        </button>
      )}
    >
      <Form onSubmit={onSubmit} submitting={submitting}>
        <div className="form-group">
          <Checkbox
            id="notifyCustomer"
            onChange={handleChange}
            label="Send completion notification"
            name="notifyCustomer"
            type="checkbox"
            checked={formProps.notifyCustomer}
          ></Checkbox>
        </div>

        <Input
          onChange={handleChange}
          label="Current odometer"
          type="number"
          name="currentOdo"
          value={formProps.currentOdo}
          placeholder="Current odometer"
          required
        ></Input>
      </Form>
    </CustomModal>
  );
};

export default AppointmentCompleteButton;
