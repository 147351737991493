import React, { SyntheticEvent, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../actions';
import { pushToast } from '../../actions/toast';
import { ROLES } from '../../constants';
import { User } from '../../interfaces';
import { selectAuth } from '../../selectors';
import { formatAxiosError } from '../../utils';
import { patch } from '../../utils/axios';
import { Form, Input } from '../form';

const ProfileForm = (): JSX.Element => {
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();

  const [formProps, setFormProps] = useState<User>(auth as User);
  const [submitting, setSubmitting] = useState(false);
  const isAdmin = auth?.roles?.includes(ROLES.admin);

  function handleOnChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;

    setFormProps({ ...formProps, [name]: value });
  }

  function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);

    patch('/users/current-user', formProps)
      .then(({ data: { data } }) => {
        dispatch(
          fetchUser(() => {
            setSubmitting(false);
          }, data.user)
        );
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
      })
      .catch((error) => {
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        setSubmitting(false);
      });
  }

  return (
    <>
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">{auth?.name}</h1>

          {auth?.roles?.map((role, i) => (
            <span key={i} className="badge rounded-pill bg-info" style={{ marginRight: 4 }}>
              {role.split('ROLE_')[1]}
            </span>
          ))}
        </div>
        <div className="head-right"></div>
      </div>

      <h4 className="section-title">Profile</h4>
      <Row>
        <Col>
          <Form submitting={submitting} onSubmit={handleSubmit}>
            <div className="body-card">
              <Input
                name="name"
                value={formProps.name}
                label="Full name*"
                placeholder="Full name"
                onChange={handleOnChange}
                disabled={!isAdmin}
              />
              {!isAdmin && (
                <p className="alert alert-warning">
                  <small>
                    <i className="fas fa-exclamation-circle"></i> Contact admin to update your full
                    name
                  </small>
                </p>
              )}
              <Input
                type="email"
                name="email"
                value={formProps.email}
                label="Email*"
                placeholder="Email"
                onChange={handleOnChange}
                disabled={!isAdmin}
              />
              {!isAdmin && (
                <p className="alert alert-warning">
                  <small>
                    <i className="fas fa-exclamation-circle"></i> Contact admin to update your email
                    address
                  </small>
                </p>
              )}
              <Input
                type="number"
                name="phoneNumber"
                value={formProps.phoneNumber}
                label="Phone number*"
                placeholder="Phone number"
                onChange={handleOnChange}
              />
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ProfileForm;
