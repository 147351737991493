import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { usePromiseFetch } from '../../hooks';
import { ChartType } from '../../types';
import { AddChart } from '../charts';
import Loader from '../commons/Loader';
import { ErrorMessage } from '../commons/NotFound';

const MonthlyChart = ({
  label,
  currentYear,
  url,
  urlParams,
  children,
  type = 'Line',
  stroke,
  fill
}: {
  label: string;
  currentYear: number;
  url: string;
  urlParams?: any;
  children: React.ReactNode;
  type?: ChartType;
  stroke?: string;
  fill?: string;
}): JSX.Element => {
  const [fetching, response, error] = usePromiseFetch(url, urlParams);

  if (fetching)
    return (
      <div className="body-card">
        <Loader />
      </div>
    );
  if (!response || error) return <ErrorMessage />;

  const { total } = response;

  return (
    <div>
      <div className="body-card">
        {children}
        <Row>
          <Col>
            <div style={{ backgroundColor: '#fff' }}>
              <AddChart
                type={type}
                label={`${total
                  .reduce((a: any, b: any) => a + b.total, 0)
                  .toFixed(2)} ${label} in ${currentYear}`}
                xLabel="month"
                yLabel="total"
                data={total}
                stroke={stroke}
                fill={fill}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MonthlyChart;
