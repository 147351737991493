import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Container, Row, Col, Accordion, Badge } from 'react-bootstrap';
import { get, del } from '../../utils/axios';
import Loader from '../../components/commons/Loader';
import { Faq } from '../../interfaces';
import { withDashboard } from '../../hoc';

function FaqPage(): JSX.Element {
  const [fetching, setFetching] = useState(true);
  const [faqs, setFaqs] = useState<Faq[]>([]);

  useEffect(() => {
    get('/faqs')
      .then((response: AxiosResponse) => {
        setFaqs(response.data.data.faqs);
        setFetching(false);
      })
      .catch(() => setFetching(false));
  }, []);

  function handleFaqDelete(id: string) {
    del(`/faqs/${id}`);
    setFaqs(faqs.filter((f) => f._id !== id));
  }

  if (fetching) return <Loader />;

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">FAQs</h1>
            </div>
            <div className="head-right">
              <Link to="/faqs/add" className="btn btn-primary">
                Add FAQ
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion defaultActiveKey="0">
            {faqs.map((faq, i) => (
              <Accordion.Item eventKey={String(i)} key={faq._id}>
                <Accordion.Header>
                  <div>
                    <div>{faq.question}</div>
                    <div style={{ display: 'flex', marginTop: '.8rem' }}>
                      {faq?.tags?.map((tag) => (
                        <Badge style={{ marginRight: 4 }} key={tag} pill>
                          {tag}
                        </Badge>
                      ))}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {faq.answer}
                  <div className="d-flex" style={{ justifyContent: 'flex-end', marginTop: '1rem' }}>
                    <Link
                      style={{ marginRight: 8 }}
                      className="btn btn-white outline btn-sm"
                      to={`/faqs/${faq._id}/edit`}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </Link>
                    <button
                      onClick={() => handleFaqDelete(faq._id as string)}
                      className="btn btn-danger btn-sm"
                    >
                      <i className="fas fa-trash-alt text-white"></i>
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default withDashboard(FaqPage);
