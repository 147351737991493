import React, { useState } from 'react';

const Clipboard = ({ inlineTitle, text }: { inlineTitle?: string; text: string }): JSX.Element => {
  const [copied, setCopied] = useState(false);

  return (
    <div className="d-flex">
      {inlineTitle && <span className="me-1">{inlineTitle}</span>}
      <div
        className="clickable d-flex"
        style={{ flexWrap: 'nowrap' }}
        onClick={() => {
          navigator.clipboard.writeText(text);
          setCopied(true);
        }}
      >
        <span className="me-2" style={{ wordBreak: 'break-all' }}>
          {text}
        </span>{' '}
        <i
          className={`fas fa-clipboard-${copied ? 'check' : 'list'}`}
          style={{ fontSize: '1.2rem' }}
        ></i>
      </div>
    </div>
  );
};

export default Clipboard;
