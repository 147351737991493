import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import FaqForm from '../../components/faq-form';
import { Faq } from '../../interfaces';

const AddFaq = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Faq>({
    question: '',
    answer: '',
    tags: []
  });
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);

    post('/faqs', formProps)
      .then(() => {
        setSubmitting(false);
        navigate(`/faqs`);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value, checked } = event.currentTarget;

    if (name === 'tags') {
      let tags = [];
      if (checked) tags = [...formProps.tags, value];
      else tags = formProps.tags?.filter((t) => t !== value) as string[];

      return setFormProps({
        ...formProps,
        tags
      });
    }

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Add FAQ</h1>
        </div>
        <div className="head-right"></div>
      </div>
      <FaqForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></FaqForm>
    </div>
  );
};

export default withDashboard(AddFaq);
