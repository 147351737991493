import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { Servicing } from '../../interfaces';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import Paginate from '../../components/paginate';
import { SearchFields } from '../../components/search-box';
import ServicingAppointmentsTable from '../../components/servicing-appointments-table';

function ServicingAppointmentsPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fetching, response, error] = usePromiseFetch(
    `/servicings/all?sort=-servicingDate`,
    searchParams
  );

  if (fetching) return <TableLoader />;
  if (!response || error) return <Navigate to="/error" />;

  const {
    appointments,
    size,
    total
  }: { appointments: Servicing[]; size: number; total: number } = response
    ? response
    : { appointments: [], size: 40, total: 0 };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">All appointments</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[
              { label: 'Bike id', name: 'bike' },
              { label: 'Customer id', name: 'customer' },
              {
                label: 'Type',
                name: 'servicingType',
                type: 'dropDown',
                options: [
                  {
                    label: 'All',
                    value: ''
                  },
                  {
                    label: 'Maintenance',
                    value: 'Maintenance'
                  },
                  {
                    label: 'Servicing',
                    value: 'Servicing'
                  }
                ]
              },
              { label: 'Starting date', name: 'servicingDate[gte]', type: 'date' },
              { label: 'Ending date', name: 'servicingDate[lte]', type: 'date' }
            ]}
          />
        </Col>
      </Row>

      <div className="table-box">
        <Row>
          <Col>
            <ServicingAppointmentsTable appointments={appointments} />
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <Paginate
            totalPage={totalPage}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default withDashboard(ServicingAppointmentsPage);
