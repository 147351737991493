import React from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import Paginate from '../../components/paginate';
import { BikeOwnershipLog } from '../../interfaces';

function BikeOwnershipLogsPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const { bikeId } = useParams<{ bikeId: string }>();
  const [fetching, response] = usePromiseFetch(`/bike-ownership?bike=${bikeId}`, searchParams);

  if (fetching) return <TableLoader />;

  const {
    ownerships,
    size,
    total
  }: { ownerships: BikeOwnershipLog[]; size: number; total: number } = response
    ? response
    : {
        ownerships: [],
        size: 40,
        total: 0
      };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Bike ownership logs</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover variant="dark">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Bike</th>
                  <th>Current owner</th>
                  <th>Previous owner</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {ownerships.map(({ _id, bike, currentOwner, previousOwner, transferDate }) => (
                  <tr key={_id}>
                    <td>
                      <small>{_id}</small>
                    </td>
                    <td>
                      <Link className="link" to={`/bikes/${bike}`}>
                        {bike}
                      </Link>
                    </td>
                    <td>
                      <Link className="link" to={`/customers/${currentOwner}`}>
                        {currentOwner}
                      </Link>
                    </td>
                    <td>
                      {previousOwner ? (
                        <Link className="link" to={`/customers/${previousOwner}`}>
                          {previousOwner}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>{new Date(transferDate).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(BikeOwnershipLogsPage);
