import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerForm from '../../components/customer-form';
import { post } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import { AxiosResponse } from 'axios';
import { Customer } from '../../interfaces';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';

const AddCustomer = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Partial<Customer>>({
    email: '',
    dob: '',
    phoneNumber: '',
    licenseNumber: 'NA',
    name: ''
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    post('/customers', formProps)
      .then((response: AxiosResponse) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/customers/${response.data.data.customer._id}`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Add Customer</h1>
        </div>
        <div className="head-right"></div>
      </div>
      <CustomerForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></CustomerForm>
    </div>
  );
};

export default withDashboard(AddCustomer);
