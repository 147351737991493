import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { get, patch } from '../../utils/yatriSiteAxios';
import { withDashboard } from '../../hoc';
import Loader from '../../components/commons/Loader';
import { Col, Row } from 'react-bootstrap';
import { Booking } from '../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../../selectors';
import BookingForm from '../../components/booking-form';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';

const UpdateBookingPage = (): JSX.Element => {
  const auth = useSelector(selectAuth);
  const [formProps, setFormProps] = useState<Booking>({
    bookingStatus: 'Active',
    status: 'Not delivered',
    etd: '',
    refunded: true,
    remarks: '',
    statusVinAssignment: 'In Progress',
    statusChassisCompletion: 'In Progress',
    statusAssemblyStart: 'In Progress',
    statusAssemblyCompletion: 'In Progress',
    statusQACheck: 'In Progress',
    statusDeliveryReady: 'In Progress'
  });
  const [submitting, setSubmitting] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    get(`/bookings/${id}`)
      .then((response: AxiosResponse) => {
        setFormProps({ ...response.data.data.booking, lastUpdatedBy: auth?.id });
        setFetching(false);
      })
      .catch((error) => {
        setFetchError(error);
        setFetching(false);
      });
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);

    patch(`/bookings/${id}`, formProps)
      .then(() => {
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/bookings/${id}`);
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        setSubmitting(false);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  if (fetching) return <Loader />;
  if (fetchError) return <Navigate to="/error" />;

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Update Booking</h1>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <BookingForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></BookingForm>
    </div>
  );
};

export default withDashboard(UpdateBookingPage);
