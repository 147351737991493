import React, { useState } from 'react';
import MonthlyChart from '../../components/monthly-chart';

const YearlyBikeManufacturedChart = (): JSX.Element => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  function increment() {
    setCurrentYear(currentYear - 1);
  }

  function decrement() {
    setCurrentYear(currentYear + 1);
  }

  return (
    <>
      <MonthlyChart
        type="Bar"
        label="total bikes manufactured"
        url={`/stats/bikes/manufactured/monthly-total/year/${currentYear}`}
        currentYear={currentYear}
        fill="#b50b41"
      >
        <h4 className="section-title">
          <button className="btn btn-light" onClick={increment}>
            <i className="fas fa-chevron-left"></i>
          </button>
          &nbsp;
          {currentYear}
          &nbsp;
          <button className="btn btn-light" onClick={decrement}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </h4>
      </MonthlyChart>
    </>
  );
};

export default YearlyBikeManufacturedChart;
