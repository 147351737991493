import React from 'react';
import { useSelector } from 'react-redux';
import LoginPage from './login';
import { selectAuth } from '../selectors';
import HomePage from './home';

const StartPage = (): JSX.Element => {
  const auth = useSelector(selectAuth);
  return auth ? <HomePage /> : <LoginPage />;
};

export default StartPage;
