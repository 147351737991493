import React, { useState, useEffect } from 'react';
import { Battery } from '../../interfaces';
import Loader from '../commons/Loader';
import NotFound from '../commons/NotFound';
import { AxiosResponse } from 'axios';
import { get } from '../../utils/axios';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface Props {
  id: string;
}

const BatteryDetailCard = ({ id }: Props): JSX.Element => {
  const [fetching, setFetching] = useState(true);
  const [battery, setBattery] = useState<Battery | null>(null);

  useEffect(() => {
    if (!id) return;
    get(`/batteries/${id}`)
      .then((response: AxiosResponse) => {
        setBattery(response.data.data.battery);
        setFetching(false);
      })
      .catch(() => setFetching(false));
  }, []);

  if (fetching) return <Loader />;
  if (!battery) return <NotFound />;

  const { _id, identifier, soc, isCharging, updatedAt } = battery;
  return (
    <Card>
      <Card.Header as="h5">
        <div>
          Battery detail{' '}
          {isCharging ? (
            <div className="badge bg-success">Charging</div>
          ) : (
            <div className="badge bg-warning">Idle</div>
          )}
        </div>
        <small style={{ fontSize: '0.9rem' }}>
          Last updated at:{' '}
          <strong>{updatedAt ? new Date(updatedAt).toLocaleString() : 'NA'}</strong>
        </small>
      </Card.Header>

      <Card.Body>
        <ul className="list-unstyled">
          <li>
            Battery id:{' '}
            <Link className="link" to={`/batteries?_id=${_id}`}>
              {_id}
            </Link>
          </li>
          <li>Battery identifier: {identifier}</li>
          {/* <li>SoH: {soh}%</li> */}
          <li>Current charge: {soc}%</li>
        </ul>
      </Card.Body>
    </Card>
  );
};

export default BatteryDetailCard;
