import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { usePromiseFetch } from '../../hooks';
import { TableLoader } from '../../components/commons/Loader';
import { BikeModel } from '../../interfaces';
import { withDashboard } from '../../hoc';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';

function BikeModelsPage(): JSX.Element {
  const [fetching, response, error] = usePromiseFetch('/bike-models');

  if (fetching) return <TableLoader />;
  if (!response || error) return <Navigate to="/error" />;

  const {
    bikeModels,
    size,
    total
  }: { bikeModels: BikeModel[]; size: number; total: number } = response
    ? response
    : {
        bikeModels: [],
        size: 40,
        total: 0
      };

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Bike Models</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <AuthorizeView
              authorizedRoles={[ROLES.admin, ROLES.level0, ROLES.productionMaintainer]}
            >
              <div className="head-right">
                <Link to="/bike-models/add" className="btn btn-primary">
                  Add new model
                </Link>
              </div>
            </AuthorizeView>
          </div>
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover>
              <thead className="table-light">
                <tr>
                  <th>Model</th>
                  <th>Name</th>
                  <th>Make year</th>
                  <th>Enabled</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {bikeModels.map(({ _id, name, isEnabled, makeYear }) => (
                  <tr key={_id}>
                    <td>{_id}</td>
                    <td>{name}</td>
                    <td>{makeYear}</td>
                    <td>
                      {isEnabled ? (
                        <div className="badge rounded-pill bg-success">Enabled</div>
                      ) : (
                        <div className="badge rounded-pill bg-danger">Disabled</div>
                      )}
                    </td>
                    <td>
                      <Link className="btn btn-sm" to={`/bike-models/${_id}`}>
                        <i className="fas fa-eye"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(BikeModelsPage);
