import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Loader from '../../components/commons/Loader';
import { ChargeStation } from '../../interfaces';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import ChargeStationTransactions from '../../components/charge-station-transactions';
import MonthlyChargeStationPayment from './MonthlyChargeStationPayment';
import MonthlyChargeStationEnergyConsumed from './MonthlyChargeStationEnergyConsumed';

function ChargeStationStatPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const [fetching, response, error] = usePromiseFetch(`/charge-stations/${id}`);

  if (fetching) return <Loader />;
  if (!response || error) return <Navigate to="/error" />;

  const chargeStation = response.station as ChargeStation;

  const { _id } = chargeStation;

  return (
    <Container>
      <div className="head-panel d-flex">
        {/* <div>
          <h1 className="page-title">{name}</h1>
          <div>
            <i className="fas fa-map-marker-alt" /> &nbsp;
            {address}
          </div>
        </div> */}
      </div>

      <Row>
        <Col className="mb-3 col-lg-6 col-12">
          <MonthlyChargeStationPayment chargeStationId={_id as string} />
        </Col>
        <Col className="mb-3 col-lg-6 col-12">
          <MonthlyChargeStationEnergyConsumed chargeStationId={_id as string} />
        </Col>
      </Row>

      <Row>
        <ChargeStationTransactions />
      </Row>
    </Container>
  );
}

export default withDashboard(ChargeStationStatPage);
