import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BikeCrashLogs from '../../components/bike-carsh-logs';
import {
  CustomersCount,
  ChargeStationsCount,
  WalletLoadSum,
  ServiceCentersCount,
  TotalVehicleOdo,
  TotalVehicleEnergyConsumption
} from '../../components/dashboard';
import { ROLES } from '../../constants';
import { withDashboard } from '../../hoc';
import AuthorizeView from '../../hoc/authorizeView';
import AllChargeStationMonthlyPayment from './AllChargeStationMonthlyPayment';
import AllChargeStationsMonthlyEnergyConsumed from './AllChargeStationsMonthlyEnergyConsumed';
import AllTopupTransactionsChart from './AllTopupTransactionsChart';
import AllTransactionsChart from './AllTransactionsChart';
import P1ModeOdoChart from './P1ModeOdoChart';
import './styles.scss';
import YearlyBikeManufacturedChart from './YearlyBikeManufacturedChart';

const FallbackHomePage = () => (
  <div className="body-card">
    <p>
      We at YATRI care deeply about the environment we live in and believe that clean individual
      mobility is an important piece in solving the problem of urban air pollution. We also believe
      that good design is critical in defining the public awareness of electric mobility since our
      goal is larger than catering solely to environmentalists. We aim to cater to the general
      public who may care more about owning a good product than about protecting the environment.
    </p>
    <br />
    <p>
      <i>
        <strong>
          &quot;Many of life&apos;s failures are people who did not realize how close they were to
          success when they gave up.&quot;
        </strong>
      </i>
      - Thomas Edison
    </p>
  </div>
);

const HomePage = (): JSX.Element => (
  <Container id="dashboard">
    <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1]} Fallback={FallbackHomePage}>
      <Row className="box-panel">
        <CustomersCount />
        <ChargeStationsCount />
        <ServiceCentersCount />
        <TotalVehicleOdo bikeModel="P1" />
        <TotalVehicleEnergyConsumption bikeModel="P1" />
      </Row>

      <Row className="mb-3">
        <Col>
          <YearlyBikeManufacturedChart />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 col-lg-6 col-12">
          <AllTopupTransactionsChart />
        </Col>
        <Col className="mb-3 col-lg-6 col-12">
          <AllTransactionsChart />
        </Col>
      </Row>

      <Row>
        <Col className="mb-3 col-lg-6 col-12">
          <AllChargeStationMonthlyPayment />
        </Col>
        <Col className="mb-3 col-lg-6 col-12">
          <AllChargeStationsMonthlyEnergyConsumed />
        </Col>
      </Row>

      <Row>
        <Col className="mb-3 col-lg-5 col-12">
          <P1ModeOdoChart />
        </Col>
        <Col className="mb-3 col-lg-7 col-12">
          <div>
            <div className="body-card" style={{ height: 351, overflow: 'scroll' }}>
              <div className="clearfix">
                <Link to="/crash-logs" className="link float-end">
                  Show all
                </Link>
              </div>
              <BikeCrashLogs hideControls />
            </div>
          </div>
        </Col>
      </Row>
    </AuthorizeView>
  </Container>
);

export default withDashboard(HomePage);
