import React from 'react';
import VehicleModeOdoChart from '../../components/charts/VehicleModeOdoChart';
import Loader from '../../components/commons/Loader';
import { ErrorMessage } from '../../components/commons/NotFound';
import { usePromiseFetch } from '../../hooks';

const P1ModeOdoChart = (): JSX.Element => {
  const [fetching, response, error] = usePromiseFetch(`/stats/bikes/model/${'P1'}/mode-odo`);

  if (fetching)
    return (
      <div className="body-card">
        <Loader />
      </div>
    );
  if (!response || error) return <ErrorMessage />;

  const { suste, thikka, babbal } = response;

  return (
    <div className="body-card">
      <VehicleModeOdoChart
        vehicleModel="P1"
        data={[
          { name: 'Suste', value: suste },
          { name: 'Thikka', value: thikka },
          { name: 'Babbal', value: babbal }
        ]}
      />
    </div>
  );
};

export default P1ModeOdoChart;
