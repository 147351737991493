import React, { SyntheticEvent, useState } from 'react';
import { Alert, Col } from 'react-bootstrap';
import ChargeStationOwnershipForm from '../../../components/ownership-form';
import CustomModal from '../../../components/modal';
import Statsbox from '../../../components/stats-box';
import { ROLES } from '../../../constants';
import AuthorizeView from '../../../hoc/authorizeView';
import { post } from '../../../utils/axios';

const AssignChargeStationOwnerModal = ({
  chargeStationId,
  existingOwner = ''
}: {
  chargeStationId: string;
  existingOwner: string;
}): JSX.Element => {
  const [formProps, setFormProps] = useState({
    owner: existingOwner
  });

  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setError('');
    setSubmitting(true);
    post(`/charge-stations/${chargeStationId}/owner/assign`, formProps)
      .then(() => {
        setSubmitting(false);
        document.location.reload();
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.response.data.message);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.manager]}>
      <Col sm={3} className="mb-3">
        <CustomModal
          headerText="Assign charge station ownership"
          ModalLauncher={(props) => (
            <button {...props} className="btn-chromeless w-100">
              <Statsbox
                bgClass="dark"
                title="Station ownership"
                faIconName="fas fa-user-plus"
                iconColorClass="text-primary"
              />
            </button>
          )}
        >
          {error && <Alert variant="danger">{error}</Alert>}

          <ChargeStationOwnershipForm
            isUpdate={existingOwner ? true : false}
            submitting={submitting}
            onSubmit={onSubmit}
            handleChange={handleChange}
            formProps={formProps}
          />
        </CustomModal>
      </Col>
    </AuthorizeView>
  );
};

export default AssignChargeStationOwnerModal;
