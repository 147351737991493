import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { usePromiseFetch } from '../../hooks';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { Bike } from '../../interfaces';
import Paginate from '../../components/paginate';
import { SearchFields } from '../../components/search-box';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';
import { getDateOnly } from '../../utils/date';

function BikesPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fetching, response] = usePromiseFetch('/bikes?sort=-createdAt', searchParams);

  if (fetching) return <TableLoader />;

  const { bikes, size, total }: { bikes: Bike[]; size: number; total: number } = response
    ? response
    : { bikes: [], size: 40, total: 0 };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Bikes</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <div className="head-right">
              <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.productionMaintainer]}>
                <Link to="/bikes/add" className="btn btn-primary">
                  Add new bike
                </Link>
              </AuthorizeView>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[
              { label: 'Bike id', name: '_id' },
              { label: 'UUID', name: 'uuid' },
              { label: 'VIN no', name: 'vinNumber' },
              { label: 'Battery id', name: 'battery' },
              { label: 'Sim no', name: 'simNumber' },
              { label: 'Controller no', name: 'controllerNumber' },
              { label: 'Motor no', name: 'motorNumber' }
            ]}
          />
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover>
              <thead className="table-light">
                <tr>
                  <th></th>
                  <th>Id</th>
                  <th>Bike Model</th>
                  <th>VIN Number</th>
                  <th>Status</th>
                  <th>MFD</th>
                  <th>Enabled</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {bikes.map(({ _id, vinNumber, customer, bikeModel, mfd, isEnabled, bikeImage }) => (
                  <tr key={_id}>
                    <td width={50}>{<img src={bikeImage} width={50} />}</td>
                    <td>
                      <small>{_id}</small>
                    </td>
                    <td>
                      <Link className="link" to={`/bike-models/${bikeModel}`}>
                        {bikeModel}
                      </Link>
                    </td>
                    <td>{vinNumber}</td>
                    <td>
                      <div
                        className={`badge rounded-pill ${!customer ? 'bg-primary' : 'bg-success'}`}
                      >
                        {!customer ? 'In stock' : 'Sold out'}
                      </div>
                    </td>
                    <td>{getDateOnly(mfd as string)}</td>
                    <td>
                      {isEnabled ? (
                        <div className="badge rounded-pill bg-success">Enabled</div>
                      ) : (
                        <div className="badge rounded-pill bg-danger">Disabled</div>
                      )}
                    </td>
                    <td>
                      <Link className="btn btn-sm" to={`/bikes/${_id}`}>
                        <i className="fas fa-eye"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(BikesPage);
