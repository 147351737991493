import React, { useState } from 'react';
import { URLSearchParamsInit } from 'react-router-dom';
import { camelCaseToSpaceSeprated } from '../../utils';
import { SelectInput, Input } from '../form';

interface Props {
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void;
  fields: {
    label: string;
    name: string;
    type?: 'text' | 'dropDown' | 'date';
    options?: { label: string; value: any }[];
  }[];
}

export function SearchFields({ fields, searchParams, setSearchParams }: Props): JSX.Element {
  const [showFilter, setShowFilter] = useState(false);

  const defaultFormProps: any = {};
  fields.forEach(({ name }) => (defaultFormProps[name] = searchParams.get(name) ?? ''));

  const [formProps, setFormProps] = useState(defaultFormProps);

  function handleOnChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    setFormProps({ ...formProps, [name]: value });
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = { ...formProps };
    Object.keys(formData).forEach(function (key) {
      if (formData[key] === '' || formData[key] === 'All') delete formData[key];
    });

    setSearchParams({ ...searchParams, ...formData });
  }

  function removeFromSearchParams(key: string) {
    searchParams.delete(key);
    setSearchParams(searchParams);
  }

  function renderSearchInputField({
    field: { label, name, type = 'text', options }
  }: {
    field: typeof fields[0];
  }) {
    if (type === 'dropDown')
      return (
        <SelectInput
          noFloat
          label={label}
          options={options?.map(({ value, label }) => ({ label, value })) as any}
          onChange={handleOnChange as any}
          name={name}
          value={formProps[name]}
        />
      );
    return (
      <Input
        noFloat
        type={type}
        label={label}
        name={name}
        onChange={handleOnChange}
        value={formProps[name]}
        className="form-control"
        placeholder={label}
        autoComplete="off"
      />
    );
  }

  return (
    <div>
      <div className="mb-3" style={{ height: showFilter ? '100%' : 0, overflow: 'hidden' }}>
        <form onSubmit={handleSubmit}>
          {/* <div className="clearfix">
            <button type="submit" className="btn btn-sm btn-dark mb-3 float-end">
              Apply filters
            </button>
          </div> */}
          <div className="row g-3 align-items-end">
            {fields.map((field) => (
              <div key={field.label} className="col-lg col-sm-6">
                {renderSearchInputField({ field })}
              </div>
            ))}
            <div className="col-lg col-sm-6">
              <div className="form-group">
                <button type="submit" className="btn btn-dark">
                  Apply filters
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="d-grid gap-2 d-md-block mb-3">
        <button
          type="button"
          className="btn btn-white outline me-2"
          onClick={() => setShowFilter(!showFilter)}
        >
          <i className={`fas fa-angle-${showFilter ? 'up' : 'down'} me-2`} />
          Filters
        </button>
        {Object.entries(defaultFormProps)
          .filter(([, value]) => value !== '')
          .map(([key]) => key)
          .map((filter, i) => (
            <button
              key={i}
              onClick={() => removeFromSearchParams(filter)}
              className="btn btn-outline-primary me-2"
            >
              {camelCaseToSpaceSeprated(filter).replace('[', ' [')}
              <i className="fas fa-times ms-4"></i>
            </button>
          ))}
      </div>
    </div>
  );
}
