import React, { SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { signIn } from '../../actions';
import { Form, Input } from '../../components/form';
import { Container, Row, Col, Alert } from 'react-bootstrap';

function LoginPage(): JSX.Element {
  const [formProps, setFormProps] = useState({
    email: '',
    password: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setError('');
    setSubmitting(true);
    dispatch(
      signIn(formProps, (error: string) => {
        setError(error);
        setSubmitting(false);
      })
    );
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>): void {
    const { value, name } = event.currentTarget;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col sm={6} style={{ marginTop: 100 }}>
          <div className="text-center">
            <img
              style={{ maxWidth: 250, marginBottom: '2rem' }}
              src="/assets/images/logo.png"
              className="img-fluid"
            />
          </div>
          <div
            style={{
              boxShadow: '0 0 1px rgb(0 0 0 / 5%), 0 20px 45px -10px rgb(0 0 0 / 10%)',
              background: '#fff',
              borderRadius: 5,
              padding: '2rem'
            }}
          >
            {error && <Alert variant="danger">{error}</Alert>}
            <Form
              submitting={submitting}
              onSubmit={handleSubmit}
              btnStyle={{ background: '#30D5C8', borderColor: '#30D5C8', width: '100%' }}
              btnText="Login"
            >
              <Input
                onChange={handleChange}
                label="Email"
                type="email"
                name="email"
                value={formProps.email}
                placeholder="Email"
                required
              ></Input>

              <Input
                onChange={handleChange}
                label="Password"
                type="password"
                name="password"
                value={formProps.password}
                placeholder="Password"
                required
              ></Input>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
