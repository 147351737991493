import React, { SyntheticEvent, useState } from 'react';
import { Alert, Col } from 'react-bootstrap';
import CustomModal from '../../../components/modal';
import Statsbox from '../../../components/stats-box';
import { ROLES } from '../../../constants';
import AuthorizeView from '../../../hoc/authorizeView';
import { post } from '../../../utils/axios';
import { Form, Input } from '../../../components/form';

const TransferLocationModal = ({
  chargeStationLocaleId,
  chargeStationId
}: {
  chargeStationLocaleId: string;
  chargeStationId: string;
}): JSX.Element => {
  const [formProps, setFormProps] = useState({
    chargeStationLocale: chargeStationLocaleId
  });

  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setError('');
    setSubmitting(true);
    post(`/charge-stations/${chargeStationId}/transfer-location`, formProps)
      .then(() => {
        setSubmitting(false);
        document.location.reload();
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.response.data.message);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.manager]}>
      <Col sm={3} className="mb-3">
        <CustomModal
          headerText="Transfer charge station location"
          ModalLauncher={(props) => (
            <button {...props} className="btn-chromeless w-100">
              <Statsbox
                bgClass="dark"
                title="Transfer location"
                faIconName="fas fa-exchange-alt"
                iconColorClass="text-secondary"
              />
            </button>
          )}
        >
          {error && <Alert variant="danger">{error}</Alert>}

          <Form submitting={submitting} onSubmit={onSubmit} btnText={'Transfer'}>
            <div className="body-card">
              <Input
                onChange={handleChange}
                label="Charge station location id*"
                type="text"
                name="chargeStationLocale"
                value={formProps.chargeStationLocale}
                placeholder="Charge station location id"
                required
              ></Input>
            </div>
          </Form>
        </CustomModal>
      </Col>
    </AuthorizeView>
  );
};

export default TransferLocationModal;
