import React, { SyntheticEvent } from 'react';
import { Form, TextArea, Input } from '../form';
import { Faq } from '../../interfaces';
import { Checkbox } from '../form';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: Faq;
}

const TAGS = ['Technical', 'Payment & Financing', 'Battery & Charging', 'General', 'App'];

const FaqForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps } = props;
  const { question, answer, tags } = formProps;
  return (
    <Form submitting={submitting} onSubmit={onSubmit}>
      <div className="body-card">
        <Input
          onChange={handleChange}
          label="Question"
          type="text"
          name="question"
          value={question}
          placeholder="Question"
          required
        ></Input>
        <TextArea
          onChange={handleChange}
          label="Answer"
          type="text"
          name="answer"
          value={answer}
          placeholder="Answer"
          required
        ></TextArea>

        <label>Tags</label>
        <div className="row row-cols-lg-auto g-2 align-items-center">
          {TAGS.map((t) => (
            <Checkbox
              id={t}
              key={t}
              inline
              name="tags"
              type="checkbox"
              label={t}
              value={t}
              checked={tags?.includes(t)}
              onChange={handleChange}
            />
          ))}
        </div>
      </div>
    </Form>
  );
};

export default FaqForm;
