import React, { SyntheticEvent, useState } from 'react';
import { Alert } from '../../components/alerts';
import { Form, Input } from '../../components/form';
import CustomModal from '../../components/modal';
import { ROLES } from '../../constants';
import AuthorizeView from '../../hoc/authorizeView';
import { post } from '../../utils/axios';

const UpdateVehicleSoftwareVersion = ({ bikeId }: { bikeId: string }): JSX.Element => {
  const [formProps, setFormProps] = useState({
    vcuSoftwareVersion: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setError('');
    setSubmitting(true);
    post(`/bikes/${bikeId}/software-version`, formProps)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.response.data.message);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.productionMaintainer]}>
      <CustomModal
        headerText="Update VCU software version"
        ModalLauncher={(props) => (
          <button {...props} className="btn-chromeless">
            Software version
          </button>
        )}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        <Alert variant="warning" small>
          <strong>
            Add <i>&apos;-D&apos;</i> suffix to install diagonostic version. i.e.
            1.1.0-VCU=245676|0xe44be0fa,DISPLAY=345456|0xe9877ae-D
          </strong>
        </Alert>
        <Form onSubmit={onSubmit} submitting={submitting}>
          <div className="body-card">
            <Input
              onChange={handleChange}
              label="VCU software version*"
              type="text"
              name="vcuSoftwareVersion"
              value={formProps.vcuSoftwareVersion}
              placeholder="VCU software version"
              required
            />
          </div>
        </Form>
      </CustomModal>
    </AuthorizeView>
  );
};

export default UpdateVehicleSoftwareVersion;
