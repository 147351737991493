import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { get, patch } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import Loader from '../../components/commons/Loader';
import { Col, Row } from 'react-bootstrap';
import StaffForm from '../../components/staff-form';
import { Staff } from '../../interfaces';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';

const UpdateStaffPage = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Staff>({
    enabled: true,
    name: '',
    email: '',
    phoneNumber: '',
    roles: []
  });
  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { staffId } = useParams<{ staffId: string }>();

  useEffect(() => {
    get(`/staffs/${staffId}`)
      .then((response: AxiosResponse) => {
        setFormProps(response.data.data.staff);
        setFetching(false);
      })
      .catch((error) => {
        setFetchError(error);
        setFetching(false);
      });
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    patch(`/staffs/${staffId}`, formProps)
      .then(() => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/staffs`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value, checked } = event.currentTarget;

    if (name === 'roles') {
      let roles = [];
      if (checked) roles = [...formProps.roles, value];
      else roles = formProps.roles?.filter((r) => r !== value) as string[];

      return setFormProps({
        ...formProps,
        roles
      });
    }

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  if (fetching) return <Loader />;
  if (fetchError) return <Navigate to="/error" />;

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Update Staff</h1>
              <span className="text-muted">Code: {staffId}</span>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <StaffForm
        submitting={submitting}
        isEdit
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      />
    </div>
  );
};

export default withDashboard(UpdateStaffPage);
