import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import Paginate from '../../components/paginate';
import { BikeTelemetry } from '../../interfaces';
import { SearchFields } from '../../components/search-box';
import { Link } from 'react-router-dom';

function InactiveVehiclesPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fetching, response] = usePromiseFetch(`/bikes/inactive-telemetries`, searchParams);

  if (fetching) return <TableLoader />;

  const {
    bikeTelemetries,
    size,
    total
  }: { bikeTelemetries: BikeTelemetry[]; size: number; total: number } = response
    ? response
    : {
        bikeTelemetries: [],
        size: 40,
        total: 0
      };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Inactive vehicles</h1>
              <div>
                <i className="fas fa-info-circle"></i> Idle vehicles that have not posted any
                telemetry data in the past 2 days.
              </div>
              <span className="text-muted">{total} entries found</span>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[{ label: 'Bike id', name: 'bike' }]}
          />
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover>
              <thead className="table-light">
                <tr>
                  <th>Vehicles</th>
                  <th>Last updated at</th>
                </tr>
              </thead>
              <tbody>
                {bikeTelemetries.map(({ bike, updatedAt }) => (
                  <tr key={bike as string}>
                    <td>
                      <Link className="link" to={`/bikes/${bike}`}>
                        <small>{bike}</small>
                      </Link>
                    </td>
                    <td>{new Date(updatedAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(InactiveVehiclesPage);
