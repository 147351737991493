import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { usePromiseFetch } from '../../hooks';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { EPaymentState, EServicesGains, WalletTransaction } from '../../interfaces';
import Paginate from '../../components/paginate';
import { SearchFields } from '../../components/search-box';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';

function WalletTransactionsPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fetching, response] = usePromiseFetch(
    '/wallets/transactions?sort=-createdAt',
    searchParams
  );

  if (fetching) return <TableLoader />;

  const {
    transactions,
    size,
    total
  }: { transactions: WalletTransaction[]; size: number; total: number } = response
    ? response
    : { transactions: [], size: 40, total: 0 };

  const totalPage = Math.ceil(total / size);

  function renderPaymentStatePill(paymentState: EPaymentState) {
    let pillClassName = 'bg-primary';
    if (paymentState === EPaymentState.completed) pillClassName = 'bg-success';
    else if (paymentState === EPaymentState.refunded) pillClassName = 'bg-dark';
    else if (paymentState === EPaymentState.pending) pillClassName = 'bg-warning';
    return <span className={`badge rounded-pill ${pillClassName}`}>{paymentState}</span>;
  }

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Transactions</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.finance]}>
              <div className="head-right">
                <Link to="/payments/add" className="btn btn-primary">
                  Create payment
                </Link>
              </div>
            </AuthorizeView>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[
              { label: 'Transaction id', name: '_id' },
              { label: 'Third party txn code', name: 'thirdPartyTxnId' },
              { label: 'Wallet id', name: 'walletId' },
              {
                label: 'Service',
                name: 'service',
                type: 'dropDown',
                options: [
                  {
                    label: 'All',
                    value: ''
                  },
                  {
                    label: 'Yatri fast charge',
                    value: 'Yatri fast charge'
                  },
                  {
                    label: 'AC charge',
                    value: 'AC charge'
                  },
                  {
                    label: 'CCS charge',
                    value: 'CCS'
                  },
                  {
                    label: 'Vehicle servicing',
                    value: 'Vehicle servicing'
                  },
                  {
                    label: 'Vehicle maintenance',
                    value: 'Vehicle maintenance'
                  },
                  {
                    label: 'Store',
                    value: 'Store'
                  },
                  {
                    label: 'Top Up',
                    value: 'Top Up'
                  },
                  {
                    label: 'Refund',
                    value: 'Refund'
                  }
                ]
              },
              { label: 'Starting date', name: 'servicingDate[gte]', type: 'date' },
              { label: 'Ending date', name: 'servicingDate[lte]', type: 'date' }
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table className="table-box" responsive hover>
            <thead className="table-light">
              <tr>
                <th>Transaction id</th>
                <th>Transaction amount</th>
                <th>Service</th>
                <th>State</th>
                <th>Timestamp</th>
                <th>Previous balance</th>
                <th>Current balance</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {transactions.map(
                ({
                  _id,
                  totalAmount,
                  service,
                  paymentState,
                  createdAt,
                  previousBalance,
                  currentBalance
                }) => (
                  <tr key={_id}>
                    <td>
                      <small>{_id}</small>
                    </td>

                    <td>
                      <strong>
                        <small>NPR.</small> <span className="text-success">{totalAmount}</span>
                      </strong>
                    </td>
                    <td>
                      <span
                        className={`badge rounded-pill ${
                          service === EServicesGains.topUp ? 'bg-success' : 'bg-light text-dark'
                        }`}
                      >
                        {service}
                      </span>
                    </td>
                    <td>{renderPaymentStatePill(paymentState)}</td>
                    <td>{new Date(createdAt).toLocaleString()}</td>
                    <td>
                      <small>NPR.</small> {previousBalance}
                    </td>
                    <td>
                      <small>NPR.</small> {currentBalance}{' '}
                      {previousBalance > currentBalance && (
                        <i className="fas fa-arrow-alt-circle-down text-danger"></i>
                      )}
                      {previousBalance < currentBalance && (
                        <i className="fas fa-arrow-alt-circle-up text-success"></i>
                      )}
                    </td>
                    <td>
                      <Link className="btn" to={`/payments/${_id}`}>
                        <i className="fas fa-eye"></i>
                      </Link>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Paginate
            totalPage={totalPage}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default withDashboard(WalletTransactionsPage);
