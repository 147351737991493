import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { removeToast } from '../../actions/toast';
import { selectToasts } from '../../selectors/toasts-selector';

const ToastUI = (): JSX.Element => {
  const dispatch = useDispatch();
  const toasts = useSelector(selectToasts);

  const toastVariantClass = (variant: any) => {
    if (variant === 'success') return 'text-success';
    if (variant === 'error') return 'text-danger';
    return '';
  };

  return (
    <ToastContainer className="p-3 position-fixed" position="bottom-end">
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          onClose={() => {
            dispatch(removeToast());
          }}
          show={true}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <strong className={`me-auto ${toastVariantClass(toast.variant)}`}>
              {toast.headText}
            </strong>
          </Toast.Header>
          <Toast.Body>
            <span className={`${toastVariantClass(toast.variant)}`}>{toast.bodyText}</span>
          </Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};

export default ToastUI;
