import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  Bar,
  BarChart,
  Area,
  AreaChart
} from 'recharts';
import { ChartType } from '../../types';

const renderChart = ({
  data,
  xLabel,
  yLabel,
  label,
  type,
  stroke,
  fill
}: {
  data: any;
  xLabel: string;
  yLabel: string;
  label?: string;
  type?: ChartType;
  stroke?: string;
  fill?: string;
}) => {
  if (type === 'Bar') {
    return (
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xLabel}>
          {label && <Label value={label} offset={-20} position="insideBottom" />}
        </XAxis>
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        <Bar dataKey={yLabel} stroke={stroke} fill={fill} />
      </BarChart>
    );
  }

  if (type === 'Area') {
    return (
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xLabel}>
          {label && <Label value={label} offset={-20} position="insideBottom" />}
        </XAxis>
        <YAxis />
        <Legend verticalAlign="top" height={36} />
        <Tooltip />
        <Area type="monotone" dataKey={yLabel} stroke={stroke} fill={fill} />
      </AreaChart>
    );
  }

  return (
    <LineChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xLabel}>
        {label && <Label value={label} offset={-20} position="insideBottom" />}
      </XAxis>
      <YAxis />
      <Tooltip />
      <Legend verticalAlign="top" height={36} />
      <Line type="monotone" dataKey={yLabel} stroke={stroke} fill={fill} />
    </LineChart>
  );
};

const AddChart = ({
  data,
  xLabel,
  yLabel,
  label,
  type,
  stroke,
  fill
}: {
  data: any;
  xLabel: string;
  yLabel: string;
  label?: string;
  type?: ChartType;
  stroke?: string;
  fill?: string;
}): JSX.Element => (
  <div>
    <ResponsiveContainer width="100%" height={300}>
      {renderChart({
        data,
        xLabel,
        yLabel,
        label,
        type,
        stroke,
        fill
      })}
    </ResponsiveContainer>
    <br />
  </div>
);

export { AddChart };
