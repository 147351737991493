import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { get } from '../../utils/axios';
import { Col } from 'react-bootstrap';
import Statsbox from '../stats-box';

const TotalVehicleEnergyConsumption = ({ bikeModel }: { bikeModel: 'P1' }): JSX.Element => {
  const [totalVehicleEnergyConsumption, setTotalVehicleEnergyConsumption] = useState(0);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    get(`/stats/bikes/model/${bikeModel}/energy-consumption`).then((response: AxiosResponse) => {
      setTotalVehicleEnergyConsumption(response.data.data.total);
      setFetching(false);
    });
  }, []);

  return (
    <>
      <Col xs={6} md={3}>
        <Statsbox
          iconColorClass="text-warning"
          bgClass="dark"
          fetching={fetching}
          faIconName="fas fa-plug"
          title={`${totalVehicleEnergyConsumption.toLocaleString()} kWh`}
          caption={`Total ${bikeModel} energy consumption`}
        />
      </Col>
    </>
  );
};

export default TotalVehicleEnergyConsumption;
