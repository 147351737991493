import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Loader from '../../components/commons/Loader';
import { ServiceCenter } from '../../interfaces';
import { withDashboard } from '../../hoc';
import Map from '../../components/map';
import { usePromiseFetch } from '../../hooks';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';
import Statsbox from '../../components/stats-box';

function ServiceCenterPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const [fetching, response, error] = usePromiseFetch(`/service-centers/${id}`);

  if (fetching) return <Loader />;
  if (!response || error) return <Navigate to="/error" />;

  const serviceCenter = response.station as ServiceCenter;

  const { _id, address, phoneNumber, openingHour, closingHour, isEnabled } = serviceCenter;
  return (
    <Container>
      <div className="head-panel d-flex">
        <div>
          <h1 className="page-title">Service Center</h1>
          {isEnabled ? (
            <div className="badge rounded-pill bg-success">Enabled</div>
          ) : (
            <div className="badge rounded-pill bg-danger">Disabled</div>
          )}
        </div>
        <div className="head-right">
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.manager]}>
            <Link to={`${pathname}/edit`} className="btn btn-dark" style={{ marginRight: 4 }}>
              Update service center
            </Link>
          </AuthorizeView>
          <Link to={`/service-centers/${id}/appointments/add`} className="btn btn-primary">
            Create appointment
          </Link>
        </div>
      </div>
      <Row className="mb-3">
        <Col sm={4} className="mb-3">
          <Link to={`/service-centers/${id}/appointments`}>
            <Statsbox
              bgClass="dark"
              title="All servicing appointments"
              faIconName="fas fa-tools"
              iconColorClass="text-warning"
            />
          </Link>
        </Col>
      </Row>
      <div className="body-card">
        <Row>
          <Col>
            <ul className="neutralize">
              <li>Code: {_id}</li>
              <li>Address: {address}</li>
              <li>Phone number: {phoneNumber}</li>
              <li>
                Operating hours: {openingHour} - {closingHour}
              </li>
            </ul>
          </Col>
        </Row>
      </div>

      <div className="body-card">
        <Row>
          <Col>
            <Map mappable={serviceCenter} />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(ServiceCenterPage);
