import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import Paginate from '../../components/paginate';
import { BatteryLog } from '../../interfaces';
import BatteryLogsTable from '../../components/battery-logs-table';

function CustomerBikeBatterySwapLogs(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const { batterySwapHistoryId } = useParams<{ batterySwapHistoryId: string }>();
  const [fetching, response] = usePromiseFetch(
    `/batteries/logs?batterySwapHistory=${batterySwapHistoryId}&sort=-createdAt`,
    searchParams
  );

  if (fetching) return <TableLoader />;

  const { logs, size, total }: { logs: BatteryLog[]; size: number; total: number } = response
    ? response
    : {
        logs: [],
        size: 40,
        total: 0
      };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Battery logs</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <BatteryLogsTable logs={logs} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(CustomerBikeBatterySwapLogs);
