import { ActionTypes } from '../actions';
import { IToast } from '../actions/toast';

export default (state: IToast[] = [], action: any): IToast[] => {
  switch (action.type) {
    case ActionTypes.PUSH_TOAST:
      return [...state, { ...action.payload, id: Math.floor(Math.random() * 1000) + 1 }];
    case ActionTypes.POP_TOAST:
      const arr = [...state];
      arr.shift();
      return arr;
    default:
      return state;
  }
};
