import React, { useState, FormEvent } from 'react';
import { Input } from '../../components/form';
import { Service } from '../../interfaces';

interface Props {
  services: Service[];
  handleServiceChange: (service: Service, index?: number, deleteIndex?: boolean) => void;
}

export const ServicesForm = ({ services, handleServiceChange }: Props): JSX.Element => {
  const [service, setService] = useState<Service>({ cost: 0, serviceName: '' });

  function onServiceChange(updatedService?: Service, index?: number) {
    if (updatedService && index !== undefined) return handleServiceChange(updatedService, index);
    handleServiceChange(service);
    setService({ cost: 0, serviceName: '' });
  }

  return (
    <>
      <label>Services (Service name | Cost)</label>
      {services.map((_service, index) => (
        <div key={index} className="form-group-inline">
          <Input
            noFloat
            onChange={(e: FormEvent<HTMLInputElement>) =>
              onServiceChange(
                {
                  ..._service,
                  serviceName: e.currentTarget.value
                },
                index
              )
            }
            type="text"
            name="serviceName"
            value={_service.serviceName}
            placeholder="Service name"
            required
          />
          <Input
            noFloat
            onChange={(e: FormEvent<HTMLInputElement>) =>
              onServiceChange(
                {
                  ..._service,
                  cost: Number(e.currentTarget.value)
                },
                index
              )
            }
            type="text"
            name="cost"
            value={_service.cost}
            placeholder="Cost"
            required
          />
          <div className="form-group" style={{ flexGrow: 0 }}>
            <button
              className="btn"
              type="button"
              onClick={() => handleServiceChange(_service, index, true)}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
      ))}
      <div className="form-group-inline">
        <Input
          noFloat
          onChange={(e: FormEvent<HTMLInputElement>) =>
            setService({ ...service, serviceName: e.currentTarget.value })
          }
          type="text"
          name="serviceName"
          value={service.serviceName}
          placeholder="Service name"
        />
        <Input
          noFloat
          onChange={(e: FormEvent<HTMLInputElement>) =>
            setService({ ...service, cost: Number(e.currentTarget.value) })
          }
          type="text"
          name="cost"
          value={service.cost}
          placeholder="Cost"
        />
        <div className="form-group" style={{ flexGrow: 0 }}>
          <button
            onClick={() => onServiceChange()}
            className="btn btn-success"
            type="button"
            disabled={!service.serviceName && !service.cost}
          >
            <i className="fas fa-check"></i>
          </button>
        </div>
      </div>
    </>
  );
};
