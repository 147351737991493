import React from 'react';
import { URLSearchParamsInit } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { SelectInput } from '../form';

export default function Paginate({
  totalPage,
  searchParams,
  setSearchParams
}: {
  totalPage: number;
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void;
}): JSX.Element {
  function handlePageClick({ selected }: { selected: number }): void {
    const selectedPage = selected + 1;

    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => (params[key] = value));

    setSearchParams({ ...params, page: String(selectedPage) });
  }

  const currentPage = Number(searchParams.get('page')) ? Number(searchParams.get('page')) : 1;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '2rem',
        width: '100%'
      }}
    >
      <div style={{ display: 'flex', marginBottom: '-2rem', alignItems: 'baseline' }}>
        <SelectInput
          noFloat
          name="limit"
          value={searchParams.get('limit') ?? '40'}
          onChange={(e) => setSearchParams({ ...searchParams, limit: e.target.value })}
          options={[
            { label: '40', value: '40' },
            { label: '60', value: '60' },
            { label: '100', value: '100' }
          ]}
        />
        <small className="ms-2">Entires per page</small>
      </div>

      <ReactPaginate
        previousLabel={<i className="fas fa-angle-left"></i>}
        nextLabel={<i className="fas fa-angle-right"></i>}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalPage}
        forcePage={currentPage - 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination center'}
        activeClassName={'active'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
      />
    </div>
  );
}
