import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  children: React.ReactNode;
  ModalLauncher: React.FunctionComponent<any>;
  headerText?: string;
};

function CustomModal({ children, ModalLauncher, headerText }: Props): JSX.Element {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ModalLauncher onClick={handleShow} />
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <strong>{headerText}</strong>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
}

export default CustomModal;
