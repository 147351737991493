import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { get, patch } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import Loader from '../../components/commons/Loader';
import { Col, Row } from 'react-bootstrap';
import BatteryForm from '../../components/battery-form';
import { Battery } from '../../interfaces';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';
import { useDispatch } from 'react-redux';

const UpdateBattery = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Battery>({
    identifier: '',
    isEnabled: true,
    soc: 100
  });
  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    get(`/batteries/${id}`)
      .then((response: AxiosResponse) => {
        setFormProps(response.data.data.battery);
        setFetching(false);
      })
      .catch((error) => {
        setFetchError(error);
        setFetching(false);
      });
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    patch(`/batteries/${id}`, formProps)
      .then(() => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/batteries`);
      })
      .catch((error) => {
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        setSubmitting(false);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  if (fetching) return <Loader />;
  if (fetchError) return <Navigate to="/error" />;

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Update Battery</h1>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <BatteryForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></BatteryForm>
    </div>
  );
};

export default withDashboard(UpdateBattery);
