import React, { SyntheticEvent, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import BikeOwnershipForm from '../../components/bike-add-ownership-form';
import CustomModal from '../../components/modal';
import Statsbox from '../../components/stats-box';
import { ROLES } from '../../constants';
import AuthorizeView from '../../hoc/authorizeView';
import { formatAxiosError } from '../../utils';
import { post } from '../../utils/axios';

const AddNewBikeOwnershipModal = ({ customerId }: { customerId: string }): JSX.Element => {
  const [formProps, setFormProps] = useState({
    bikeId: '',
    customerId: customerId,
    purchaseDate: new Date().toISOString().split('T')[0]
  });
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    post('/bike-ownership/add', formProps)
      .then(() => {
        setSubmitting(false);
        document.location.reload();
      })
      .catch((error) => {
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        setSubmitting(false);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <AuthorizeView authorizedRoles={[ROLES.admin]}>
      <Col sm={3} className="mb-3">
        <CustomModal
          headerText="Assign bike ownership"
          ModalLauncher={(props) => (
            <button {...props} className="btn-chromeless w-100">
              <Statsbox
                bgClass="dark"
                title="Assign bike ownership"
                faIconName="fas fa-biking"
                iconColorClass="text-danger"
              />
            </button>
          )}
        >
          <BikeOwnershipForm
            submitting={submitting}
            onSubmit={onSubmit}
            handleChange={handleChange}
            formProps={formProps}
          ></BikeOwnershipForm>
        </CustomModal>
      </Col>
    </AuthorizeView>
  );
};

export default AddNewBikeOwnershipModal;
