import React, { useState, useEffect } from 'react';
import Loader from '../commons/Loader';
import NotFound from '../commons/NotFound';
import { AxiosResponse } from 'axios';
import { get } from '../../utils/axios';
import { Card } from 'react-bootstrap';
import { Customer } from '../../interfaces';
import { Link } from 'react-router-dom';

interface Props {
  id: string;
}

const CustomerDetailCard = ({ id }: Props): JSX.Element => {
  const [fetching, setFetching] = useState(true);
  const [customer, setCustomer] = useState<Customer | null>(null);

  useEffect(() => {
    get(`/customers/${id}`)
      .then((response: AxiosResponse) => {
        setCustomer(response.data.data.customer);
        setFetching(false);
      })
      .catch(() => setFetching(false));
  }, []);

  if (fetching) return <Loader />;
  if (!customer) return <NotFound />;

  const { _id, countryCode, photo, email, dob, phoneNumber, licenseNumber, name } = customer;
  return (
    <Link to={`/customers/${_id}`}>
      <Card>
        <Card.Header as="h5">Customer detail</Card.Header>
        <img src={photo} className="img-fluid" />
        <Card.Body>
          <Card.Title>{name}</Card.Title>
          <ul className="list-unstyled">
            <li>Code: {_id}</li>
            <li>
              Phone number: {countryCode} {phoneNumber}
            </li>
            <li>Email: {email}</li>
            <li>DoB: {dob}</li>
            <li>License no: {licenseNumber}</li>
          </ul>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default CustomerDetailCard;
