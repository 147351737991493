import React, { SyntheticEvent } from 'react';
import { TextArea, Input, SelectInput, Form } from '../../components/form';
import { EServicesOutlays } from '../../interfaces';
import CustomerPeepCard from '../customer-peep-card';

type IPayment = {
  customerId: string;
  amount: number;
  service: EServicesOutlays | '';
  remarks: '';
};

interface Props {
  submitting: boolean;
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  formProps: IPayment;
}

const PaymentForm = (props: Props): JSX.Element => {
  const { onSubmit, submitting, handleChange, formProps } = props;
  const services = Object.values(EServicesOutlays);

  const { customerId, amount, service, remarks } = formProps;
  return (
    <div className="row">
      <div className="col-sm-12 col-md-8 order-2 order-md-1">
        <Form onSubmit={onSubmit} submitting={submitting} btnText="Create payment">
          <div className="body-card">
            <Input
              onChange={handleChange}
              label="Customer Id*"
              type="text"
              name="customerId"
              value={customerId}
              placeholder="Customer id"
              required
            ></Input>
            <Input
              onChange={handleChange}
              label="Amount*"
              type="number"
              name="amount"
              value={amount}
              placeholder="Amount"
              required
            ></Input>
            <SelectInput
              onChange={handleChange}
              label="Select service*"
              type="text"
              name="service"
              options={[
                { label: 'Select service', value: '' },
                ...services.map((s) => ({ label: s, value: s }))
              ]}
              value={service}
              required
            />
            <TextArea
              onChange={handleChange}
              label="Remarks*"
              name="remarks"
              value={remarks}
              placeholder="Remarks"
              required
            ></TextArea>
          </div>
        </Form>
      </div>
      <div className="col-sm-12 col-md-4 order-1 order-md-2 mb-3">
        <CustomerPeepCard customerId={formProps.customerId} />
      </div>
    </div>
  );
};

export default PaymentForm;
