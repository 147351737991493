import { FetchUserAction } from './auth';
import { FetchUnreadServicingCount } from './servicingCount';

export enum ActionTypes {
  FETCH_USER = 'FETCH_USER',
  FETCH_UNREAD_SERVICING_COUNT = 'FETCH_UNREAD_SERVICING_COUNT',
  PUSH_TOAST = 'PUSH_TOAST',
  POP_TOAST = 'POP_TOAST'
}

export type Action = FetchUserAction | FetchUnreadServicingCount;
