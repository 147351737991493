import React from 'react';

interface Props {
  label?: string;
  name: string;
  value: any;
  noFloat?: boolean;
  error?: boolean | string;
  errorMessage?: string;
  onChange: (e: any) => any;
  [key: string]: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TextArea = ({
  onChange,
  rows,
  name,
  label,
  noFloat,
  error,
  errorMessage,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <div className="form-floating form-group">
      {label && noFloat && <label htmlFor={name}>{label}</label>}
      <textarea
        className={`form-control ${error && 'is-invalid'}`}
        name={name}
        id={name}
        rows={rows ? rows : 3}
        onChange={onChange}
        {...otherProps}
      ></textarea>
      {label && !noFloat && <label htmlFor={name}>{label}</label>}
      {errorMessage && <span className="invalid-feedback">{errorMessage}</span>}{' '}
    </div>
  );
};
