import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

type Props = {
  children: React.ReactNode;
  id: string;
  icon?: React.ReactNode;
  linkText: string;
};

function CollapseNavLink({ children, id, icon, linkText }: Props): JSX.Element {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        aria-controls={id}
        style={{ width: '100%', textAlign: 'left' }}
      >
        <div className="s-icon" style={{ marginRight: 12 }}>
          {icon}
        </div>
        {linkText}
      </Button>
      <Collapse in={open}>
        <div id={id}>{children}</div>
      </Collapse>
    </>
  );
}

export default CollapseNavLink;
