import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { TestRide } from '../../interfaces';
import Paginate from '../../components/paginate';
import { SearchFields } from '../../components/search-box';
import { usePromiseFetchSite } from '../bookings';

function TestRidesPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fetching, response] = usePromiseFetchSite('/test-rides?sort=-bookingDate', searchParams);

  if (fetching) return <TableLoader />;

  const {
    testRides,
    size,
    total
  }: { testRides: TestRide[]; size: number; total: number } = response
    ? response
    : { testRides: [], size: 40, total: 0 };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Test rides</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <div className="head-right">
              <Link to="/test-rides/add" className="btn btn-primary">
                Add test ride
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[
              { label: 'Id', name: '_id' },
              { label: 'Email', name: 'email' },
              { label: 'Phone number', name: 'phoneNumber' }
            ]}
          />
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover>
              <thead className="table-light">
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone number</th>
                  <th>Booking date</th>
                  <th>License number</th>
                  <th>Bike</th>
                  <th>Attended</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {testRides.map(
                  ({
                    _id,
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    bike,
                    bookingDate,
                    licenseNumber,
                    hasAttended
                  }) => (
                    <tr key={_id}>
                      <td>
                        <small>{_id}</small>
                      </td>

                      <td>{`${firstName} ${lastName}`}</td>
                      <td>{email}</td>
                      <td>{phoneNumber}</td>
                      <td>{new Date(bookingDate).toLocaleString()}</td>
                      <td>{licenseNumber}</td>
                      <td>{bike}</td>
                      <td>{hasAttended ? 'Yes' : 'No'}</td>
                      <td>
                        <Link className="btn btn-sm" to={`/test-rides/${_id}/edit`}>
                          <i className="fas fa-pencil-alt"></i>
                        </Link>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(TestRidesPage);
