import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { get, put } from '../../../utils/axios';
import { withDashboard } from '../../../hoc';
import Loader from '../../../components/commons/Loader';
import { Alert, Col, Row } from 'react-bootstrap';
import ChargingStationDockForm from '../../../components/charging-station-dock-form';
import { Dock, EChargingTypes } from '../../../interfaces';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../../actions/toast';
import { formatAxiosError } from '../../../utils';

const UpdateDock = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Dock>({
    isAvailable: true,
    isEnabled: true,
    capacity: 3.5,
    maxCapacity: 3.5,
    type: EChargingTypes.yatriFastCharge,
    isOutOfService: false
  });

  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, dockId } = useParams<{ id: string; dockId: string }>();

  useEffect(() => {
    get(`/charge-stations/${id}`)
      .then((response: AxiosResponse) => {
        const dock = response.data.data.station.docks.find((d: Dock) => d._id === dockId);
        if (!dock) setFetchError('Dock with that id not found');
        else setFormProps(dock);
        setFetching(false);
      })
      .catch((error) => {
        setFetchError(error);
        setFetching(false);
      });
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    put(`/charge-stations/${id}/docks/${dockId}`, formProps)
      .then(() => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(-1);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  if (fetching) return <Loader />;
  if (fetchError) return <Navigate to="/error" />;

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Update Charging Dock</h1>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <Row>
        <ChargingStationDockForm
          isUpdate
          submitting={submitting}
          onSubmit={onSubmit}
          handleChange={handleChange}
          formProps={formProps}
        ></ChargingStationDockForm>
      </Row>
    </div>
  );
};

export default withDashboard(UpdateDock);
