import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import StaffForm from '../../components/staff-form';
import { post } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import { Staff } from '../../interfaces';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';

const AddStaffPage = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Staff>({
    enabled: true,
    name: '',
    email: '',
    phoneNumber: '',
    password: '',
    roles: []
  });
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);

    post('/staffs', formProps)
      .then(() => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/staffs`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value, checked } = event.currentTarget;

    if (name === 'roles') {
      let roles = [];
      if (checked) roles = [...formProps.roles, value];
      else roles = formProps.roles?.filter((r) => r !== value) as string[];

      return setFormProps({
        ...formProps,
        roles
      });
    }

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Add Staff</h1>
        </div>
        <div className="head-right"></div>
      </div>
      <StaffForm
        submitting={submitting}
        isEdit={false}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      />
    </div>
  );
};

export default withDashboard(AddStaffPage);
