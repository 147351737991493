import React from 'react';
import { Card } from 'react-bootstrap';

const Statsbox = ({
  caption,
  title,
  fetching,
  faIconName,
  bgClass,
  iconColorClass
}: {
  caption?: string;
  title: string | number;
  fetching?: boolean;
  faIconName: string;
  bgClass: string;
  iconColorClass?: string;
}): JSX.Element => {
  return (
    <Card bg={bgClass} text="white">
      <Card.Body className="d-flex" style={{ alignItems: 'center' }}>
        {fetching ? (
          <div>
            <div style={{ borderRadius: 4, width: 40, height: 12, backgroundColor: '#dee2e6' }} />
            <div
              style={{
                borderRadius: 4,
                width: 80,
                height: 8,
                backgroundColor: '#dee2e6',
                marginTop: 6
              }}
            />
          </div>
        ) : (
          <>
            <div className={`box-icon ${iconColorClass}`}>
              <i className={faIconName}></i>
            </div>
            <div>
              <Card.Title className="mb-0">{title}</Card.Title>
              {caption && <Card.Text>{caption}</Card.Text>}
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default Statsbox;
