import React, { SyntheticEvent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ChargeStationForm from '../../components/charge-station-form';
import { post } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import { AxiosResponse } from 'axios';
import { ChargeStation, EChargeStationStatus } from '../../interfaces';
import { formatAxiosError, scrollToTop } from '../../utils';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import { ErrorMessage } from '../../components/commons/NotFound';

const AddChargeStation = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const chargeStationLocale = searchParams.get('chargeStationLocale');

  const [formProps, setFormProps] = useState<Partial<ChargeStation>>({
    chargeStationLocale: chargeStationLocale as string,
    isEnabled: true,
    status: EChargeStationStatus.online,
    softwareVersion: '',
    remarks: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    post('/charge-stations', formProps)
      .then((response: AxiosResponse) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/charge-stations/${response.data.data.station._id}`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        scrollToTop();
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;

    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  if (!chargeStationLocale)
    return <ErrorMessage errorMessage="Charge station location is not present" />;

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Add Charge Station</h1>
        </div>
        <div className="head-right"></div>
      </div>
      <ChargeStationForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></ChargeStationForm>
    </div>
  );
};

export default withDashboard(AddChargeStation);
