import React from 'react';
import { Col } from 'react-bootstrap';
import CustomModal from '../../components/modal';
import Statsbox from '../../components/stats-box';
import { ROLES } from '../../constants';
import AuthorizeView from '../../hoc/authorizeView';

const WalletLoadModal = ({ customerId }: { customerId: string }): JSX.Element => (
  <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.finance]}>
    <Col sm={3} className="mb-3">
      <CustomModal
        headerText="Load wallet with"
        ModalLauncher={(props) => (
          <button {...props} className="btn-chromeless w-100">
            <Statsbox
              bgClass="dark"
              title="Load wallet"
              faIconName="fas fa-wallet"
              iconColorClass="text-primary"
            />
          </button>
        )}
      >
        <ul className="list-group">
          <a
            href={`${process.env.REACT_APP_YATRIHUB_API_URL}/wallet/khalti?customerId=${customerId}`}
            target="_blank"
            rel="noreferrer"
            className="list-group-item list-group-item-action"
          >
            <img src="/assets/images/khalti.png" className="me-2" width={40} />
            Using Khalti
          </a>
          <a
            href={`${process.env.REACT_APP_YATRIHUB_API_URL}/wallet/esewa?customerId=${customerId}`}
            target="_blank"
            rel="noreferrer"
            className="list-group-item list-group-item-action"
          >
            <img src="/assets/images/esewa.png" className="me-2" width={40} />
            Using eSewa
          </a>
        </ul>
      </CustomModal>
    </Col>
  </AuthorizeView>
);

export default WalletLoadModal;
