import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { Staff } from '../../interfaces';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import Paginate from '../../components/paginate';
import { SearchFields } from '../../components/search-box';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';

function StaffsPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fetching, response] = usePromiseFetch('/staffs', searchParams);

  if (fetching) return <TableLoader />;

  const { staffs, size, total }: { staffs: Staff[]; size: number; total: number } = response
    ? response
    : {
        staffs: [],
        size: 40,
        total: 0
      };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Staffs</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <div className="head-right">
              <AuthorizeView authorizedRoles={[ROLES.admin]}>
                <Link to="/staffs/add" className="btn btn-primary">
                  Add staff
                </Link>
              </AuthorizeView>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[
              { label: 'Email', name: 'email' },
              {
                label: 'Phone number',
                name: 'phoneNumber'
              }
            ]}
          />
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover>
              <thead className="table-light">
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone number</th>
                  <th>Status</th>
                  <th>Roles</th>
                  <th>Added date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {staffs.map(({ _id, name, email, phoneNumber, enabled, roles, createdAt }) => (
                  <tr key={_id}>
                    <td>
                      <small>{_id}</small>
                    </td>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>{phoneNumber}</td>
                    <td>
                      {enabled ? (
                        <div className="badge rounded-pill bg-success">Enabled</div>
                      ) : (
                        <div className="badge rounded-pill bg-danger">Disabled</div>
                      )}
                    </td>
                    <td>
                      {roles?.map((role) => (
                        <span key={role} className="badge rounded-pill bg-primary me-2">
                          {role?.split('ROLE_')[1]}
                        </span>
                      ))}
                    </td>
                    <td>{new Date(createdAt as string).toLocaleString()}</td>

                    <td>
                      <AuthorizeView authorizedRoles={[ROLES.admin]}>
                        <Link className="btn btn-sm" to={`/staffs/${_id}/edit`}>
                          <i className="fas fa-pencil-alt"></i>
                        </Link>
                      </AuthorizeView>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(StaffsPage);
