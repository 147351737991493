export enum EVechicleLogLevels {
  info = 'Info',
  error = 'Error',
  warn = 'Warn'
}
export interface VehicleLog {
  _id: string;
  bike: string;
  level: EVechicleLogLevels;
  type: string;
  remarks: string;
  createdAt: string;
  updatedAt: string;
}
