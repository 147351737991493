import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import Paginate from '../../components/paginate';
import { VehicleLog } from '../../interfaces';
import { SearchFields } from '../../components/search-box';
import { Link } from 'react-router-dom';

function VehicleLogsPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fetching, response] = usePromiseFetch(`/vehicle-logs`, searchParams);

  if (fetching) return <TableLoader />;

  const { logs, size, total }: { logs: VehicleLog[]; size: number; total: number } = response
    ? response
    : {
        logs: [],
        size: 40,
        total: 0
      };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Vehicle logs</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[
              { label: 'Bike', name: 'bike' },
              {
                label: 'level',
                name: 'type',
                type: 'dropDown',
                options: [
                  {
                    label: 'All',
                    value: ''
                  },
                  {
                    label: 'Info',
                    value: 'Info'
                  },
                  {
                    label: 'Error',
                    value: 'Error'
                  },
                  {
                    label: 'Warning',
                    value: 'Warn'
                  }
                ]
              },
              {
                label: 'Type',
                name: 'type',
                type: 'dropDown',
                options: [
                  {
                    label: 'All',
                    value: ''
                  },
                  {
                    label: 'Software',
                    value: 'Software'
                  }
                ]
              },
              { label: 'Starting date', name: 'createdAt[gte]', type: 'date' },
              { label: 'Ending date', name: 'createdAt[lte]', type: 'date' }
            ]}
          />
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover variant="dark">
              <thead>
                <tr>
                  <th>Vehicle</th>
                  <th>Type</th>
                  <th>Level</th>
                  <th>Log</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {logs.map(({ bike, type, level, remarks, createdAt }) => (
                  <tr key={bike} className={level === 'Error' ? 'text-danger' : ''}>
                    <td>
                      <Link className="link" to={`/bikes/${bike}`}>
                        <small>{bike}</small>
                      </Link>
                    </td>
                    <td>{type}</td>
                    <td>{level}</td>
                    <td>{remarks}</td>
                    <td>{new Date(createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(VehicleLogsPage);
