import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Loader from '../../components/commons/Loader';
import { Booking } from '../../interfaces';
import { withDashboard } from '../../hoc';
import { usePromiseFetchSite } from '../bookings';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

function BookingPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const [fetching, response, error] = usePromiseFetchSite(`/bookings/${id}`);

  if (fetching) return <Loader />;
  if (!response || error) return <Navigate to="/error" />;

  const booking = response.booking as Booking;

  const {
    _id,
    firstName,
    lastName,
    email,
    address,
    bike,
    amount,
    bookingDate,
    etd,
    paymentStatus,
    paymentType,
    phoneNumber,
    bookingStatus,
    deliveryDate,
    refunded,
    status,
    txnId,
    configurator,
    remarks,
    lastUpdatedBy
  } = booking;

  function _renderBookingConfiguration() {
    if (configurator) {
      const {
        paint,
        seatTrim,
        suspensionPaint,
        powerPack,
        performanceBraking,
        digitalStartupPack
      } = configurator;

      return (
        <div style={{ marginBottom: 0 }}>
          <h4>Your configuration</h4>
          <br />
          <ul className="neutralize" style={{ textTransform: 'capitalize' }}>
            <li>
              <span>Paint: {paint}</span>
            </li>
            <li>
              <span>Seat Trim: {seatTrim}</span>
            </li>
            <li>
              <span>Suspension Finish: {suspensionPaint}</span>
            </li>
            <li>
              <span>Power pack: {powerPack}</span>
            </li>
            <li>
              <span>Performance braking: {performanceBraking}</span>
            </li>
            <li>
              <span>Digital startup pack: {digitalStartupPack}</span>
            </li>
          </ul>
        </div>
      );
    }

    return null;
  }

  function StatusBox({ title, active }: { title: string; active: boolean }) {
    return (
      <div className={styles.rsvStatusBox}>
        <div className={styles.boxLeft}></div>
        <div className={styles.boxRight}>
          <div className={styles.middleDot} style={active ? { backgroundColor: '#30D5C8' } : {}} />
          <div
            className={styles.cornerDot}
            style={active ? { backgroundColor: 'rgba(48, 213, 200, 0.17)' } : {}}
          />
          <div className={styles.cornerDotInner} style={active ? { background: '#30D5C8' } : {}} />

          <div
            className={styles.boxRightContent}
            style={active ? { borderColor: '#30D5C8', color: '#000' } : {}}
          >
            {/* <i className="fas fa-check"></i> */}
            <p>{title}</p>
          </div>
        </div>
      </div>
    );
  }

  function _renderReservationStatus() {
    const {
      statusVinAssignment,
      statusChassisCompletion,
      statusAssemblyStart,
      statusAssemblyCompletion,
      statusQACheck,
      statusDeliveryReady
    } = booking;
    return (
      <div className={`${styles.rsvStatus} mt-4`}>
        <StatusBox title="VIN ASSIGNED" active={statusVinAssignment === 'Completed'} />
        <StatusBox
          title="CHASSIS COMPLETED, SENT TO PAINT"
          active={statusChassisCompletion === 'Completed'}
        />
        <StatusBox title="Assembly Started" active={statusAssemblyStart === 'Completed'} />
        <StatusBox title="Assembly Completed" active={statusAssemblyCompletion === 'Completed'} />
        <StatusBox title="QA Check" active={statusQACheck === 'Completed'} />
        <StatusBox title="ready for delivery" active={statusDeliveryReady === 'Completed'} />
      </div>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Booking</h1>
            </div>
            <div className="head-right">
              <Link to={`${pathname}/edit`} className="btn btn-primary">
                Update booking
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="8">
          <div className="body-card">
            <ul className="neutralize">
              <li>Booking code: {_id}</li>
              <li>Booking status: {bookingStatus}</li>
              <li>
                Name: {firstName} {lastName}
              </li>
              <li>Email: {email}</li>
              <li>Phone number: {phoneNumber}</li>
              <li>Address: {address}</li>
              <li>Bike: {bike}</li>
              <li>Booking date: {new Date(bookingDate as string).toLocaleDateString()}</li>
              <li>Amount: NPR. {amount?.toLocaleString()}</li>
              <li>
                Delivery date: {deliveryDate ? new Date(deliveryDate).toLocaleDateString() : etd}
              </li>
              <li>Payment status: {paymentStatus}</li>
              <li>Payment type: {paymentType}</li>
              <li>Refunded: {refunded ? 'Yes' : 'No'}</li>
              <li>Delivery status: {status}</li>
              <li>Transaction id: {txnId ? txnId : 'NA'}</li>
            </ul>
          </div>
          <div className="body-card">
            <strong>Remarks</strong>
            <p className="paragraph">{remarks}</p>
          </div>

          <div className="body-card">{_renderBookingConfiguration()}</div>
        </Col>
        <Col>
          <div className="body-card">
            <strong>Information</strong>
            <p>
              Last updated by{' '}
              {lastUpdatedBy ? (
                <Link className="link" to={`/staffs?_id=${lastUpdatedBy}`}>
                  user
                </Link>
              ) : (
                'NA'
              )}
            </p>
          </div>
          {_renderReservationStatus()}
        </Col>
      </Row>
    </Container>
  );
}

export default withDashboard(BookingPage);
