import axios, { AxiosResponse } from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_SERVER
});

export function get(url: string, params = {}): Promise<AxiosResponse> {
  return instance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
}

export function post(url: string, body: any): Promise<AxiosResponse> {
  return instance.post(url, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
}

export function patch(url: string, body: any): Promise<AxiosResponse> {
  return instance.patch(url, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
}

export function put(url: string, body: any): Promise<AxiosResponse> {
  return instance.put(url, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
}

export function del(url: string): Promise<AxiosResponse> {
  return instance.delete(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
}

export default { get, post, patch, del };
