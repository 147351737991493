import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Loader from '../../components/commons/Loader';
import { BusinessHour, ChargeStation, ChargeStationLocation } from '../../interfaces';
import { withDashboard } from '../../hoc';
import Map from '../../components/map';
import { usePromiseFetch } from '../../hooks';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';
import Clipboard from '../../components/clipboard';
import Statsbox from '../../components/stats-box';
import { Alert } from '../../components/alerts';
import AssignChargeStationLocationOwnerModal from './ownership';
import CustomerPeepCard from '../../components/customer-peep-card';
import ChargeStationDetail from '../charge-station/ChargeStationDetail';

function renderBusinessHours({ isClosed, is24Hours, openingHour, closingHour }: BusinessHour) {
  if (is24Hours) return '24 hours';
  if (isClosed) return 'Closed';

  return `${openingHour}-${closingHour}`;
}

function ChargeStationLocationPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const [fetching, response, error] = usePromiseFetch(`/charge-station-locales/${id}`);

  const chargeStationIds = response?.stationLocale.chargeStations.map(
    (station: { _id: any }) => station._id
  );

  const queryParams = new URLSearchParams();
  chargeStationIds?.forEach((id: string) => queryParams.append('chargeStation', id));

  const [txsFetching, txsResponse, txsError] = usePromiseFetch(
    `/stats/charge-station-locale/totalRevenue?${queryParams.toString()}`
  );

  // console.log(txsResponse);

  if (fetching) return <Loader />;
  if (!response || error) return <Navigate to="/error" />;

  const chargeStationLocale = response.stationLocale as ChargeStationLocation;

  const {
    _id,
    name,
    city,
    address,
    isEnabled,
    phoneNumber,
    remarks,
    facilities,
    currentlyClosed,
    description,
    businessType,
    businessHours,
    owner
  } = chargeStationLocale;
  const chargeStations = chargeStationLocale.chargeStations as ChargeStation[];

  return (
    <Container>
      <Row className="mb-3">
        {/* <Col sm={3} className="mb-3">
          <Link to={`/charge-station-locations/${_id}/stats`}>
            <Statsbox
              bgClass="dark"
              title="Stats"
              faIconName="fas fa-chart-line"
              iconColorClass="text-success"
              caption="Charge stations at this location"
            />
          </Link>
        </Col> */}
        <AssignChargeStationLocationOwnerModal
          chargeStationLocationId={id as string}
          existingOwner={owner as string}
        />
      </Row>
      {currentlyClosed && <Alert variant="warning">Owner has closed this business for today</Alert>}
      <div className="head-panel d-flex">
        <div>
          <h1 className="page-title">{name}</h1>
          <div>
            <i className="fas fa-charging-station"></i> &nbsp;
            {businessType}
          </div>
          <div>
            <i className="fas fa-map-marker-alt" /> &nbsp;
            {address}, {city}
          </div>
          <div>
            <i className="fas fa-phone-alt" /> &nbsp;{phoneNumber}
          </div>
          <div>
            {isEnabled ? (
              <div className="badge rounded-pill bg-success me-1">Enabled</div>
            ) : (
              <div className="badge rounded-pill bg-danger me-1">Disabled</div>
            )}
          </div>

          {/* {chargeStations.docks
            ?.map((dock) => dock.type)
            .filter((v, i, a) => a.indexOf(v) === i)
            .map((dockType) => (
              <div key={dockType} className="badge rounded-pill bg-primary me-1">
                {dockType}
              </div>
            ))} */}
        </div>

        <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.manager]}>
          <div className="head-right">
            <Link to={`${pathname}/edit`} className="btn btn-primary">
              Update charge station location
            </Link>
          </div>
        </AuthorizeView>
      </div>

      <Row>
        <div className="col-sm-12 col-md-8 order-2 order-md-1">
          <div className="body-card mb-3">
            <ul className="neutralize">
              <li>
                <Clipboard inlineTitle="Code:" text={_id as string} />
              </li>
            </ul>
          </div>
          <div className="body-card mb-3">
            <ul className="neutralize">
              <li>
                <strong>Business hours</strong>
              </li>
              {Object.entries(businessHours).map(([key, value]) => (
                <li key={key}>
                  <span style={{ textTransform: 'capitalize' }}>{key}</span>:{' '}
                  {renderBusinessHours(value)}
                </li>
              ))}
            </ul>
          </div>

          <div className="body-card mb-3">
            <strong>Facilities</strong>

            <ul className="">
              {facilities.map((facility) => (
                <li key={facility}>{facility}</li>
              ))}
            </ul>
          </div>

          <div className="body-card mb-3">
            <p style={{ whiteSpace: 'pre-line' }}>
              {description ? description : 'No business description available.'}
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 order-1 order-md-2 mb-3">
          <CustomerPeepCard customerId={owner} heading="Owner" />
        </div>
      </Row>

      <h4 style={{ marginBottom: '1rem', marginTop: '1rem' }}>Charge stations</h4>

      <h6 style={{ marginBottom: '1rem', marginTop: '1rem', color: 'orange' }}>
        Total Revenue: NPR {txsResponse?.totalRevenue}
      </h6>
      <h6 style={{ marginBottom: '1rem', marginTop: '1rem', color: 'orange' }}>
        Total Number of Transactions: {txsResponse?.totalNumberOfTrx}
      </h6>
      <h6 style={{ marginBottom: '1rem', marginTop: '1rem', color: 'orange' }}>
        Total Units consumption: {txsResponse?.totalEUnits}
      </h6>

      {chargeStations.map((chargeStation) => (
        <ChargeStationDetail key={chargeStation._id} chargeStation={chargeStation} />
      ))}
      <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.manager]}>
        <div className="head-right">
          <Link to={`/charge-stations/add?chargeStationLocale=${_id}`} className="btn btn-primary">
            Add charge station
          </Link>
        </div>
      </AuthorizeView>

      <hr className="hr" />

      <div className="body-card mb-3">
        <Row>
          <Col>
            <strong>Remarks:</strong>
            <p style={{ whiteSpace: 'pre-line' }}>{remarks ? remarks : 'NA'}</p>
          </Col>
        </Row>
      </div>

      <Map mappable={chargeStationLocale} />
    </Container>
  );
}

export default withDashboard(ChargeStationLocationPage);
