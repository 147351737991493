import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { get, patch } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import Loader from '../../components/commons/Loader';
import { Col, Row } from 'react-bootstrap';
import { Service, Servicing } from '../../interfaces';
import ServicingAppointmentForm from '../../components/servicing-appointment-form';
import { getDateOnly, getTimeOnly } from '../../utils/date';
import { formatAxiosError, scrollToTop } from '../../utils';
import { pushToast } from '../../actions/toast';
import { useDispatch } from 'react-redux';

const UpdateServiceCenterAppointment = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Servicing>({
    bike: '',
    customer: '',
    servicingDate: '',
    servicingTime: '',
    servicingType: '',
    staffRemarks: '',
    services: [],
    discount: 0
  });
  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, appointmentId } = useParams<{ id: string; appointmentId: string }>();

  useEffect(() => {
    get(`/service-centers/${id}/appointments/${appointmentId}`)
      .then((response: AxiosResponse) => {
        const appointment: Servicing = response.data.data.appointment;
        const date = appointment.servicingDate as string;
        appointment.servicingDate = getDateOnly(date);
        appointment.servicingTime = getTimeOnly(date);
        setFormProps(appointment);
        setFetching(false);
      })
      .catch((error) => {
        setFetchError(error);
        setFetching(false);
      });
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    patch(`/service-centers/${id}/appointments/${appointmentId}`, formProps)
      .then((response: AxiosResponse) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/servicing-appointments/${response.data.data.appointment._id}`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        scrollToTop();
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  function handleServiceChange(service: Service, index?: number, deleteIndex?: boolean) {
    // 1) Add new service
    if (index === undefined || index === null)
      return setFormProps({ ...formProps, services: [...formProps.services, service] });

    const newServices = [...formProps.services];

    // 2) Delete service
    if (deleteIndex) {
      newServices.splice(index, 1);
      return setFormProps({
        ...formProps,
        services: newServices
      });
    }

    // 3) Update service
    newServices[index] = service;
    setFormProps({
      ...formProps,
      services: newServices
    });
  }

  if (fetching) return <Loader />;
  if (fetchError) return <Navigate to="/error" />;

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Update Servicing Appointment</h1>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <ServicingAppointmentForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        handleServiceChange={handleServiceChange}
        formProps={formProps}
        serviceCenterId={id as string}
      ></ServicingAppointmentForm>
    </div>
  );
};

export default withDashboard(UpdateServiceCenterAppointment);
