import React from 'react';
import { Alert as RBAlert } from 'react-bootstrap';

export const Alert = ({
  variant,
  children,
  small
}: {
  variant: 'danger' | 'warning' | 'info';
  children: React.ReactNode;
  small?: boolean;
}): JSX.Element => {
  let faIconClass = 'fas fa-exclamation-circle';
  if (variant === 'danger') faIconClass = 'fas fa-exclamation-triangle';
  if (variant === 'info') faIconClass = 'fas fa-info-circle';

  const renderAlert = () => (
    <RBAlert variant={variant}>
      <i className={`${faIconClass} alert-${variant}`}></i> {children}
    </RBAlert>
  );
  return small ? <small>{renderAlert()}</small> : renderAlert();
};
