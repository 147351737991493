import React from 'react';
import { Modal } from 'react-bootstrap';
import Map from '../map';
import { Mappable } from '../../interfaces';

const LocationModal = ({
  coords,
  setCoords
}: {
  coords: Mappable | null;
  setCoords: React.Dispatch<React.SetStateAction<Mappable | null>>;
}): JSX.Element => {
  return coords ? (
    <Modal size="lg" show onHide={() => setCoords(null)}>
      <Modal.Header closeButton>
        <Modal.Title>Crash location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Coords:{' '}
          <strong>{`${coords.location.coordinates[1]}, ${coords.location.coordinates[0]}`}</strong>
        </p>
        <br />
        <Map mappable={coords} />
      </Modal.Body>
    </Modal>
  ) : (
    <noscript />
  );
};

export default LocationModal;
