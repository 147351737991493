import { combineReducers } from 'redux';
import { Auth } from '../actions';
import { IToast } from '../actions/toast';
import auth from './auth';
import toasts from './toasts';
import unreadAppointmentsCount from './servicingCount';

export interface StoreState {
  auth: Auth;
  unreadAppointmentsCount: number;
  toasts: IToast[];
}

export const reducers = combineReducers<StoreState>({
  auth,
  unreadAppointmentsCount,
  toasts
});
