import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import Paginate from '../../components/paginate';
import { BatteryLog } from '../../interfaces';
import { SearchFields } from '../../components/search-box';
import BatteryLogsTable from '../../components/battery-logs-table';

function BatteryLogsPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const { batteryId } = useParams<{ batteryId: string }>();
  const [fetching, response] = usePromiseFetch(
    `/batteries/logs?battery=${batteryId}&sort=-createdAt`,
    searchParams
  );

  if (fetching) return <TableLoader />;

  const { logs, size, total }: { logs: BatteryLog[]; size: number; total: number } = response
    ? response
    : {
        logs: [],
        size: 40,
        total: 0
      };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Battery logs</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[
              { label: 'Bike id', name: 'bike' },
              {
                label: 'Charging type',
                name: 'chargingType',
                type: 'dropDown',
                options: [
                  {
                    label: 'All',
                    value: ''
                  },
                  {
                    label: 'Yatri fast charge',
                    value: 'Yatri fast charge'
                  },
                  {
                    label: 'AC charge',
                    value: 'AC charge'
                  },
                  {
                    label: 'CCS',
                    value: 'CCS'
                  },
                  {
                    label: 'Discharge',
                    value: 'Discharge'
                  }
                ]
              },
              { label: 'Starting date', name: 'createdAt[gte]', type: 'date' },
              { label: 'Ending date', name: 'createdAt[lte]', type: 'date' }
            ]}
          />
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <BatteryLogsTable logs={logs} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(BatteryLogsPage);
