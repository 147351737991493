import React from 'react';
import { Col } from 'react-bootstrap';
import { usePromiseFetchSite } from '.';
import { Booking } from '../../interfaces';
import Statsbox from '../../components/stats-box';

const CancelledBookingsCount = (): JSX.Element => {
  const [fetching, response] = usePromiseFetchSite('/bookings?bookingStatus=Cancelled');

  const {
    bookings,
    size,
    total
  }: { bookings: Booking[]; size: number; total: number } = response ?? {
    bookings: [],
    size: 40,
    total: 0
  };

  return (
    <a href="/bookings?bookingStatus=Cancelled">
      <Statsbox
        iconColorClass="text-danger"
        bgClass="dark"
        fetching={fetching}
        title={total}
        caption="Cancelled bookings"
        faIconName="far fa-times-circle"
      />
    </a>
  );
};

export default CancelledBookingsCount;
