import React, { useState, useEffect } from 'react';
import { BikeLocation } from '../../interfaces';
import Loader from '../commons/Loader';
import NotFound from '../commons/NotFound';
import { AxiosResponse } from 'axios';
import { get } from '../../utils/axios';
import Map from '../map';

interface Props {
  bikeId: string;
}

const BikeLocationCard = ({ bikeId }: Props): JSX.Element => {
  const [fetching, setFetching] = useState(true);
  const [bikeLocation, setBikeLocation] = useState<BikeLocation | null>(null);

  useEffect(() => {
    if (!bikeId) return;
    get(`/bike-locations/bikes/${bikeId}`)
      .then((response: AxiosResponse) => {
        setBikeLocation(response.data.data.bikeLocation);
        setFetching(false);
      })
      .catch(() => setFetching(false));
  }, [bikeId]);

  if (fetching) return <Loader />;
  if (!bikeLocation) return <NotFound />;

  const { updatedAt } = bikeLocation;
  return (
    <div style={{ position: 'relative' }}>
      <p
        style={{ position: 'absolute', zIndex: 1, right: 4, top: 4 }}
        className="bg-white rounded-pill badge text-dark"
      >
        Location last updated at: <strong>{new Date(updatedAt).toLocaleString()}</strong>
      </p>
      <Map mappable={bikeLocation} />
    </div>
  );
};

export default BikeLocationCard;
