import React from 'react';
import { useSelector } from 'react-redux';
import NotFound from '../components/commons/NotFound';
import { selectAuth } from '../selectors';

function ProtectedRoute({
  children,
  authorizedRoles
}: {
  children: any;
  authorizedRoles: string[];
}): JSX.Element {
  const auth = useSelector(selectAuth);

  const isAuthorized = authorizedRoles.some((role: string) => {
    return auth?.roles?.includes(role);
  });

  return isAuthorized ? children : <NotFound />;
}

export default ProtectedRoute;
