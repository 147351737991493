import React from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Loader from '../../components/commons/Loader';
import { WalletTransaction } from '../../interfaces';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import CustomerPeepCard from '../../components/customer-peep-card';

function WalletTransactionPage(): JSX.Element {
  const { transactionId } = useParams<{ transactionId: string }>();

  const [fetching, response, error] = usePromiseFetch(`/wallets/transactions/${transactionId}`);

  if (fetching) return <Loader />;
  if (!response || error) return <Navigate to="/error" />;

  const transaction = response.transaction as WalletTransaction;

  const {
    _id,
    thirdPartyTxnId,
    walletId,
    customerId,
    totalAmount,
    amount,
    serviceCharge,
    service,
    paymentMethod,
    paymentState,
    createdAt,
    updatedAt,
    chargingPayment,
    remarks,
    createdBy
  } = transaction;
  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Payment</h1>
              <small className="text-muted">{_id}</small>
              <p>
                <span className="badge bg-success rounded-pill">{paymentState}</span>
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <div className="body-card">
        <Row>
          <div className="col-sm-12 col-md-8 order-2 order-md-1">
            <ul className="neutralize">
              <li>
                Wallet code:{' '}
                <Link className="link" to={`/payments?walletId=${walletId}`}>
                  {walletId}
                </Link>
              </li>
              <li>
                Customer:{' '}
                <Link className="link" to={`/customers/${customerId}`}>
                  {customerId}
                </Link>
              </li>
              <li>Payment method: {paymentMethod}</li>
              <li>Service: {service}</li>
              {createdBy && (
                <li>
                  Payment created by:{' '}
                  <Link className="link" to={`/staffs?_id=${createdBy}`}>
                    user
                  </Link>
                </li>
              )}
              <li>Payment date: {new Date(createdAt).toLocaleString()}</li>
              <li>Last updated date: {updatedAt ? new Date(updatedAt).toLocaleString() : 'NA'}</li>
              <li>Third-party txn id: {thirdPartyTxnId || 'NA'}</li>
              <li>
                Remarks: <i>{remarks}</i>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-4 order-1 order-md-2 mb-3">
            <CustomerPeepCard customerId={customerId} />
          </div>
        </Row>
      </div>
      <Row>
        <Col>
          <h5 style={{ margin: '1.5rem 0 0.5rem 0' }}>
            <strong>Payment summary</strong>
          </h5>
          <div className="body-card">
            <ul className="neutralize">
              <li>
                Amount:<strong> Rs. {amount.toLocaleString()}</strong>
              </li>
              <li>
                Service charge:<strong> Rs. {serviceCharge.toLocaleString()}</strong>
              </li>
              <li>
                <strong>
                  Total amount:
                  <span className="text-success"> Rs. {totalAmount.toLocaleString()}</span>
                </strong>
              </li>
            </ul>
          </div>
        </Col>
      </Row>

      {chargingPayment && (
        <div>
          <br />
          <div style={{ marginBottom: '1rem' }}>
            <h4>Service</h4>
            <p>
              <span className="badge bg-primary rounded-pill">{service}</span>
            </p>
          </div>
          <div className="body-card">
            <Row>
              <Col>
                <ul className="neutralize">
                  {chargingPayment.bike && (
                    <li>
                      Bike:{' '}
                      <Link className="link" to={`/bikes/${chargingPayment.bike}`}>
                        {chargingPayment.bike}
                      </Link>
                    </li>
                  )}
                  <li>
                    Electricity unit:{' '}
                    {typeof chargingPayment.eUnit !== 'undefined' ? (
                      `${chargingPayment.eUnit} kWh`
                    ) : (
                      <div className="badge bg-warning">Pending</div>
                    )}
                  </li>
                  <li>
                    Total charge time:{' '}
                    {typeof chargingPayment.totalChargeTime !== 'undefined'
                      ? `${(chargingPayment.totalChargeTime / 60).toFixed(2)} min`
                      : 'NA'}
                  </li>
                  {typeof chargingPayment.startingCharge !== 'undefined' &&
                    typeof chargingPayment.endingCharge !== 'undefined' && (
                      <li>
                        Session SoC:{' '}
                        {`${chargingPayment.startingCharge}% - ${chargingPayment.endingCharge}%`}
                      </li>
                    )}

                  <li>
                    Charge station:{' '}
                    <Link className="link" to={`/charge-stations/${chargingPayment.chargeStation}`}>
                      {chargingPayment.chargeStation}
                    </Link>{' '}
                  </li>
                  <li>
                    Charging dock:{' '}
                    {chargingPayment.chargingDock ? `#${chargingPayment.chargingDock}` : 'NA'}
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Container>
  );
}

export default withDashboard(WalletTransactionPage);
