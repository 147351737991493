import React, { SyntheticEvent } from 'react';
import { Form, Input } from '../form';
import { Customer } from '../../interfaces';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: Partial<Customer>;
}

const CustomerForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps } = props;
  const { email, dob, phoneNumber, licenseNumber, name } = formProps;
  return (
    <Form submitting={submitting} onSubmit={onSubmit}>
      <div className="body-card">
        <Input
          onChange={handleChange}
          label="Full name*"
          type="text"
          name="name"
          value={name}
          placeholder="Full name"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Email*"
          type="email"
          name="email"
          value={email}
          placeholder="Email"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Date of birth*"
          type="date"
          name="dob"
          value={dob}
          placeholder="Date of birth"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Phone number*"
          type="text"
          name="phoneNumber"
          value={phoneNumber}
          placeholder="Phone number"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="License number*"
          type="text"
          name="licenseNumber"
          value={licenseNumber}
          placeholder="License number"
          required
        ></Input>
      </div>
    </Form>
  );
};

export default CustomerForm;
