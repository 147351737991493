import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BatteryLog } from '../../interfaces';

const BatteryLogsTable = ({ logs }: { logs: BatteryLog[] }): JSX.Element => (
  <Table responsive hover variant="dark">
    <thead>
      <tr>
        <th>Code</th>
        <th>Bike</th>
        <th>SoH</th>
        <th>Lifecycle</th>
        <th>Charging type</th>
        <th>Starting charge</th>
        <th>Ending charge</th>
        <th>Current Odo</th>
        <th>Odo variance</th>
        <th>Timestamp</th>
      </tr>
    </thead>
    <tbody>
      {logs.map(
        ({
          _id,
          bike,
          soh,
          lifecycle,
          startingCharge,
          endingCharge,
          chargingType,
          createdAt,
          currentOdo,
          odoVariance
        }) => (
          <tr key={_id}>
            <td>
              <small>{_id}</small>
            </td>
            <td>
              <Link className="link" to={`/bikes/${bike}`}>
                {bike}
              </Link>
            </td>
            <td>{soh}%</td>
            <td>{lifecycle}</td>
            <td>{chargingType}</td>
            <td>{startingCharge}%</td>
            <td>{endingCharge}%</td>
            <td>{currentOdo}</td>
            <td>{odoVariance}</td>
            <td>{new Date(createdAt).toLocaleString()}</td>
          </tr>
        )
      )}
    </tbody>
  </Table>
);

export default BatteryLogsTable;
