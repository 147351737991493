import React, { SyntheticEvent } from 'react';
import { Form, Input } from '../form';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: {
    bikeId: string;
    customerId: string;
    purchaseDate: string;
  };
}

const BikeOwnershipForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps } = props;
  const { customerId, bikeId, purchaseDate } = formProps;
  return (
    <Form submitting={submitting} onSubmit={onSubmit}>
      <div className="body-card">
        <Input
          onChange={handleChange}
          label="Customer id*"
          type="text"
          name="customerId"
          value={customerId}
          placeholder="Customer id"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Bike id*"
          type="text"
          name="bikeId"
          value={bikeId}
          placeholder="Bike id"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Purchase date*"
          type="date"
          name="purchaseDate"
          value={purchaseDate}
          required
        ></Input>
      </div>
    </Form>
  );
};

export default BikeOwnershipForm;
