import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import { AxiosResponse } from 'axios';
import { ChargeStationLocation } from '../../interfaces';
import { formatAxiosError, scrollToTop } from '../../utils';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import ChargeStationLocationForm from '../../components/charge-station-location-form';

const AddChargeStationLocation = (): JSX.Element => {
  const [formProps, setFormProps] = useState<ChargeStationLocation>({
    name: '',
    phoneNumber: '',
    location: {
      coordinates: [0, 0]
    },
    isEnabled: true,
    city: '',
    address: '',
    remarks: '',
    owner: '',
    description: '',
    businessType: '',
    website: '',
    instagram: '',
    facebook: '',
    facilities: [],
    chargeStations: [],
    businessHours: {
      sunday: {
        isClosed: false,
        is24Hours: true,
        openingHour: '',
        closingHour: ''
      },
      monday: {
        isClosed: false,
        is24Hours: true,
        openingHour: '',
        closingHour: ''
      },
      tuesday: {
        isClosed: false,
        is24Hours: true,
        openingHour: '',
        closingHour: ''
      },
      wednesday: {
        isClosed: false,
        is24Hours: true,
        openingHour: '',
        closingHour: ''
      },
      thursday: {
        isClosed: false,
        is24Hours: true,
        openingHour: '',
        closingHour: ''
      },
      friday: {
        isClosed: false,
        is24Hours: true,
        openingHour: '',
        closingHour: ''
      },
      saturday: {
        isClosed: false,
        is24Hours: true,
        openingHour: '',
        closingHour: ''
      }
    }
  });
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    post('/charge-station-locales', formProps)
      .then((response: AxiosResponse) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/charge-station-locations/${response.data.data.stationLocale._id}`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        scrollToTop();
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;

    if (name.split('-')[0] === 'businessHours') {
      const newFormProps = {
        ...formProps,
        businessHours: {
          ...formProps.businessHours,
          [name.split('-')[1]]: {
            ...formProps.businessHours[name.split('-')[1]],
            [name.split('-')[2]]: type === 'checkbox' ? checked : value
          }
        }
      };
      return setFormProps(newFormProps);
    }

    if (name === 'facilities') {
      let facilities = [];
      if (checked) facilities = [...formProps.facilities, value];
      else facilities = formProps.facilities?.filter((r) => r !== value) as string[];

      return setFormProps({
        ...formProps,
        facilities
      });
    }

    if (type === 'checkbox') value = checked as any;
    else {
      if (name === 'lat')
        return setFormProps({
          ...formProps,
          location: {
            coordinates: [formProps.location.coordinates[0], Number(value)]
          }
        });

      if (name === 'lng')
        return setFormProps({
          ...formProps,
          location: {
            coordinates: [Number(value), formProps.location.coordinates[1]]
          }
        });
    }

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Add charge station location</h1>
        </div>
        <div className="head-right"></div>
      </div>
      <ChargeStationLocationForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      />
    </div>
  );
};

export default withDashboard(AddChargeStationLocation);
