import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUser } from './actions';
import './App.scss';
import { FullScreenLoader } from './components/commons/Loader';
import ToastUI from './components/toast';

const App: FunctionComponent = (props) => {
  const [fetching, setFetching] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(
        fetchUser(() => {
          setFetching(false);
        })
      );
    } else setFetching(false);
  }, [dispatch]);

  if (fetching) return <FullScreenLoader />;

  return (
    <div>
      {props.children} <ToastUI />
    </div>
  );
};

export default App;
