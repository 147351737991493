import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { usePromiseFetch } from '../../hooks';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { Customer } from '../../interfaces';
import Paginate from '../../components/paginate';
import { SearchFields } from '../../components/search-box';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';

function CustomersPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fetching, response] = usePromiseFetch('/customers?sort=-createdAt', searchParams);

  if (fetching) return <TableLoader />;

  const {
    customers,
    size,
    total
  }: { customers: Customer[]; size: number; total: number } = response
    ? response
    : { customers: [], size: 40, total: 0 };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Customers</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <AuthorizeView authorizedRoles={[ROLES.admin]}>
              <div className="head-right">
                <Link to="/customers/add" className="btn btn-primary">
                  Register new customer
                </Link>
              </div>
            </AuthorizeView>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[
              { label: 'Id', name: '_id' },
              { label: 'Name', name: 'name[match]' },
              { label: 'Email', name: 'email' },
              { label: 'Phone number', name: 'phoneNumber' }
            ]}
          />
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover>
              <thead className="table-light">
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone number</th>
                  <th>Enabled</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {customers.map(({ _id, name, phoneNumber, email, countryCode, enabled }) => (
                  <tr key={_id}>
                    <td>
                      <small>{_id}</small>
                    </td>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>
                      {countryCode} {phoneNumber}
                    </td>
                    <td>
                      {enabled ? (
                        <div className="badge rounded-pill bg-success">Enabled</div>
                      ) : (
                        <div className="badge rounded-pill bg-danger">Disabled</div>
                      )}
                    </td>
                    <td>
                      <Link className="btn btn-sm" to={`/customers/${_id}`}>
                        <i className="fas fa-eye"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(CustomersPage);
