import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ServiceCenterForm from '../../components/service-center-form';
import { post } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import { AxiosResponse } from 'axios';
import { ServiceCenter } from '../../interfaces';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';

const AddServiceCenter = (): JSX.Element => {
  const [formProps, setFormProps] = useState<ServiceCenter>({
    location: {
      coordinates: [0, 0]
    },
    address: '',
    phoneNumber: '',
    openingHour: '',
    closingHour: '',
    isEnabled: true
  });
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);

    post('/service-centers', formProps)
      .then((response: AxiosResponse) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/service-centers/${response.data.data.station._id}`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;
    else {
      if (name === 'lat')
        return setFormProps({
          ...formProps,
          location: {
            coordinates: [formProps.location.coordinates[0], Number(value)]
          }
        });

      if (name === 'lng')
        return setFormProps({
          ...formProps,
          location: {
            coordinates: [Number(value), formProps.location.coordinates[1]]
          }
        });
    }

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Add Service Center</h1>
        </div>
        <div className="head-right"></div>
      </div>
      <ServiceCenterForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></ServiceCenterForm>
    </div>
  );
};

export default withDashboard(AddServiceCenter);
