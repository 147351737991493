import React, { SyntheticEvent } from 'react';
import { TextArea, Checkbox, Input, SelectInput, Form } from '../../components/form';
import { Booking } from '../../interfaces';

interface Props {
  submitting: boolean;
  handleChange: (e: React.FormEvent<any>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  formProps: Booking;
}

const BookingForm = (props: Props): JSX.Element => {
  const { onSubmit, submitting, handleChange, formProps } = props;
  const {
    refunded,
    status,
    etd,
    remarks,
    bookingStatus,
    statusVinAssignment,
    statusChassisCompletion,
    statusAssemblyStart,
    statusAssemblyCompletion,
    statusQACheck,
    statusDeliveryReady
  } = formProps;
  return (
    <Form onSubmit={onSubmit} submitting={submitting}>
      <div className="body-card">
        <Input
          onChange={handleChange}
          label="Estimated delivery date*"
          type="text"
          name="etd"
          value={etd}
          placeholder="Estimated delivery date"
          required
        ></Input>
        <SelectInput
          onChange={handleChange}
          label="Delivery status*"
          type="text"
          name="status"
          options={[
            { label: 'Not delivered', value: 'Not delivered' },
            { label: 'Delivered', value: 'Delivered' }
          ]}
          value={status}
          required
        />
      </div>
      <div className="body-card">
        <SelectInput
          onChange={handleChange}
          label="Booking status*"
          type="text"
          name="bookingStatus"
          options={[
            { label: 'Active', value: 'Active' },
            { label: 'Cancelled', value: 'Cancelled' }
          ]}
          value={bookingStatus}
          required
        />
        <Checkbox
          id="refunded"
          onChange={handleChange}
          label="Refunded"
          name="refunded"
          type="checkbox"
          checked={refunded}
        ></Checkbox>
      </div>
      <div className="body-card">
        <SelectInput
          onChange={handleChange}
          label="Vin assignment*"
          type="text"
          name="statusVinAssignment"
          options={[
            { label: 'In Progress', value: 'In Progress' },
            { label: 'Completed', value: 'Completed' }
          ]}
          value={statusVinAssignment}
          required
        />
        <SelectInput
          onChange={handleChange}
          label="Chassis completion*"
          type="text"
          name="statusChassisCompletion"
          options={[
            { label: 'In Progress', value: 'In Progress' },
            { label: 'Completed', value: 'Completed' }
          ]}
          value={statusChassisCompletion}
          required
        />
        <SelectInput
          onChange={handleChange}
          label="Assembly start*"
          type="text"
          name="statusAssemblyStart"
          options={[
            { label: 'In Progress', value: 'In Progress' },
            { label: 'Completed', value: 'Completed' }
          ]}
          value={statusAssemblyStart}
          required
        />
        <SelectInput
          onChange={handleChange}
          label="Assembly completion*"
          type="text"
          name="statusAssemblyCompletion"
          options={[
            { label: 'In Progress', value: 'In Progress' },
            { label: 'Completed', value: 'Completed' }
          ]}
          value={statusAssemblyCompletion}
          required
        />
        <SelectInput
          onChange={handleChange}
          label="QA check*"
          type="text"
          name="statusQACheck"
          options={[
            { label: 'In Progress', value: 'In Progress' },
            { label: 'Completed', value: 'Completed' }
          ]}
          value={statusQACheck}
          required
        />
        <SelectInput
          onChange={handleChange}
          label="Delivery ready*"
          type="text"
          name="statusDeliveryReady"
          options={[
            { label: 'In Progress', value: 'In Progress' },
            { label: 'Completed', value: 'Completed' }
          ]}
          value={statusDeliveryReady}
          required
        />
      </div>
      <div className="body-card">
        <TextArea
          onChange={handleChange}
          label="Remarks"
          name="remarks"
          value={remarks}
          placeholder="Remarks"
        ></TextArea>
      </div>
    </Form>
  );
};

export default BookingForm;
