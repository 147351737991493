import React, { SyntheticEvent } from 'react';
import { TextArea, Checkbox, Form, Input } from '../form';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: {
    isEnabled: boolean;
    identifier: string;
    soc: number;
  };
}

const BatteryForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps } = props;
  const { identifier, isEnabled, soc } = formProps;
  return (
    <Form onSubmit={onSubmit} submitting={submitting}>
      <div className="body-card">
        <Checkbox
          switchMode
          id="isEnabled"
          onChange={handleChange}
          label="Enabled"
          name="isEnabled"
          type="checkbox"
          checked={isEnabled}
        ></Checkbox>
        <Input
          onChange={handleChange}
          label="Battery identifier*"
          type="text"
          name="identifier"
          value={identifier}
          placeholder="Battery identifier"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Current charge (%)*"
          type="number"
          min="0"
          max="100"
          name="soc"
          value={soc}
          placeholder="Current charge"
          required
        />
      </div>
    </Form>
  );
};

export default BatteryForm;
