import React, { SyntheticEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { post } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import { AxiosResponse } from 'axios';
import { Service, Servicing } from '../../interfaces';
import ServicingAppointmentForm from '../../components/servicing-appointment-form';
import { formatAxiosError, scrollToTop } from '../../utils';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';

const AddServicingAppointment = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Servicing>({
    bike: '',
    customer: '',
    servicingDate: '',
    servicingTime: '',
    servicingType: '',
    staffRemarks: '',
    services: [],
    discount: 0
  });

  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);

    post(`/service-centers/${id}/appointments`, formProps)
      .then((response: AxiosResponse) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/servicing-appointments/${response.data.data.appointment._id}`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        scrollToTop();
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  function handleServiceChange(service: Service, index?: number, deleteIndex?: boolean) {
    // 1) Add new service
    if (index === undefined || index === null)
      return setFormProps({ ...formProps, services: [...formProps.services, service] });

    const newServices = [...formProps.services];

    // 2) Delete service
    if (deleteIndex) {
      newServices.splice(index, 1);
      return setFormProps({
        ...formProps,
        services: newServices
      });
    }

    // 3) Update service
    newServices[index] = service;
    setFormProps({
      ...formProps,
      services: newServices
    });
  }

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Add Servicing Appointment</h1>
        </div>
        <div className="head-right"></div>
      </div>
      <ServicingAppointmentForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        handleServiceChange={handleServiceChange}
        formProps={formProps}
        serviceCenterId={id as string}
      />
    </div>
  );
};

export default withDashboard(AddServicingAppointment);
