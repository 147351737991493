import React, { SyntheticEvent, useState } from 'react';
import { Checkbox, Form, Input } from '../form';
import { ROLES } from '../../constants';
import { Staff } from '../../interfaces';
import StaffRoleInfoModal from './StaffRoleInfoModal';

interface Props {
  isEdit?: boolean;
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: Staff;
}

const allRoles = Object.values(ROLES);

const StaffForm = (props: Props): JSX.Element => {
  const [showStaffRoleModal, setShowStaffRoleModal] = useState(false);

  const { onSubmit, handleChange, submitting, formProps, isEdit } = props;
  const { name, email, enabled, password, phoneNumber, roles } = formProps;
  return (
    <>
      <Form submitting={submitting} onSubmit={onSubmit}>
        <div className="body-card">
          <Checkbox
            switchMode
            id="enabled"
            onChange={handleChange}
            label="Enabled"
            name="enabled"
            type="checkbox"
            checked={enabled}
          ></Checkbox>
          <Input
            onChange={handleChange}
            label="Full name*"
            type="text"
            name="name"
            value={name}
            placeholder="Full name"
            required
          ></Input>

          <Input
            onChange={handleChange}
            label="Email*"
            type="email"
            name="email"
            value={email}
            placeholder="Email"
            required
          ></Input>

          {!isEdit && (
            <Input
              onChange={handleChange}
              label="Password*"
              type="password"
              name="password"
              value={password}
              placeholder="Password"
              required
            ></Input>
          )}

          <Input
            onChange={handleChange}
            label="Phone number*"
            type="text"
            name="phoneNumber"
            value={phoneNumber}
            placeholder="Phone number"
            required
          ></Input>

          <label>
            Roles*{' '}
            <button
              type="button"
              className="btn-chromeless link"
              onClick={() => setShowStaffRoleModal(true)}
            >
              <i className="fas fa-info-circle"></i>
            </button>
          </label>

          <div className="row row-cols-lg-auto g-4 align-items-center">
            {allRoles.map((t) => (
              <Checkbox
                key={t}
                inline
                id={t}
                name="roles"
                type="checkbox"
                label={t?.split('ROLE_')[1]}
                value={t}
                checked={roles?.includes(t)}
                onChange={handleChange}
              />
            ))}
          </div>
        </div>
      </Form>
      <StaffRoleInfoModal
        showStaffRoleModal={showStaffRoleModal}
        setShowStaffRoleModal={setShowStaffRoleModal}
      />
    </>
  );
};

export default StaffForm;
