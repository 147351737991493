import React from 'react';

interface Props {
  label?: string;
  name: string;
  value: any;
  noFloat?: boolean;
  error?: boolean | string;
  errorMessage?: string;
  inputGroupText?: string;
  onChange: (e: any) => any;
  [key: string]: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Input = ({
  onChange,
  error,
  errorMessage,
  inputGroupText,
  label,
  noFloat,
  name,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <div className={`${inputGroupText && 'input-group'} form-group`} style={{ display: 'flex' }}>
      {inputGroupText && <span className="input-group-text">{inputGroupText}</span>}
      <div className={`${!noFloat ? 'form-floating' : ''} `} style={{ flexGrow: 1 }}>
        {label && noFloat && <label htmlFor={name}>{label}</label>}
        <input
          className={`form-control ${error && 'is-invalid'}`}
          onChange={onChange}
          autoComplete="off"
          name={name}
          id={name}
          {...otherProps}
        />
        {label && !noFloat && <label htmlFor={name}>{label}</label>}
      </div>

      {errorMessage && <span className="invalid-feedback">{errorMessage}</span>}
    </div>
  );
};
