import React from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Accordion, Card, CardGroup } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import { usePromiseFetch } from '../../hooks';
import Loader from '../../components/commons/Loader';
import { Customer } from '../../interfaces';
import { withDashboard } from '../../hoc';
import WalletLoadModal from './WalletLoadModal';
import AddNewBikeOwnershipModal from './AddNewBikeOwnershipModal';
import Statsbox from '../../components/stats-box';
import LoadCreditsModal from './LoadCreditsModal';
import UpdateRfid from './UpdateRfid';
import { getDateOnly } from '../../utils/date';

function UserWallet({ customerId }: { customerId: string }): JSX.Element {
  const [fetching, response, error] = usePromiseFetch(`/wallets/customer/${customerId}`);

  if (fetching)
    return (
      <div style={{ position: 'relative', width: '15px', height: '35px' }}>
        <Loader />
      </div>
    );
  if (!response || !response.wallet || error)
    return <p className="text-danger">Error fetching wallet. Please contact SW support.</p>;

  const wallet = response.wallet;

  return (
    <div>
      <p className="text-success">
        <strong>Rs. {wallet.balance}</strong>
      </p>
      <p>
        <Link className="link" to={`/payments?walletId=${wallet._id}`}>
          View all wallet transactions
        </Link>
      </p>
    </div>
  );
}

function CustomerPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const [fetching, response, error] = usePromiseFetch(`/customers/${id}`);

  if (fetching) return <Loader />;
  if (!response || error) return <Navigate to="/error" />;

  const customer = response.customer as Customer;
  const {
    _id,
    name,
    email,
    rfid,
    countryCode,
    phoneNumber,
    photo,
    bikes,
    energySubscription
  } = customer;

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">{name}</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={3} className="mb-3">
          <Link to={`/servicing-appointments?customer=${customer._id}`}>
            <Statsbox
              bgClass="dark"
              title="All servicing appointments"
              faIconName="fas fa-tools"
              iconColorClass="text-warning"
            />
          </Link>
        </Col>
        <WalletLoadModal customerId={_id as string} />
        <LoadCreditsModal customerId={_id as string} />
        <AddNewBikeOwnershipModal customerId={_id as string} />
      </Row>
      <div className="body-card">
        <Row>
          <Col>
            <ul className="neutralize">
              <li>
                <img
                  className="img-fluid"
                  style={{ borderRadius: '50%', maxWidth: 80 }}
                  src={photo}
                />
                <br />
                <br />
              </li>
              <li>
                <UserWallet customerId={id as string} />
              </li>
              <li>Code: {_id}</li>
              <li>Name: {name}</li>
              <li>Joined date: {new Date(customer.createdAt).toLocaleDateString()}</li>
              <li>
                Email: {email}{' '}
                {customer.isEmailVerified ? (
                  <span className="badge rounded-pill bg-success">Verified</span>
                ) : (
                  <span className="badge rounded-pill bg-danger">Unverfied</span>
                )}
              </li>
              <li>RFID: {rfid ?? 'NA'}</li>
              <li>
                Phone number: {countryCode} {phoneNumber}
              </li>
              <li>DoB: {customer.dob ? getDateOnly(customer.dob) : 'NA'}</li>
            </ul>
          </Col>
        </Row>
      </div>
      <div className="body-card">
        <Row>
          <Col>
            <div className="head-panel d-flex">
              <div className="head-left">
                <h4>Energy subscription</h4>
              </div>
              <div className="head-right"></div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <ul className="neutralize">
              {energySubscription ? (
                <>
                  <li>Membership: {energySubscription.membership}</li>
                  <li>
                    Energy volume remaining:{' '}
                    {energySubscription.energyVolumeRemaining
                      ? `${energySubscription.energyVolumeRemaining} kWh`
                      : 'NA'}
                  </li>
                  <li>Subscribed at: {new Date(energySubscription.createdAt).toLocaleString()}</li>
                  <li>
                    Expires at: {new Date(energySubscription.expirationDate).toLocaleString()}
                  </li>
                </>
              ) : (
                <li>NA</li>
              )}
            </ul>
          </Col>
        </Row>
      </div>
      <div className="body-card">
        <Row>
          <Col>
            <div className="head-panel d-flex">
              <div className="head-left">
                <h4>Customer bikes</h4>
              </div>
              <div className="head-right"></div>
            </div>
          </Col>
        </Row>
        <Row>
          <CardGroup>
            {!bikes?.length && <div>No bike found under this customer</div>}

            {bikes?.map((bike) => (
              <Card key={bike._id} style={{ maxWidth: '19rem' }}>
                <Link to={`/bikes/${bike._id}`}>
                  <Card.Img variant="top" src={bike.bikeImage} />
                  <Card.Body>
                    <Card.Title>{bike.bikeName}</Card.Title>
                    <Card.Text>
                      <small>code: {bike._id}</small>
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            ))}
          </CardGroup>
        </Row>
      </div>
      <Row>
        <Col>
          <Accordion className="mt-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <b>
                  <i className="fas fa-cog"></i> Advance settings
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ padding: 0 }}>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <UpdateRfid customerId={customer._id} rfid={customer.rfid as string} />
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default withDashboard(CustomerPage);
