import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ROLES } from '../../constants';
import AuthorizeView from '../../hoc/authorizeView';
import { ChargeStation } from '../../interfaces';
import ChargingDocks from './ChargingDocks';
import { usePromiseFetch } from '../../hooks/usePromiseFetch';

const ChargeStationDetail = ({ chargeStation }: { chargeStation: ChargeStation }): JSX.Element => {
  const { code, _id } = chargeStation;
  console.log('log form inner', chargeStation._id);

  const [txsFetching, txsResponse, txsError] = usePromiseFetch(
    `/stats/charge-station-locale/totalRevenue?chargeStation=${_id}`
  );

  return (
    <div className="body-card mb-3">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <Link className="link" to={`/charge-stations/${chargeStation._id}`}>
                <h4>#{code}</h4>
              </Link>
            </div>
            <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.manager]}>
              <div className="head-right">
                <Link
                  to={`/charge-stations/${chargeStation._id}/docks/add`}
                  className="btn btn-primary"
                >
                  Add charging dock
                </Link>
              </div>
            </AuthorizeView>
          </div>
        </Col>
        <Row>
          <div>
            <h6 style={{ marginBottom: '1rem', marginTop: '1rem', color: 'orange' }}>
              Total Revenue: NPR {txsResponse?.totalRevenue}
            </h6>
            <h6 style={{ marginBottom: '1rem', marginTop: '1rem', color: 'orange' }}>
              Total Number of Transactions: {txsResponse?.totalNumberOfTrx}
            </h6>
            <h6 style={{ marginBottom: '1rem', marginTop: '1rem', color: 'orange' }}>
              Total Units consumption: {txsResponse?.totalEUnits}
            </h6>
          </div>
        </Row>
      </Row>
      <ChargingDocks chargeStation={chargeStation} />
    </div>
  );
};

export default ChargeStationDetail;
