import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import PERMISSIONS from './permissions.json';

type IProps = {
  showStaffRoleModal: boolean;
  setShowStaffRoleModal: (flag: boolean) => void;
};

const StaffRoleInfoModal = ({ showStaffRoleModal, setShowStaffRoleModal }: IProps): JSX.Element => (
  <Modal size="lg" show={showStaffRoleModal} onHide={() => setShowStaffRoleModal(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Staff roles</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div style={{ margin: '1.5rem 0' }}>
        <h4 style={{ fontSize: '1rem' }}>ADMIN</h4>
        <p>
          Admins have full access and can manage all features and settings including creating other
          admins and staffs and assign different permissions.
        </p>
      </div>
      <div style={{ margin: '1.5rem 0' }}>
        <h4 style={{ fontSize: '1rem' }}>EC_STAFFS</h4>
        <p>
          EC_STAFFS have a very low level access and can create, or update limited collections i.e.
          bookings, test rides, faqs etc.
        </p>
      </div>

      {PERMISSIONS.map(({ role, description, collections }, index) => (
        <div key={index} style={{ margin: '1.5rem 0' }}>
          <h4 style={{ fontSize: '1rem' }}>{role}</h4>
          <p>{description}</p>
          <Table responsive hover bordered variant="dark">
            <thead>
              <tr>
                <th>Collections</th>
                <th>Create</th>
                <th>Read</th>
                <th>Update</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {collections.map(({ collection, permissions }, index) => (
                <tr key={index}>
                  <td>{collection}</td>
                  <td>
                    {permissions.create ? (
                      <i className="fas fa-check text-success" />
                    ) : (
                      <i className="fas fa-times text-muted" />
                    )}
                  </td>
                  <td>
                    {permissions.read ? (
                      <i className="fas fa-check text-success" />
                    ) : (
                      <i className="fas fa-times text-muted" />
                    )}
                  </td>
                  <td>
                    {permissions.update ? (
                      <i className="fas fa-check text-success" />
                    ) : (
                      <i className="fas fa-times text-muted" />
                    )}
                  </td>
                  <td>
                    {permissions.delete ? (
                      <i className="fas fa-check text-success" />
                    ) : (
                      <i className="fas fa-times text-muted" />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
    </Modal.Body>
  </Modal>
);

export default StaffRoleInfoModal;
