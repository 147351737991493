import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BikeForm from '../../components/bike-form';
import { post } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import { AxiosResponse } from 'axios';
import { usePromiseFetch } from '../../hooks';
import { BikeModel } from '../../interfaces';
import Loader from '../../components/commons/Loader';
import { formatAxiosError } from '../../utils';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';

const AddBikePage = (): JSX.Element => {
  const [formProps, setFormProps] = useState({
    bikeModelId: '',
    batteryId: '',
    bikeColor: '',
    mfd: new Date().toISOString().split('T')[0],
    vinNumber: '',
    chargerNumber: '',
    controllerNumber: '',
    motorNumber: '',
    simNumber: '',
    keyNumber: '',
    configuration: {}
  });
  const [submitting, setSubmitting] = useState(false);
  const [fetching, response] = usePromiseFetch('/bike-models');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { bikeModels }: { bikeModels: BikeModel[] } = response
    ? response
    : {
        bikeModels: []
      };

  function onSubmit(event: SyntheticEvent) {
    setSubmitting(true);
    event.preventDefault();

    post('/bikes', formProps)
      .then((response: AxiosResponse) => {
        setSubmitting(false);
        dispatch(
          pushToast({ bodyText: 'Action successful', headText: 'Success', variant: 'success' })
        );
        navigate(`/bikes/${response.data.data.bike._id}`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ bodyText: formatAxiosError(error), headText: 'Error', variant: 'error' })
        );
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;

    if (name.split('configuration.')[1])
      return setFormProps({
        ...formProps,
        configuration: { ...formProps.configuration, [name.split('configuration.')[1]]: value }
      });

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Add Bike</h1>
        </div>
        <div className="head-right"></div>
      </div>
      {fetching ? (
        <Loader />
      ) : (
        <BikeForm
          submitting={submitting}
          bikeModels={bikeModels}
          onSubmit={onSubmit}
          handleChange={handleChange}
          formProps={formProps}
        />
      )}
    </div>
  );
};

export default withDashboard(AddBikePage);
