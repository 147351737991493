import React from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { Servicing } from '../../interfaces';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import Paginate from '../../components/paginate';
import ServicingAppointmentsTable from '../../components/servicing-appointments-table';

function ServiceCenterAppointmentsPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams<{ id: string }>();

  const [fetching, response, error] = usePromiseFetch(
    `/service-centers/${id}/appointments?sort=-servicingDate`,
    searchParams
  );

  if (fetching) return <TableLoader />;
  if (!response || error) return <Navigate to="/error" />;

  const {
    appointments,
    size,
    total
  }: { appointments: Servicing[]; size: number; total: number } = response
    ? response
    : { appointments: [], size: 40, total: 0 };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Appointments</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <div className="head-right">
              <Link to={`/service-centers/${id}/appointments/add`} className="btn btn-primary">
                Create appointment
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <ServicingAppointmentsTable appointments={appointments} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(ServiceCenterAppointmentsPage);
