import React, { SyntheticEvent, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';
import { put } from '../../utils/axios';
import { Form, Input } from '../form';

const PasswordChangeForm = (): JSX.Element => {
  const [formProps, setFormProps] = useState({
    currentPassword: '',
    newPassword: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  function handleOnChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;

    setFormProps({ ...formProps, [name]: value });
  }

  function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    put('/users/current-user/change-password', formProps)
      .then(({ data: { data } }) => {
        setSubmitting(false);
        setFormProps({
          currentPassword: '',
          newPassword: ''
        });
        localStorage.setItem('token', data.token);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
      });
  }

  return (
    <>
      <h4 className="section-title">Change Password</h4>
      <Row>
        <Col>
          <Form submitting={submitting} onSubmit={handleSubmit}>
            <div className="body-card">
              <Input
                type="password"
                name="currentPassword"
                value={formProps.currentPassword}
                label="Current passsword*"
                placeholder="Current passsword"
                onChange={handleOnChange}
              />
              <Input
                type="password"
                name="newPassword"
                value={formProps.newPassword}
                label="New password*"
                placeholder="New password"
                onChange={handleOnChange}
              />
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PasswordChangeForm;
