import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { del } from '../../utils/axios';
import { fetchServicingCount } from '../../actions';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';

interface Props {
  servicingId: string;
  serviceCenterId: string;
}
const AppointmentCancelButton = ({ servicingId, serviceCenterId }: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleAppointmentCancel() {
    if (!confirm('Are you sure to cancel appointment?')) return;

    del(`/service-centers/${serviceCenterId}/appointments/${servicingId}`)
      .then(() => {
        dispatch(fetchServicingCount());
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/service-centers/${serviceCenterId}/appointments`);
      })
      .catch((error) =>
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        )
      );
  }

  return (
    <button onClick={handleAppointmentCancel} className="btn btn-danger float-end mt-3">
      Cancel appointment
    </button>
  );
};

export default AppointmentCancelButton;
