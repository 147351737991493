import { Dispatch } from 'redux';
import { ActionTypes } from './types';

export type IToast = {
  headText: string;
  bodyText: string;
  variant?: 'success' | 'error';
  id?: number;
};

export const pushToast = (toast: IToast) => async (dispatch: Dispatch): Promise<void> => {
  dispatch({
    type: ActionTypes.PUSH_TOAST,
    payload: toast
  });
};

export const removeToast = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch({
    type: ActionTypes.POP_TOAST
  });
};
