import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { ServiceCenter, Servicing } from '../../interfaces';
import { toLocalDateAndFormatWithTime } from '../../utils/date';

function servicingStatusBadge(servicingStatus: string): JSX.Element {
  let badgeClassName = 'bg-success';
  if (servicingStatus === 'In progress') badgeClassName = 'bg-warning';
  if (servicingStatus === 'Cancelled') badgeClassName = 'bg-danger';

  return <div className={`badge rounded-pill ${badgeClassName}`}>{servicingStatus}</div>;
}

const ServicingAppointmentsTable = ({
  appointments
}: {
  appointments: Servicing[];
}): JSX.Element => {
  const [showServices, setShowServices] = useState(false);
  return (
    <div>
      <div className="clearfix">
        <button
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          title="Show services only mode"
          type="button"
          style={{ float: 'right' }}
          className={`btn ${showServices ? 'btn-primary' : 'btn-white outline'} mb-3`}
          onClick={() => setShowServices(!showServices)}
        >
          <i className="fas fa-bars"></i>
        </button>
      </div>
      {showServices ? (
        <div className="list-group">
          <h4 className="section-title">Services</h4>
          {appointments.map(
            ({
              _id,
              currentOdo,
              servicingDate,
              services,
              discount,
              servicingStatus,
              servicingType
            }) => (
              <div key={_id} className="list-group-item list-group-item-action">
                <Link to={`/servicing-appointments/${_id}`}>
                  <ul className="list-unstyled">
                    <li>
                      <div
                        className={`badge rounded-pill ${servicingStatusBadge(
                          servicingStatus as string
                        )} mb-1`}
                      >
                        {servicingStatus}
                      </div>
                    </li>
                    <li>
                      <strong>Code:</strong> <small>{_id}</small>
                    </li>
                    <li>
                      <strong>Service date: </strong>
                      {toLocalDateAndFormatWithTime(servicingDate)}
                    </li>
                    <li>
                      <strong>Service type: </strong>
                      {servicingType}
                    </li>
                    <li>
                      <strong>Odo:</strong> {currentOdo ? `${currentOdo} km` : 'NA'}
                    </li>
                    <li>
                      <strong>Total cost:</strong> Rs.{' '}
                      {services.reduce((a, b) => a + b.cost, 0) - discount}
                    </li>
                    <li>
                      <strong>Total discount:</strong> Rs. {discount}
                    </li>
                    <li>
                      <strong>Services:</strong>
                      <ul>
                        {services.map((service, i) => (
                          <li key={i}>
                            {service.serviceName} • Rs. {service.cost}
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </Link>
              </div>
            )
          )}
        </div>
      ) : (
        <Table responsive hover>
          <thead className="table-light">
            <tr>
              <th>Code</th>
              <th>Servicing date</th>
              <th>Type</th>
              <th>Odo</th>
              <th>Status</th>
              <th>Service center</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {appointments.map(
              ({
                _id,
                servicingDate,
                servicingType,
                servicingStatus,
                serviceCenter,
                isRead,
                currentOdo
              }) => (
                <tr key={_id} style={!isRead ? { background: '#fff', fontWeight: 'bold' } : {}}>
                  <td>
                    <small>{_id}</small>
                  </td>
                  <td>{toLocalDateAndFormatWithTime(servicingDate)}</td>
                  <td>{servicingType}</td>
                  <td>{currentOdo ? `${currentOdo} km` : 'NA'}</td>
                  <td>{servicingStatusBadge(servicingStatus as string)}</td>
                  <td>
                    <Link
                      className="link"
                      to={`/service-centers/${(serviceCenter as ServiceCenter)._id}`}
                    >
                      {(serviceCenter as ServiceCenter).address}
                    </Link>
                  </td>
                  <td>
                    <Link className="btn btn-sm" to={`/servicing-appointments/${_id}`}>
                      <i className="fas fa-eye"></i>
                    </Link>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ServicingAppointmentsTable;
