import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import BatteryForm from '../../components/battery-form';
import { Battery } from '../../interfaces';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';

const AddBattery = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Battery>({
    identifier: '',
    isEnabled: true,
    soc: 100
  });
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    post('/batteries', formProps)
      .then(() => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/batteries`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Add Battery</h1>
        </div>
        <div className="head-right"></div>
      </div>
      <BatteryForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></BatteryForm>
    </div>
  );
};

export default withDashboard(AddBattery);
