import React from 'react';
import { camelCaseToSpaceSeprated } from '../../utils';
import { SelectInput } from '../form';
import configurations from './configurations';

const Configurator = ({
  bikeModelId,
  handleChange,
  configuration
}: {
  bikeModelId: string;
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  configuration: { [key: string]: any };
}): JSX.Element | null => {
  const config = configurations[bikeModelId];

  if (!config) return null;

  return (
    <div className="body-card">
      <label className="mb-2">Configuration</label>
      {Object.entries(config).map(([key]) => (
        <SelectInput
          key={key}
          label={camelCaseToSpaceSeprated(config[key].name)}
          name={`configuration.${config[key].name}`}
          value={configuration[key]}
          onChange={handleChange}
          options={[
            { label: '-', value: '' },
            ...config[key].options.map((val: string) => ({
              label: val,
              value: val
            }))
          ]}
          required
        />
      ))}
    </div>
  );
};

export default Configurator;
