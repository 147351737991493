import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { Booking } from '../../interfaces';
import Paginate from '../../components/paginate';
import { SearchFields } from '../../components/search-box';
import { get } from '../../utils/yatriSiteAxios';
import { AxiosResponse } from 'axios';
import BookingsCountChart from './BookingsCountChart';
import ExportBookingsModal from './ExportBookingsModal';
import ActiveBookingsCount from './ActiveBookingsCount';
import CancelledBookingsCount from './CancelledBookingsCount';
import DeliveredBookingsCount from './DeliveredBookingsCount';

export function usePromiseFetchSite(
  url: string,
  params?: { [key: string]: any }
): [boolean, any, Error | null] {
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setFetching(true);
    get(url, params)
      .then((response: AxiosResponse) => {
        setData(response.data.data);
        setFetching(false);
      })
      .catch((err: Error) => {
        setError(err);
        setFetching(false);
      });
  }, [url, params]);

  return [fetching, data, error];
}

function BookingsPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams({
    sort: '-bookingDate'
  });
  const [openExportModal, setOpenExportModal] = useState(false);

  const [fetching, response] = usePromiseFetchSite('/bookings', searchParams);

  const { bookings, size, total }: { bookings: Booking[]; size: number; total: number } = response
    ? response
    : { bookings: [], size: 40, total: 0 };

  const totalPage = Math.ceil(total / size);

  return (
    <>
      <Container>
        <Row>
          <Col sm={4} className="mb-3">
            <ActiveBookingsCount />
          </Col>
          <Col sm={4} className="mb-3">
            <DeliveredBookingsCount />
          </Col>
          <Col sm={4} className="mb-3">
            <CancelledBookingsCount />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <BookingsCountChart />
          </Col>
        </Row>
      </Container>
      <br />
      {fetching ? (
        <TableLoader />
      ) : (
        <Container>
          <Row>
            <Col>
              <div className="head-panel d-flex">
                <div className="head-left">
                  <h1 className="page-title">Bookings</h1>
                  <span className="text-muted">{total} entries found</span>
                </div>
                <div className="head-right">
                  <button className="btn-chromeless link" onClick={() => setOpenExportModal(true)}>
                    <i className="fas fa-file-download"></i> Export
                  </button>

                  {/* <a href={`${process.env.REACT_APP_YATRI_SITE_API_URL}/bookings/download/csv`}>
                
              </a> */}
                  {/* <Link to="/bookings/add" className="btn btn-primary">
                Add new booking
              </Link> */}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <SearchFields
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                fields={[
                  { label: 'Id', name: '_id' },
                  { label: 'First name', name: 'firstName[match]' },
                  {
                    label: 'Booking status',
                    name: 'bookingStatus',
                    type: 'dropDown',
                    options: [
                      {
                        label: 'All',
                        value: ''
                      },
                      {
                        label: 'Active',
                        value: 'Active'
                      },
                      {
                        label: 'Cancelled',
                        value: 'Cancelled'
                      }
                    ]
                  },
                  {
                    label: 'Delivery status',
                    name: 'status',
                    type: 'dropDown',
                    options: [
                      {
                        label: 'All',
                        value: ''
                      },
                      {
                        label: 'Delivered',
                        value: 'Delivered'
                      },
                      {
                        label: 'Not delivered',
                        value: 'Not delivered'
                      }
                    ]
                  },
                  {
                    label: 'Bike',
                    name: 'bike',
                    type: 'dropDown',
                    options: [
                      {
                        label: 'All',
                        value: ''
                      },
                      {
                        label: 'Project Zero',
                        value: 'Project Zero'
                      },
                      {
                        label: 'Project One',
                        value: 'Project One'
                      }
                    ]
                  },
                  { label: 'Email', name: 'email' }
                ]}
              />
            </Col>
          </Row>

          <div className="table-box">
            <Row>
              <Col>
                <Table responsive hover>
                  <thead className="table-light">
                    <tr>
                      <th>Code</th>
                      <th>Booking status</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone number</th>
                      <th>Booking date</th>
                      <th>Delivery date</th>
                      <th>Bike</th>
                      <th>Delivery status</th>
                      <th>Configurator</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.map(
                      ({
                        _id,
                        bookingStatus,
                        firstName,
                        lastName,
                        phoneNumber,
                        email,
                        bookingDate,
                        etd,
                        bike,
                        status,
                        configurator
                      }) => (
                        <tr key={_id}>
                          <td>
                            <small>{_id}</small>
                          </td>
                          <td>
                            {bookingStatus === 'Active' ? (
                              <div className="badge rounded-pill bg-success">Active</div>
                            ) : (
                              <div className="badge rounded-pill bg-danger">{bookingStatus}</div>
                            )}
                          </td>
                          <td>{`${firstName} ${lastName}`}</td>
                          <td>{email}</td>
                          <td>{phoneNumber}</td>
                          <td>{new Date(bookingDate as string).toLocaleDateString()}</td>
                          <td>{etd}</td>
                          <td>{bike}</td>
                          <td>
                            {status === 'Delivered' ? (
                              <div className="badge rounded-pill bg-success">{status}</div>
                            ) : (
                              <div className="badge rounded-pill bg-warning">{status}</div>
                            )}
                          </td>
                          <td>
                            {configurator ? (
                              <div className="badge rounded-pill bg-success">Configured</div>
                            ) : (
                              <div className="badge rounded-pill bg-warning">Not configured</div>
                            )}
                          </td>
                          <td>
                            <Link className="btn" to={`/bookings/${_id}`}>
                              <i className="fas fa-eye"></i>
                            </Link>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col>
                <Paginate
                  totalPage={totalPage}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                />
              </Col>
            </Row>
          </div>
          <ExportBookingsModal
            openExportModal={openExportModal}
            setOpenExportModal={setOpenExportModal}
            searchParams={searchParams}
          />
        </Container>
      )}
    </>
  );
}

export default withDashboard(BookingsPage);
