import { FormikProps } from 'formik';
import React from 'react';
import { Table } from 'react-bootstrap';
import { Alert } from '../alerts';
import { TextArea, Checkbox, FileInput, Form, Input } from '../form';
import CustomModal from '../modal';
import ColorsField from './ColorField';
import { BikeModelFormProps } from './types';

interface Props {
  formik: FormikProps<BikeModelFormProps>;
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  handleImageChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: any) => void;
  submitting: boolean;
  formProps: BikeModelFormProps;
  isUpdate?: boolean;
}

const BikeModelForm = (props: Props): JSX.Element => {
  const {
    formik,
    onSubmit,
    handleChange,
    handleImageChange,
    submitting,
    formProps,
    isUpdate
  } = props;
  const {
    name,
    _id,
    isEnabled,
    description,
    makeYear,
    currentVCUSoftwareVersion,
    currentVCUSoftwareChangeLog,
    colors
  } = formProps;
  return (
    <div>
      <Form submitting={submitting} onSubmit={onSubmit}>
        <div className="body-card">
          <Checkbox
            switchMode
            id="isEnabled"
            onChange={handleChange}
            label="Enabled"
            name="isEnabled"
            type="checkbox"
            checked={isEnabled}
          ></Checkbox>

          <Alert variant="warning" small>
            You cannot update the bike model once its set.
          </Alert>

          <Input
            onChange={handleChange as any}
            label="Bike model*"
            placeholder="Bike model"
            autoComplete="off"
            type="text"
            name="_id"
            value={_id}
            disabled={isUpdate}
            error={formik.touched._id && formik.errors._id}
            errorMessage={formik.errors._id}
          />
          <Input
            onChange={handleChange}
            label="Model name*"
            placeholder="Model name"
            type="text"
            name="name"
            value={name}
            error={formik.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          ></Input>
          <Input
            onChange={handleChange}
            label="Make year*"
            placeholder="Make year"
            type="number"
            name="makeYear"
            value={makeYear}
            error={formik.touched.makeYear && formik.errors.makeYear}
            errorMessage={formik.errors.makeYear}
          ></Input>

          <CustomModal
            headerText="Current VCU software version"
            ModalLauncher={(props) => (
              <div {...props} className="btn-chromeless w-100">
                <Alert variant="warning" small>
                  <strong>
                    Click here for detailed guideline on how to set latest VCU software release
                  </strong>
                </Alert>
              </div>
            )}
          >
            VCU software version has two informations separated by a{' '}
            <strong>
              hyphen <i>&quot;-&quot;</i>
            </strong>
            <ul className="mb-3">
              <li>
                The first is the version number i.e. <code>1.0.3</code>
              </li>
              <li>
                Second part consist of board names consisting file size and a CRC number. Board name
                is followed by{' '}
                <strong>
                  <i>&quot;=&quot;</i>
                </strong>{' '}
                followed by new update&apos;s file size which is then followed by{' '}
                <strong>&quot;|&quot;</strong> sign and then the CRC number. Multiple board name can
                be set by using comma <strong>&quot;,&quot;</strong>. Board names must be in all{' '}
                <strong>uppercase letters with no spaces</strong>. Instead of spaces use{' '}
                <strong>underscore &quot;_&quot; </strong> if necessary i.e.
                <code>VCU=245676|0xe44be0fa,DISPLAY=345456|0xe9877ae</code>
              </li>
            </ul>
            <Table responsive hover bordered variant="dark">
              <thead>
                <tr>
                  <th>Board name</th>
                  <th>-</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>VCU board</td>
                  <td>VCU</td>
                </tr>
                <tr>
                  <td>Communication board</td>
                  <td>COMM</td>
                </tr>
                <tr>
                  <td>Display board</td>
                  <td>DISPLAY</td>
                </tr>
              </tbody>
            </Table>
            <p className="mt-3">
              For e.g. a new software release of version 2.0.3 which has new updates only on VCU
              board and display board and has a total file size of 245676 bytes and 345456 bytes
              respectively will be written as{' '}
              <code>2.0.3-VCU=245676|0xe44be0fa,DISPLAY=345456|0xe9877ae</code>
            </p>
          </CustomModal>

          <Input
            onChange={handleChange}
            label="Current VCU software version*"
            placeholder="Current VCU software version"
            type="text"
            name="currentVCUSoftwareVersion"
            value={currentVCUSoftwareVersion}
            error={
              formik.touched.currentVCUSoftwareVersion && formik.errors.currentVCUSoftwareVersion
            }
            errorMessage={formik.errors.currentVCUSoftwareVersion}
          ></Input>
          <TextArea
            onChange={handleChange}
            label="Current VCU software change log*"
            placeholder="Current VCU software change log"
            type="text"
            name="currentVCUSoftwareChangeLog"
            value={currentVCUSoftwareChangeLog}
            error={
              formik.touched.currentVCUSoftwareChangeLog &&
              formik.errors.currentVCUSoftwareChangeLog
            }
            errorMessage={formik.errors.currentVCUSoftwareChangeLog}
          ></TextArea>
        </div>
        <ColorsField handleChange={handleChange} colors={colors} />
        <div className="body-card">
          <TextArea
            onChange={handleChange}
            label="Description*"
            placeholder="Description"
            type="text"
            name="description"
            value={description}
            error={formik.touched.description && formik.errors.description}
            errorMessage={formik.errors.description}
          ></TextArea>
        </div>
        <div className="body-card">
          <FileInput accept="image/*" handleChange={handleImageChange} />
        </div>
      </Form>
    </div>
  );
};

export default BikeModelForm;
