import { ActionTypes } from './types';
import { Dispatch } from 'redux';
import axios from '../utils/axios';
import catchAsync from '../utils/catchAsync';
import { User } from '../interfaces';

export type Auth = User | null;

export interface FetchUserAction {
  type: ActionTypes.FETCH_USER;
  payload: Auth;
}

export const signIn = (
  formProps: { email: string; password: string },
  errorCallback?: (error: string) => void
) => (dispatch: Dispatch): void => {
  catchAsync(async () => {
    const response = await axios.post('/users/login', formProps);
    const data = response.data.data;

    /*
     * Save token to local storage
     */
    localStorage.setItem('token', data.token);
    location.reload();
  }, errorCallback);
};

export const fetchUser = (callback: () => void, user?: Auth) => (dispatch: Dispatch): void => {
  catchAsync(
    async () => {
      let _user;

      if (user) _user = user;
      else {
        const response = await axios.get('/users/current-user');
        _user = response.data.data.user;
      }

      dispatch<FetchUserAction>({
        type: ActionTypes.FETCH_USER,
        payload: _user
      });
      callback();
    },
    () => {
      callback();
      localStorage.removeItem('token');
    }
  );
};

export const signOut = (): void => {
  window.location.href = '/';
  localStorage.removeItem('token');
};
