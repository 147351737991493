import React from 'react';
import { Container } from 'react-bootstrap';
import BikeCarshLogs from '../../components/bike-carsh-logs';
import { withDashboard } from '../../hoc';

function BikeCrashLogsPage(): JSX.Element {
  return (
    <Container>
      <BikeCarshLogs />
    </Container>
  );
}

export default withDashboard(BikeCrashLogsPage);
