import React, { SyntheticEvent } from 'react';
import { Form, Input } from '../form';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: {
    owner: string;
  };
  isUpdate: boolean;
}

const OwnershipForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps, isUpdate } = props;
  const { owner } = formProps;
  return (
    <Form submitting={submitting} onSubmit={onSubmit} btnText={isUpdate ? 'Transfer' : 'Add'}>
      <div className="body-card">
        <Input
          onChange={handleChange}
          label="Owner id*"
          type="text"
          name="owner"
          value={owner}
          placeholder="Owner id"
          required
        ></Input>
      </div>
    </Form>
  );
};

export default OwnershipForm;
