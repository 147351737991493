import React, { SyntheticEvent, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Alert } from '../../components/alerts';
import { Form, Input } from '../../components/form';
import CustomModal from '../../components/modal';
import { ROLES } from '../../constants';
import AuthorizeView from '../../hoc/authorizeView';
import { post } from '../../utils/axios';

const UpdateRfid = ({ customerId, rfid }: { customerId: string; rfid: string }): JSX.Element => {
  const [formProps, setFormProps] = useState({
    rfid
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (!formProps.rfid) return;

    setError('');
    setSubmitting(true);

    post(`/customers/${customerId}/rfid`, formProps)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.response.data.message);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.finance]}>
      <Col sm={3}>
        <CustomModal
          headerText="Update customer RFID"
          ModalLauncher={(props) => (
            <button {...props} className="btn-chromeless w-100" style={{ textAlign: 'left' }}>
              Update customer RFID
            </button>
          )}
        >
          {error && <Alert variant="danger">{error}</Alert>}

          <Form onSubmit={onSubmit} submitting={submitting} btnText="Submit">
            <div className="body-card">
              <Input
                onChange={handleChange}
                label="RFID*"
                type="text"
                name="rfid"
                value={formProps.rfid}
                placeholder="RFID"
                required
              />
            </div>
          </Form>
        </CustomModal>
      </Col>
    </AuthorizeView>
  );
};

export default UpdateRfid;
