import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { get } from '../../utils/axios';
import { Col } from 'react-bootstrap';
import Statsbox from '../stats-box';

const TotalVehicleOdo = ({ bikeModel }: { bikeModel: 'P1' }): JSX.Element => {
  const [totalVehicleOdo, setTotalVehicleOdo] = useState(0);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    get(`/stats/bikes/model/${bikeModel}/odo`).then((response: AxiosResponse) => {
      setTotalVehicleOdo(response.data.data.total);
      setFetching(false);
    });
  }, []);

  return (
    <>
      <Col xs={6} md={3}>
        <Statsbox
          iconColorClass="text-primary"
          bgClass="dark"
          fetching={fetching}
          faIconName="fas fa-road"
          title={`${totalVehicleOdo.toLocaleString()} km`}
          caption={`Total ${bikeModel} odo`}
        />
      </Col>
      {bikeModel === 'P1' && (
        <Col xs={6} md={3}>
          <Statsbox
            iconColorClass="text-success"
            bgClass="dark"
            fetching={fetching}
            faIconName="fas fa-leaf"
            title={`${(totalVehicleOdo * 0.08).toLocaleString()} kg`}
            caption={`Total ${bikeModel} carbon offset`}
          />
        </Col>
      )}
    </>
  );
};

export default TotalVehicleOdo;
