import React, { FormEvent } from 'react';

interface Props {
  handleChange: (e: FormEvent<HTMLInputElement>) => void;
  timeSlots: { slot: string; available: boolean }[];
  servicingTime?: string;
}

export const TimeSlots = ({ handleChange, timeSlots, servicingTime }: Props): JSX.Element => (
  <div>
    {timeSlots.map(({ slot, available }) => {
      return (
        <button
          type="button"
          className="btn btn-outline-primary rounded-pill"
          key={slot}
          disabled={!available}
          style={
            slot === servicingTime
              ? {
                  border: '4px solid #0071e2',
                  background: '#0071e2',
                  color: '#fff',
                  marginRight: 4,
                  marginBottom: 4
                }
              : { marginRight: 4, marginBottom: 4 }
          }
          name="servicingTime"
          value={slot}
          onClick={handleChange as any}
        >
          {slot}
        </button>
      );
    })}
  </div>
);
