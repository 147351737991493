import React from 'react';
import { Link } from 'react-router-dom';
import { withDashboard } from '../../hoc';
import styles from './Settings.module.scss';

const SettingsPage = (): JSX.Element => (
  <div className="container">
    <div className="head-panel d-flex">
      <div className="head-left">
        <h1 className="page-title">Settings</h1>
      </div>
      <div className="head-right"></div>
    </div>
    <div>
      <div>
        <p>
          <b>Application</b>
        </p>

        <div className={`d-flex ${styles.settingsBox}`}>
          <Link to="/settings/general" className="d-flex">
            <div className={`d-flex ${styles.iconWrapper}`}>
              <i className="fas fa-cog" />
            </div>
            <div>
              <p>General</p>
              <span className="text-muted">General application configurations.</span>
            </div>
          </Link>

          <Link to="/settings/notifications" className="d-flex">
            <div className={`d-flex ${styles.iconWrapper}`}>
              <i className="fas fa-bell" />
            </div>
            <div>
              <p>Notifications</p>
              <span className="text-muted">Send global notifications to Yatri users.</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default withDashboard(SettingsPage);
