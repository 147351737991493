import React, { SyntheticEvent } from 'react';
import { Form, Input } from '../form';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: {
    vinNumber: string;
    chargerNumber: string | undefined;
    controllerNumber: string;
    motorNumber: string;
    simNumber: string | undefined;
    keyNumber: string | undefined;
  };
}

const UpdateBikeForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps } = props;
  const {
    vinNumber,
    chargerNumber,
    controllerNumber,
    motorNumber,
    simNumber,
    keyNumber
  } = formProps;

  return (
    <Form submitting={submitting} onSubmit={onSubmit}>
      <div className="body-card">
        <Input
          onChange={handleChange}
          label="VIN number*"
          type="text"
          name="vinNumber"
          value={vinNumber}
          placeholder="VIN number"
          required
        />
        <Input
          onChange={handleChange}
          label="Charger number*"
          type="text"
          name="chargerNumber"
          value={chargerNumber}
          placeholder="Charger number"
        />
        <Input
          onChange={handleChange}
          label="Controller number*"
          type="text"
          name="controllerNumber"
          value={controllerNumber}
          placeholder="Controller number"
          required
        />
        <Input
          onChange={handleChange}
          label="Motor number*"
          type="text"
          name="motorNumber"
          value={motorNumber}
          placeholder="Motor number"
          required
        />
        <Input
          onChange={handleChange}
          label="SIM number"
          type="text"
          name="simNumber"
          value={simNumber}
          placeholder="SIM number"
        />
        <Input
          onChange={handleChange}
          label="Digital key number"
          type="text"
          name="keyNumber"
          value={keyNumber}
          placeholder="Key number"
        />
      </div>
    </Form>
  );
};

export default UpdateBikeForm;
