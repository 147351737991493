import React, { SyntheticEvent, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { Alert } from '../../components/alerts';
import Loader from '../../components/commons/Loader';
import SettingsForm from '../../components/settings-form';
import { Settingprops } from '../../interfaces';
import { formatAxiosError, scrollToTop } from '../../utils';
import { get, post } from '../../utils/axios';
import { withDashboard } from '../../hoc';

const SettingApplicationPage = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Settingprops>({
    fastChargingCostPerEUnit: 0,
    acChargingCostPerMinute: 0,
    yatriWebsiteAPIAccessToken: '',
    currentPetrolPrice: 180
  });
  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    get(`/configurations`)
      .then((response: AxiosResponse) => {
        setFormProps(response.data.data.configuration);
        setFetching(false);
      })
      .catch((error) => {
        setFetchError(error);
        setFetching(false);
      });
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    post('/configurations', formProps)
      .then(() => {
        setSubmitting(false);
        navigate(`/settings`);
      })
      .catch((error) => {
        setError(formatAxiosError(error));
        setSubmitting(false);
        scrollToTop();
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  if (fetching) return <Loader />;
  if (fetchError) return <Navigate to="/error" />;

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">
            Settings <i className="fas fa-caret-right"></i> General
          </h1>
          <span className="text-muted">General application configurations.</span>
        </div>
        <div className="head-right"></div>
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
      <SettingsForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></SettingsForm>
    </div>
  );
};

export default withDashboard(SettingApplicationPage);
