import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ROLES } from '../../constants';
import AuthorizeView from '../../hoc/authorizeView';
import { ChargeStation } from '../../interfaces';
// import { del } from '../../utils/axios';

interface Props {
  chargeStation: ChargeStation;
}

const ChargingDocks = ({ chargeStation }: Props): JSX.Element => {
  // const { id } = useParams<{ id: string; dockId: string }>();

  // function handleDockDelete(dockId: any) {
  //   del(`/charge-stations/${id}/docks/${dockId}`).then(() => {
  //     location.reload();
  //   });
  // }

  return (
    <Row>
      <Col>
        <div className="table-box">
          <Table responsive hover>
            <thead className="table-light">
              <tr>
                <th>Dock id</th>
                <th>Availability</th>
                <th>Enabled</th>
                <th>Capacity (kWh)</th>
                <th>Type</th>
                <th>Dock status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {chargeStation.docks?.map((dock) => (
                <tr key={dock._id}>
                  <td>
                    <small>{dock._id}</small>
                  </td>
                  <td>
                    {dock.isAvailable ? (
                      <div className="badge rounded-pill bg-success">Available</div>
                    ) : (
                      <div className="badge rounded-pill bg-danger">In use</div>
                    )}
                  </td>
                  <td>
                    {dock.isEnabled ? (
                      <div className="badge rounded-pill bg-success">Enabled</div>
                    ) : (
                      <div className="badge rounded-pill bg-danger">Disabled</div>
                    )}
                  </td>
                  <td>
                    {dock.capacity}
                    {dock.maxCapacity && dock.maxCapacity > dock.capacity
                      ? ` - ${dock.maxCapacity}`
                      : ''}{' '}
                    kWh
                  </td>
                  <td>{dock.type}</td>
                  <td>
                    {dock.isOutOfService ? (
                      <div className="badge rounded-pill bg-warning">Out of service</div>
                    ) : (
                      <div className="badge rounded-pill bg-success">Operational</div>
                    )}
                  </td>
                  <td>
                    <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.manager]}>
                      <Link
                        to={`/charge-stations/${chargeStation._id}/docks/${dock._id}/edit`}
                        className="btn btn-sm"
                      >
                        <i className="fas fa-pencil-alt" />
                      </Link>
                    </AuthorizeView>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <br />
      </Col>
    </Row>
  );
};

export default ChargingDocks;
