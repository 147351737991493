import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Loader from '../../components/commons/Loader';
import { ChargeStation, ChargeStationLocation, EChargeStationStatus } from '../../interfaces';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';
import HiddenKey from '../../components/hidden-key';
import Clipboard from '../../components/clipboard';
import Statsbox from '../../components/stats-box';
import { Alert } from '../../components/alerts';
import AssignChargeStationOwnerModal from './ownership';
import CustomerPeepCard from '../../components/customer-peep-card';
import ChargeStationDetail from './ChargeStationDetail';
import TransferLocationModal from './transfer-location';

function ChargeStationPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const [fetching, response, error] = usePromiseFetch(`/charge-stations/${id}`);

  if (fetching) return <Loader />;
  if (!response || error) return <Navigate to="/error" />;

  const chargeStation = response.station as ChargeStation;

  const {
    _id,
    remarks,
    apiKey,
    uuid,
    docks,
    owner,
    softwareVersion,
    status,
    isEnabled,
    isCellularBalanceLow
  } = chargeStation;
  const chargeStationLocale = chargeStation.chargeStationLocale as ChargeStationLocation;

  return (
    <Container>
      <Row className="mb-3">
        <Col sm={3} className="mb-3">
          <Link to={`/charge-stations/${_id}/stats`}>
            <Statsbox
              bgClass="dark"
              title="Stats"
              faIconName="fas fa-chart-line"
              iconColorClass="text-success"
              caption="Charge station stats"
            />
          </Link>
        </Col>
        <AssignChargeStationOwnerModal
          chargeStationId={id as string}
          existingOwner={owner as string}
        />
        <TransferLocationModal
          chargeStationId={id as string}
          chargeStationLocaleId={(chargeStationLocale?._id as string) || ''}
        />
      </Row>

      {status !== EChargeStationStatus.online && (
        <Alert variant="danger">This charge station seems to be out of service</Alert>
      )}
      {docks?.some((d) => d.isOutOfService) && (
        <Alert variant="warning">One or more chargers are currently under maintenance</Alert>
      )}
      <div className="head-panel d-flex">
        <div>
          <div>
            {isEnabled ? (
              <div className="badge rounded-pill bg-success me-1">Enabled</div>
            ) : (
              <div className="badge rounded-pill bg-danger me-1">Disabled</div>
            )}
          </div>

          <div>
            {status === EChargeStationStatus.online ? (
              <div className="badge rounded-pill bg-success">{status}</div>
            ) : (
              <div className="badge rounded-pill bg-warning">{status}</div>
            )}
          </div>

          <div>
            {isCellularBalanceLow && (
              <div>
                <div className="badge rounded-pill bg-danger">Cellular Balance Low</div>
              </div>
            )}
          </div>

          <div>
            {docks
              ?.map((dock) => dock.type)
              .filter((v, i, a) => a.indexOf(v) === i)
              .map((dockType) => (
                <div key={dockType} className="badge rounded-pill bg-primary me-1">
                  {dockType}
                </div>
              ))}
          </div>
        </div>

        <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.manager]}>
          <div className="head-right">
            <Link to={`${pathname}/edit`} className="btn btn-primary">
              Update Charge Station
            </Link>
          </div>
        </AuthorizeView>
      </div>

      <Row>
        <div className="col-sm-12 col-md-8 order-2 order-md-1">
          {chargeStationLocale ? (
            <Link to={`/charge-station-locations/${chargeStationLocale._id}`}>
              <div className="body-card mb-3">
                <h1 className="page-title">{chargeStationLocale.name}</h1>
                <div>
                  <i className="fas fa-charging-station"></i> &nbsp;
                  {chargeStationLocale.businessType}
                </div>
                <div>
                  <i className="fas fa-map-marker-alt" /> &nbsp;
                  {chargeStationLocale.address}, {chargeStationLocale.city}
                </div>
              </div>
            </Link>
          ) : (
            <Alert variant="warning">Not assigned to any charge station location.</Alert>
          )}

          <div className="body-card mb-3">
            <ul className="neutralize">
              <li>
                <Clipboard inlineTitle="Code:" text={_id as string} />
              </li>
              <li>
                <li>
                  <Clipboard inlineTitle="UUID:" text={uuid as string} />
                </li>
              </li>
            </ul>
          </div>

          <ul className="list-group my-3">
            <li className="list-group-item bg-light">
              <strong>Current Software version: v{softwareVersion?.split('-')[0]}</strong>
              {softwareVersion?.split('-')[softwareVersion.split('-').length - 1] === 'D' && (
                <div>
                  <span className="badge bg-warning rounded-pill">Diagnostic mode</span>
                </div>
              )}
            </li>
            <li className="list-group-item">
              <ul className="neutralize">
                <li>
                  <strong>Boards detail:</strong>
                  <ul>
                    {softwareVersion
                      ?.split('-')[1]
                      .split(',')
                      .map((board) => (
                        <li key={board}>{`${board.split('=')[0]} • ${board.split('=')[1]}`}</li>
                      ))}
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="col-sm-12 col-md-4 order-1 order-md-2 mb-3">
          <CustomerPeepCard customerId={owner} heading="Owner" />
        </div>
      </Row>

      <ChargeStationDetail chargeStation={chargeStation} />

      <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1]}>
        <Card className="my-3">
          <Card.Body>
            <HiddenKey title="API key" hiddenText={apiKey as string} />
          </Card.Body>
        </Card>
      </AuthorizeView>

      <div className="body-card mb-3">
        <Row>
          <Col>
            <strong>Remarks:</strong>
            <p style={{ whiteSpace: 'pre-line' }}>{remarks ? remarks : 'NA'}</p>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(ChargeStationPage);
