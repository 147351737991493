import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Row, Col, Table } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { usePromiseFetch } from '../../hooks';
import Paginate from '../../components/paginate';
import { BikeCrashLog, Mappable } from '../../interfaces';
import LocationModal from './LocationModal';

function BikeCrashLogs({ hideControls }: { hideControls?: boolean }): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const [coords, setCoords] = useState<Mappable | null>(null);

  const [fetching, response] = usePromiseFetch(`/bike-crashes?sort=-createdAt`, searchParams);

  if (fetching) return <TableLoader />;

  const { logs, size, total }: { logs: BikeCrashLog[]; size: number; total: number } = response
    ? response
    : {
        logs: [],
        size: 40,
        total: 0
      };

  const totalPage = Math.ceil(total / size);

  return (
    <>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Bike crash logs</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover variant="dark">
              <thead className="table-dark">
                <tr>
                  <th>Code</th>
                  <th>X, Y, Z</th>
                  <th>Timestamp</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {logs.map(({ _id, bike, x, y, z, createdAt, location }) => (
                  <tr key={_id}>
                    <td>
                      <small>{_id}</small>
                    </td>
                    <td>{`${x}, ${y}, ${z}`}</td>
                    <td>{new Date(createdAt).toLocaleString()}</td>
                    <td>
                      <Link to={`/bikes/${bike}`} className="btn btn-sm btn-light">
                        <i className="fas fa-info-circle"></i>
                      </Link>
                      &nbsp;
                      <button
                        className="btn btn-sm btn-light"
                        onClick={() => setCoords({ location })}
                      >
                        <i className="fas fa-map-marker-alt"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        {!hideControls && (
          <Row>
            <Col>
              <Paginate
                totalPage={totalPage}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />
            </Col>
          </Row>
        )}
        <LocationModal coords={coords} setCoords={setCoords} />
      </div>
    </>
  );
}

export default BikeCrashLogs;
