import { Action, ActionTypes } from '../actions';

export default (state = 0, action: Action): number => {
  switch (action.type) {
    case ActionTypes.FETCH_UNREAD_SERVICING_COUNT:
      return action.payload;
    default:
      return state;
  }
};
