import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { ServiceCenter } from '../../interfaces';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';

function ServiceCenterPage(): JSX.Element {
  const [fetching, response, error] = usePromiseFetch('/service-centers');

  if (fetching) return <TableLoader />;
  if (!response || error) return <Navigate to="/error" />;

  const {
    stations,
    size,
    total
  }: { stations: ServiceCenter[]; size: number; total: number } = response
    ? response
    : { stations: [], size: 40, total: 0 };

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Service centers</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.manager]}>
              <div className="head-right">
                <Link to="/service-centers/add" className="btn btn-primary">
                  Add service center
                </Link>
              </div>
            </AuthorizeView>
          </div>
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover>
              <thead className="table-light">
                <tr>
                  <th>Code</th>
                  <th>Address</th>
                  <th>Phone number</th>
                  <th>Opening hour</th>
                  <th>Closing hour</th>
                  <th>Enabled</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {stations.map(
                  ({ _id, address, phoneNumber, openingHour, closingHour, isEnabled }) => (
                    <tr key={_id}>
                      <td>
                        <small>{_id}</small>
                      </td>
                      <td>{address}</td>
                      <td>{phoneNumber}</td>
                      <td>{openingHour}</td>
                      <td>{closingHour}</td>
                      <td>
                        {isEnabled ? (
                          <div className="badge rounded-pill bg-success">Enabled</div>
                        ) : (
                          <div className="badge rounded-pill bg-danger">Disabled</div>
                        )}
                      </td>
                      <td>
                        <Link className="btn btn-sm" to={`/service-centers/${_id}`}>
                          <i className="fas fa-eye"></i>
                        </Link>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(ServiceCenterPage);
