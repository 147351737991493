import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withDashboard } from '../../hoc';
import { Col, Row } from 'react-bootstrap';
import { EServicesOutlays } from '../../interfaces';
import PaymentForm from '../../components/payment-form';
import { post } from '../../utils/axios';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';

type IPayment = {
  customerId: string;
  amount: number;
  service: EServicesOutlays | '';
  remarks: '';
};

const AddPaymentPage = (): JSX.Element => {
  const [formProps, setFormProps] = useState<IPayment>({
    customerId: '',
    amount: 0,
    service: '',
    remarks: ''
  });
  const [submitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();

    post(`/wallets/transactions/create-payment`, formProps)
      .then(({ data: { data } }) => {
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(`/payments/${data.transaction.id}`);
      })
      .catch((error) => {
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Create payment</h1>
              <p className="text-muted">
                Create a wallet payment, for top-ups go to customer page.
              </p>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <PaymentForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></PaymentForm>
    </div>
  );
};

export default withDashboard(AddPaymentPage);
