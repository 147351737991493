import React, { ChangeEvent } from 'react';

interface Props {
  label?: string;
  name: string;
  value: any;
  noFloat?: boolean;
  onChange: (e: ChangeEvent<any>) => any;
  options: { value: string | number; label: string | number }[];
  [key: string]: any;
}
export const SelectInput = ({
  noFloat,
  name,
  value,
  onChange,
  label,
  options,
  ...otherProps
}: Props): JSX.Element => (
  <div className={`${!noFloat && 'form-floating'} form-group`}>
    {label && noFloat && <label htmlFor={name}>{label}</label>}
    <select
      className="form-select"
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      {...otherProps}
    >
      {options.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {label && !noFloat && <label htmlFor={name}>{label}</label>}
  </div>
);
