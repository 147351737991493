import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SettingsNotificationForm from '../../components/settings-notification-form';
import { formatAxiosError } from '../../utils';
import { post } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import { ENotificationTypes, Notification } from '../../interfaces';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import { Alert } from '../../components/alerts';

const SettingNotificationPage = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Partial<Notification>>({
    title: '',
    body: '',
    type: ENotificationTypes.hubInfo
  });
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (!confirm('Are you sure to send out a global notification?')) return;

    setSubmitting(true);
    post('/notifications', formProps)
      .then(() => {
        setSubmitting(false);
        dispatch(
          pushToast({
            bodyText: 'Notifications saved successfully',
            headText: 'Success',
            variant: 'success'
          })
        );
        navigate(`/settings`);
      })
      .catch((error) => {
        dispatch(
          pushToast({ bodyText: formatAxiosError(error), headText: 'Error', variant: 'error' })
        );
        setSubmitting(false);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">
            Settings <i className="fas fa-caret-right"></i> Notification
          </h1>
          <p className="text-muted" style={{ marginBottom: '1rem' }}>
            Send global notifications to Yatri users.
          </p>
          <Alert variant="info">
            This will not send actual push notification to Yatri users mobile app. Visit{' '}
            <a
              href="https://console.firebase.google.com/u/0/project/yatrihub-chat/messaging"
              className="link"
            >
              firebase console
            </a>{' '}
            to send global push notification to mobile app.
          </Alert>
        </div>
        <div className="head-right"></div>
      </div>
      <SettingsNotificationForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></SettingsNotificationForm>
    </div>
  );
};

export default withDashboard(SettingNotificationPage);
