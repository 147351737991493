import React, { SyntheticEvent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { EChargingTypes } from '../../interfaces';
import { Alert } from '../alerts';
import { Checkbox, Form, SelectInput } from '../form';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  isUpdate?: boolean;
  formProps: {
    isAvailable: boolean;
    isEnabled: boolean;
    type: EChargingTypes;
    capacity: number;
    maxCapacity: number;
    isOutOfService: boolean;
  };
}

const ChargeStationDockForm = (props: Props): JSX.Element => {
  const { isUpdate, onSubmit, handleChange, submitting, formProps } = props;
  const { isAvailable, isEnabled, type, capacity, maxCapacity, isOutOfService } = formProps;
  return (
    <Container>
      <Row>
        <Col>
          <Form submitting={submitting} onSubmit={onSubmit}>
            <div className="body-card">
              <Checkbox
                switchMode
                id="isEnabled"
                onChange={handleChange}
                label="Enabled"
                name="isEnabled"
                type="checkbox"
                checked={isEnabled}
              ></Checkbox>
              <Checkbox
                switchMode
                id="isAvailable"
                onChange={handleChange}
                label="Availability"
                name="isAvailable"
                type="checkbox"
                checked={isAvailable}
              ></Checkbox>
              <Checkbox
                switchMode
                id="isOutOfService"
                onChange={handleChange}
                label="Out of service"
                name="isOutOfService"
                type="checkbox"
                checked={isOutOfService}
              ></Checkbox>
              <SelectInput
                onChange={handleChange}
                label="Charging Type*"
                type="text"
                name="type"
                options={Object.values(EChargingTypes)
                  .filter((v) => v !== 'Discharge')
                  .map((v) => ({ value: v, label: v }))}
                value={type}
                disabled={isUpdate}
                required
              />
              <SelectInput
                onChange={handleChange}
                label="Charger capacity*"
                type="text"
                name="capacity"
                options={[3.5, 7, 30, 60].map((v) => ({ value: v, label: `${v} kWh` }))}
                value={capacity}
                disabled={isUpdate}
                required
              />
              <Alert variant="warning" small>
                If the charger has a constant capacity then set the same value to both capacity and
                max capacity.
              </Alert>
              <SelectInput
                onChange={handleChange}
                label="Charger max capacity*"
                type="text"
                name="maxCapacity"
                options={[3.5, 7, 30, 60].map((v) => ({ value: v, label: `${v} kWh` }))}
                value={maxCapacity}
                disabled={isUpdate}
                required
              />
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ChargeStationDockForm;
