import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { get, patch } from '../../utils/axios';
import Loader from '../../components/commons/Loader';
import { Servicing } from '../../interfaces';
import { withDashboard } from '../../hoc';
import CustomerDetailCard from '../../components/customer-detail-card';
import BikeDetailCard from '../../components/bike-detail-card';
import AppointmentDeleteButton from './AppointmentCancelButton';
import AppointmentCompleteButton from './AppointmentCompleteButton';
import { updateServicingCount } from '../../actions';
import { selectUnreadServicingCount } from '../../selectors';
import MarkAppointmentAsUnRead from './MarkAppointmentAsUnRead';
import { toLocalDateAndFormatWithTime } from '../../utils/date';

function servicingStatusBadge(servicingStatus: string): JSX.Element {
  let badgeClassName = 'bg-success';
  if (servicingStatus === 'In progress') badgeClassName = 'bg-warning';
  if (servicingStatus === 'Cancelled') badgeClassName = 'bg-danger';

  return <div className={`badge rounded-pill ${badgeClassName}`}>{servicingStatus}</div>;
}

function ServiceCenterAppointmentPage(): JSX.Element {
  const [fetching, setFetching] = useState(true);
  const [servicing, setServicing] = useState<Servicing | null>(null);
  const dispatch = useDispatch();
  const unreadServicingCount = useSelector(selectUnreadServicingCount);

  const { appointmentId } = useParams<{ appointmentId: string }>();

  useEffect(() => {
    get(`/servicings/${appointmentId}`)
      .then((response: AxiosResponse) => {
        setServicing(response.data.data.appointment);
        setFetching(false);
      })
      .catch(() => setFetching(false));
  }, []);

  useEffect(() => {
    if (servicing?.isRead !== undefined && servicing.isRead === false) {
      setServicing({ ...servicing, isRead: true });
      patch(`/servicings/${appointmentId}/mark-as-read`, {});
      dispatch(updateServicingCount(unreadServicingCount, 'decrement'));
    }
  }, [servicing?.isRead]);

  if (fetching) return <Loader />;
  if (!servicing) return <Navigate to="/error" />;

  const {
    _id,
    staffRemarks,
    servicingStatus,
    servicingDate,
    serviceCenter,
    servicingType,
    remarks,
    services,
    discount,
    customer,
    bike,
    currentOdo
  } = servicing as Servicing;

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Appointment detail</h1>
              <div>
                <small className="text-muted">Code: {_id}</small>
              </div>
              {servicingStatusBadge(servicingStatus as string)}
            </div>
            <div className="head-right">
              <MarkAppointmentAsUnRead
                servicingId={_id as string}
                unreadServicingCount={unreadServicingCount}
              />
              &nbsp;&nbsp;
              {!(servicingStatus === 'Cancelled' || servicingStatus === 'Completed') && (
                <Link
                  style={{ marginRight: 8 }}
                  to={`/service-centers/${serviceCenter}/appointments/${appointmentId}/edit`}
                  className="btn btn-primary"
                >
                  Update appointment
                </Link>
              )}
              <AppointmentCompleteButton
                servicingId={_id as string}
                serviceCenterId={serviceCenter as string}
                status={servicingStatus as string}
              />
            </div>
          </div>

          <div className="body-card">
            <ul className="neutralize">
              <li>Total cost: Rs. {services.reduce((a, b) => a + b.cost, 0) - discount}</li>
              <li>Total discount: Rs. {discount}</li>
              <li>Servicing date: {toLocalDateAndFormatWithTime(servicingDate)} </li>
              <li>Servicing type: {servicingType}</li>
              <li>Current odometer: {currentOdo ?? 'NA'}</li>
              <li>Remarks: {remarks ?? 'NA'}</li>
            </ul>
          </div>
          <ul className="list-group my-3">
            <li className="list-group-item bg-light">
              <h5>Services</h5>
            </li>
            {services.map((service, i) => (
              <li key={i} className="list-group-item">
                <div>Service: {service.serviceName}</div>
                <div>Cost: {service.cost}</div>
              </li>
            ))}
          </ul>
          <div className="body-card mb-3">
            Staff Remarks:
            <p style={{ whiteSpace: 'pre-line' }}>{staffRemarks ?? 'NA'}</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={5}>
          <CustomerDetailCard id={customer as string} />
        </Col>
        <Col>
          <Link to={`/bikes/${bike}`}>
            <BikeDetailCard id={bike as string} fullWidth />
          </Link>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12}>
          <AppointmentDeleteButton
            servicingId={_id as string}
            serviceCenterId={serviceCenter as string}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default withDashboard(ServiceCenterAppointmentPage);
