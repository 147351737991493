import React, { ReactNode, SyntheticEvent } from 'react';
import { Form as BootstrapForm } from 'react-bootstrap';

export * from './Input';
export * from './TextArea';
export * from './FileInput';
export * from './Checkbox';
export * from './SelectInput';

export const Form = ({
  onSubmit,
  submitting,
  children,
  btnStyle = {},
  btnText
}: {
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  children: ReactNode;
  btnStyle?: any;
  btnText?: string;
}): JSX.Element => (
  <BootstrapForm onSubmit={onSubmit}>
    {children}
    <button
      disabled={submitting}
      type="submit"
      className="btn btn-primary mt-3"
      style={{ position: 'relative', padding: '.5rem 2.5rem', ...btnStyle }}
    >
      {submitting && (
        <div
          className="spi"
          style={{
            position: 'absolute',
            height: 15,
            width: 15,
            top: 9,
            left: 9
          }}
        />
      )}
      {btnText ?? 'Save'}
    </button>
  </BootstrapForm>
);
