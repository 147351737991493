import React from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { TableLoader } from '../../components/commons/Loader';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import Paginate from '../../components/paginate';
import { BatterySwapHistory } from '../../interfaces';

function CustomerBikeBatterySwapsHistory(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const { bikeId } = useParams<{ bikeId: string }>();
  const [fetching, response] = usePromiseFetch(`/battery-swaps/bikes/${bikeId}`, searchParams);

  if (fetching) return <TableLoader />;

  const {
    logs,
    size,
    total
  }: { logs: BatterySwapHistory[]; size: number; total: number } = response
    ? response
    : {
        logs: [],
        size: 40,
        total: 0
      };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Battery Swap logs</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover>
              <thead className="table-light">
                <tr>
                  <th>Code</th>
                  <th>Bike id</th>
                  <th>Current battery id</th>
                  <th>Previous battery id</th>
                  <th>Timestamp</th>
                  <th>Logs</th>
                </tr>
              </thead>
              <tbody>
                {logs.map(({ _id, bike, currentBattery, previousBattery, createdAt }) => (
                  <tr key={_id}>
                    <td>
                      <small>{_id}</small>
                    </td>
                    <td>
                      <Link className="link" to={`/bikes/${bike}`}>
                        {bike}
                      </Link>
                    </td>
                    <td>
                      <Link className="link" to={`/batteries?_id=${currentBattery}`}>
                        {currentBattery}
                      </Link>
                    </td>
                    <td>
                      {previousBattery && (
                        <Link className="link" to={`/batteries?_id=${previousBattery}`}>
                          {previousBattery}
                        </Link>
                      )}
                    </td>
                    <td>{new Date(createdAt).toLocaleString()}</td>
                    <td>
                      <Link className="btn btn-sm" to={`${_id}/logs`}>
                        <i className=" fas fa-digital-tachograph"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(CustomerBikeBatterySwapsHistory);
