export enum ENotificationTypes {
  yatriHub = 'yatri-hub',
  wallet = 'yatri-wallet',
  yatriEnergy = 'yatri-energy',
  global = 'global',
  test = 'test'
}

export interface Notification {
  customer: string;
  title: string;
  description: string;
  createdAt: string;
  body?: string;
  type: ENotificationTypes;
}
