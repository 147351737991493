type ConfigurationPropsSchema = {
  name: string;
  options: string[];
};

type P1Schema = {
  paints: ConfigurationPropsSchema;
  seatTrims: ConfigurationPropsSchema;
  suspensionPaints: ConfigurationPropsSchema;
  powerPacks: ConfigurationPropsSchema;
  performanceBrakings: ConfigurationPropsSchema;
  startupPacks: ConfigurationPropsSchema;
};

/**
 * Variable name is bike model id
 */
const P1: P1Schema = {
  paints: {
    name: 'paint',
    options: [
      'Sun glow',
      'Glacier',
      'Cherry wood',
      'Clound burst',
      'Aqua forest',
      'Shakespeare',
      'Lavender meadow',
      'Mulberry',
      'Gorkhali green',
      'Vulcan',
      'Yatri turquoise'
    ]
  },
  seatTrims: {
    name: 'seatTrim',
    options: ['Charcoal', 'Copperfield']
  },
  suspensionPaints: {
    name: 'suspensionPaint',
    options: ['Woodsmoke', 'Luxor gold', 'Almond frost']
  },
  powerPacks: {
    name: 'powerPack',
    options: ['72T', '80T']
  },
  performanceBrakings: {
    name: 'performanceBraking',
    options: ['240mm', '320mm']
  },
  startupPacks: {
    name: 'startupPack',
    options: ['Mechanical', 'Digital']
  }
};

const configurations: { [key: string]: any } = { P1 };
export default configurations;
