import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../utils/yatriSiteAxios';
import { withDashboard } from '../../hoc';
import { TestRide } from '../../interfaces';
import TestRideForm from '../../components/test-ride-form';

const today = new Date();

const AddTestRide = (): JSX.Element => {
  const [formProps, setFormProps] = useState<TestRide>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    hasAttended: false,
    bike: '-',
    licenseNumber: '',
    bookingDate: `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(
      today.getDate()
    ).padStart(2, '0')}T10:00`,
    remarks: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    formProps.bookingDate = new Date(formProps.bookingDate);
    post('/test-rides', formProps)
      .then(() => {
        setSubmitting(false);
        navigate(`/test-rides`);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Add Test Ride</h1>
        </div>
        <div className="head-right"></div>
      </div>
      <TestRideForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      />
    </div>
  );
};

export default withDashboard(AddTestRide);
