import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { patch } from '../../utils/axios';
import { updateServicingCount } from '../../actions';

interface Props {
  servicingId: string;
  unreadServicingCount: number;
}
const MarkAppointmentAsUnRead = ({ servicingId, unreadServicingCount }: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleUnreadAppointment() {
    patch(`/servicings/${servicingId}/mark-as-unread`, {}).then(() => {
      dispatch(updateServicingCount(unreadServicingCount, 'increment'));
      navigate(-1);
    });
  }

  return (
    <button
      onClick={handleUnreadAppointment}
      type="button"
      className="btn"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title="Mark as unread"
    >
      <i className="fas fa-envelope-square"></i>
    </button>
  );
};

export default MarkAppointmentAsUnRead;
