import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const FileInput = ({ handleChange, label, className, ...otherProps }: any): JSX.Element => {
  return (
    <div className="form-group">
      <input
        className={`form-control ${className}`}
        type="file"
        label={label}
        onChange={handleChange}
        {...otherProps}
      ></input>
    </div>
  );
};
