import React, { FormEvent } from 'react';

interface Props {
  switchMode?: boolean;
  label?: string;
  inline?: boolean;
  name: string;
  type: any;
  checked?: boolean;
  id: string;
  onChange: (e: FormEvent<HTMLInputElement>) => any;
  [key: string]: any;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Checkbox = ({
  label,
  switchMode,
  inline,
  name,
  id,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <div className={inline ? '' : 'form-group'}>
      <div className={`form-check ${switchMode ? 'form-switch' : ''}`}>
        <input className="form-check-input" id={id} name={name} {...otherProps} />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  );
};
