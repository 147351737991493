import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { get, put } from '../../utils/yatriSiteAxios';
import { withDashboard } from '../../hoc';
import { TestRide } from '../../interfaces';
import TestRideForm from '../../components/test-ride-form';
import { AxiosResponse } from 'axios';
import Loader from '../../components/commons/Loader';
import { Alert } from '../../components/alerts';

const today = new Date();

const UpdateTestRide = (): JSX.Element => {
  const [formProps, setFormProps] = useState<TestRide>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    hasAttended: false,
    bike: '-',
    licenseNumber: '',
    bookingDate: `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
      2,
      '0'
    )}-${today.getDate()}T10:00`,
    remarks: ''
  });

  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    get(`/test-rides/${id}`)
      .then((response: AxiosResponse) => {
        const date = new Date(response.data.data.testRide.bookingDate);
        const formatDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          '0'
        )}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(
          2,
          '0'
        )}:${String(date.getMinutes()).padStart(2, '0')}`;

        response.data.data.testRide.bookingDate = formatDate;
        setFormProps(response.data.data.testRide);
        setFetching(false);
      })
      .catch((error) => {
        setFetchError(error);
        setFetching(false);
      });
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    setError('');

    formProps.bookingDate = new Date(formProps.bookingDate);
    put(`/test-rides/${id}`, formProps)
      .then(() => {
        setSubmitting(false);
        navigate(`/test-rides`);
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.response.data.message);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  if (fetching) return <Loader />;
  if (fetchError) return <Navigate to="/error" />;

  return (
    <div className="container">
      <div className="head-panel d-flex">
        <div className="head-left">
          <h1 className="page-title">Update Test Ride</h1>
        </div>
        <div className="head-right"></div>
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
      <TestRideForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      />
    </div>
  );
};

export default withDashboard(UpdateTestRide);
