import React from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { usePromiseFetch } from '../../hooks';
import { TableLoader } from '../../components/commons/Loader';
import { ChargeStation, ChargeStationLocation } from '../../interfaces';
import { withDashboard } from '../../hoc';
import { SearchFields } from '../../components/search-box';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';
import Paginate from '../../components/paginate';
import { cities } from '../../utils';

function ChargeStationsPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fetching, response, error] = usePromiseFetch('/charge-station-locales', searchParams);

  if (fetching) return <TableLoader />;
  if (!response || error) return <Navigate to="/error" />;

  const {
    stationLocales,
    size,
    total
  }: { stationLocales: ChargeStationLocation[]; size: number; total: number } = response
    ? response
    : {
        stationLocales: [],
        size: 40,
        total: 0
      };

  const totalPage = Math.ceil(total / size);

  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Charge station locations</h1>
              <span className="text-muted">{total} entries found</span>
            </div>
            <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.manager]}>
              <div className="head-right">
                <Link to="/charge-station-locations/add" className="btn btn-primary">
                  Add charge station location
                </Link>
              </div>
            </AuthorizeView>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchFields
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            fields={[
              { label: 'id', name: '_id' },
              { label: 'Owner id', name: 'owner' },
              {
                label: 'Enabled',
                name: 'isEnabled',
                type: 'dropDown',
                options: [
                  {
                    label: 'All',
                    value: ''
                  },
                  {
                    label: 'Active',
                    value: 'true'
                  },
                  {
                    label: 'Disabled',
                    value: 'false'
                  }
                ]
              },
              {
                label: 'City',
                name: 'city',
                type: 'dropDown',
                options: [
                  {
                    label: '-',
                    value: ''
                  },
                  ...cities.map((c) => ({ label: c, value: c }))
                ]
              }
            ]}
          />
        </Col>
      </Row>
      <div className="table-box">
        <Row>
          <Col>
            <Table responsive hover>
              <thead className="table-light">
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                  <th>City</th>
                  <th>Address</th>
                  <th>Docks</th>
                  <th>Enabled</th>
                  <th>Owner</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {stationLocales.map(
                  ({ _id, name, city, address, chargeStations, isEnabled, owner }) => (
                    <tr key={_id}>
                      <td>
                        <small>{_id}</small>
                      </td>
                      <td style={{ maxWidth: 200 }}>
                        <div>{name}</div>
                      </td>
                      <td>{city}</td>
                      <td>{address}</td>
                      <td>
                        {(chargeStations as ChargeStation[])
                          .map((station) => station.docks)
                          .flat()
                          ?.map((dock) => dock.type)
                          .filter((v, i, a) => a.indexOf(v) === i)
                          .map((dockType) => (
                            <div key={dockType} className="badge rounded-pill bg-primary mx-1">
                              {dockType}
                            </div>
                          ))}
                      </td>
                      <td>
                        {isEnabled ? (
                          <div className="badge rounded-pill bg-success">Enabled</div>
                        ) : (
                          <div className="badge rounded-pill bg-danger">Disabled</div>
                        )}
                      </td>
                      <td>
                        <small>
                          <Link className="link" to={`/customers/${owner}`}>
                            {owner}
                          </Link>
                        </small>
                      </td>
                      <td>
                        <Link className="btn btn-sm" to={`/charge-station-locations/${_id}`}>
                          <i className="fas fa-eye"></i>
                        </Link>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paginate
              totalPage={totalPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(ChargeStationsPage);
