import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchServicingCount } from '../../actions';
import { selectUnreadServicingCount } from '../../selectors/servicing-count-selector';

const ServicingAppointmentLink = (): JSX.Element => {
  const dispatch = useDispatch();
  const unreadServicingCount = useSelector(selectUnreadServicingCount);

  useEffect(() => {
    dispatch(fetchServicingCount());
  }, []);

  return (
    <NavLink to="/servicing-appointments">
      <div className="s-icon">
        <i className="fas fa-calendar-check"></i>
      </div>{' '}
      Servicing appointments &nbsp;
      {unreadServicingCount > 0 ? (
        <span className="badge bg-danger rounded-pill">{unreadServicingCount}</span>
      ) : (
        <span />
      )}
    </NavLink>
  );
};

export default ServicingAppointmentLink;
