import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import { useSelector } from 'react-redux';
import axios from '../utils/axios';
import { selectUnreadServicingCount } from '../selectors';

export interface FetchUnreadServicingCount {
  type: ActionTypes.FETCH_UNREAD_SERVICING_COUNT;
  payload: number;
}

export const fetchServicingCount = () => async (dispatch: Dispatch): Promise<void> => {
  const response = await axios.get('/servicings/all/count');
  const data = response.data.data;

  dispatch<FetchUnreadServicingCount>({
    type: ActionTypes.FETCH_UNREAD_SERVICING_COUNT,
    payload: data.count
  });
};

export const updateServicingCount = (count: number, action: 'increment' | 'decrement') => async (
  dispatch: Dispatch
): Promise<void> => {
  switch (action) {
    case 'increment':
      count++;
      break;
    case 'decrement':
      count--;
      break;
    default:
      count;
      break;
  }

  dispatch<FetchUnreadServicingCount>({
    type: ActionTypes.FETCH_UNREAD_SERVICING_COUNT,
    payload: count
  });
};
