import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { get, patch } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import Loader from '../../components/commons/Loader';
import { Col, Row } from 'react-bootstrap';
import FaqForm from '../../components/faq-form';
import { Faq } from '../../interfaces';
import { Alert } from '../../components/alerts';

const UpdateFaq = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Faq>({
    question: '',
    answer: '',
    tags: []
  });
  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    get(`/faqs/${id}`)
      .then((response: AxiosResponse) => {
        setFormProps(response.data.data.faq);
        setFetching(false);
      })
      .catch((error) => {
        setFetchError(error);
        setFetching(false);
      });
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    setError('');
    patch(`/faqs/${id}`, formProps)
      .then(() => {
        setSubmitting(false);
        navigate(`/faqs`);
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.response.data.message);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value, checked } = event.currentTarget;

    if (name === 'tags') {
      let tags = [];
      if (checked) tags = [...formProps.tags, value];
      else tags = formProps.tags?.filter((t) => t !== value) as string[];

      return setFormProps({
        ...formProps,
        tags
      });
    }

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  if (fetching) return <Loader />;
  if (fetchError) return <Navigate to="/error" />;

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Update FAQ</h1>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      {error && <Alert variant="danger">{error}</Alert>}
      <FaqForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      ></FaqForm>
    </div>
  );
};

export default withDashboard(UpdateFaq);
