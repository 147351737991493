import React, { SyntheticEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { post } from '../../../utils/axios';
import { withDashboard } from '../../../hoc';
import ChargingStationDockForm from '../../../components/charging-station-dock-form';
import { Dock, EChargingTypes } from '../../../interfaces';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../../actions/toast';
import { formatAxiosError } from '../../../utils';

const AddDock = (): JSX.Element => {
  const [formProps, setFormProps] = useState<Dock>({
    isAvailable: true,
    isEnabled: true,
    capacity: 3.5,
    maxCapacity: 3.5,
    type: EChargingTypes.yatriFastCharge,
    isOutOfService: false
  });
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string; dockId: string }>();

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    post(`/charge-stations/${id}/docks`, formProps)
      .then(() => {
        setSubmitting(false);
        dispatch(
          pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
        );
        navigate(-1);
      })
      .catch((error) => {
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        setSubmitting(false);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Add Charging Dock</h1>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <Row>
        <ChargingStationDockForm
          submitting={submitting}
          onSubmit={onSubmit}
          handleChange={handleChange}
          formProps={formProps}
        ></ChargingStationDockForm>
      </Row>
    </div>
  );
};

export default withDashboard(AddDock);
