import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { usePromiseFetchSite } from '.';
import { AddChart } from '../../components/charts';
import Loader from '../../components/commons/Loader';
import { ErrorMessage } from '../../components/commons/NotFound';

const BookingsCountChart = (): JSX.Element => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  function increment() {
    setCurrentYear(currentYear - 1);
  }

  function decrement() {
    setCurrentYear(currentYear + 1);
  }

  const [fetching, response, error] = usePromiseFetchSite(`/bookings/total/year/${currentYear}`);

  if (fetching)
    return (
      <div className="body-card">
        <Loader />
      </div>
    );
  if (!response || error) return <ErrorMessage />;

  const { total } = response;

  return (
    <div>
      <div className="body-card">
        <h4 className="section-title">
          <button className="btn btn-light" onClick={increment}>
            <i className="fas fa-chevron-left"></i>
          </button>
          &nbsp;
          {currentYear}
          &nbsp;
          <button className="btn btn-light" onClick={decrement}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </h4>
        <Row>
          <Col>
            <div style={{ backgroundColor: '#fff', padding: '16px' }}>
              <AddChart
                label={`${currentYear} total bookings`}
                xLabel="month"
                yLabel="total"
                data={total}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BookingsCountChart;
