import React, { SyntheticEvent } from 'react';
import { ChargeStationLocation } from '../../interfaces';
import { TextArea, Input, Checkbox, SelectInput, Form } from '../form';

const businessTypes = [
  'Cafe / Restaurant',
  'Automotive',
  'Hotel / Resort',
  'Hospital / Clinic',
  'Mall / Commercial Center'
];
const cities = [
  'Achham',
  'Arghakhanchi',
  'Baglung',
  'Baitadi',
  'Bajhang',
  'Bajura',
  'Banke',
  'Bara',
  'Bardiya',
  'Bhaktapur',
  'Bhojpur',
  'Chitwan',
  'Dadeldhura',
  'Dailekh',
  'Dang Deokhuri',
  'Darchula',
  'Dhading',
  'Dhankuta',
  'Dhanusa',
  'Dolakha',
  'Dolpa',
  'Doti',
  'Gorkha',
  'Gulmi',
  'Humla',
  'Ilam',
  'Jajarkot',
  'Jhapa',
  'Jumla',
  'Kailali',
  'Kalikot',
  'Kanchanpur',
  'Kapilvastu',
  'Kaski',
  'Kathmandu',
  'Kavrepalanchok',
  'Khotang',
  'Lalitpur',
  'Lamjung',
  'Mahottari',
  'Makwanpur',
  'Manang',
  'Morang',
  'Mugu',
  'Mustang',
  'Myagdi',
  'Nawalparasi',
  'Nuwakot',
  'Okhaldhunga',
  'Palpa',
  'Panchthar',
  'Parbat',
  'Parsa',
  'Pyuthan',
  'Ramechhap',
  'Rasuwa',
  'Rautahat',
  'Rolpa',
  'Rukum',
  'Rupandehi',
  'Salyan',
  'Sankhuwasabha',
  'Saptari',
  'Sarlahi',
  'Sindhuli',
  'Sindhupalchok',
  'Siraha',
  'Solukhumbu',
  'Sunsari',
  'Surkhet',
  'Syangja',
  'Tanahu',
  'Taplejung',
  'Terhathum',
  'Udayapur'
];
interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: ChargeStationLocation;
}

const ChargeStationLocationForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps } = props;
  const {
    name,
    location: { coordinates },
    isEnabled,
    city,
    address,
    phoneNumber,
    remarks,
    description,
    businessType,
    website,
    instagram,
    facebook,
    businessHours,
    facilities
  } = formProps;

  return (
    <div className="row">
      <div className="col-sm-12">
        <Form submitting={submitting} onSubmit={onSubmit}>
          <div className="body-card mb-3">
            <Checkbox
              switchMode
              id="isEnabled"
              onChange={handleChange}
              label="Enabled"
              name="isEnabled"
              type="checkbox"
              checked={isEnabled}
            />
          </div>
          <div className="body-card mb-3">
            <Input
              onChange={handleChange}
              label="Name*"
              type="text"
              name="name"
              value={name}
              placeholder="Name"
              required
            />
            <SelectInput
              onChange={handleChange}
              label="City*"
              type="text"
              name="city"
              options={[
                { label: 'Select a city', value: '' },
                ...cities.map((c) => ({ label: c, value: c }))
              ]}
              value={city}
              required
            />
            <Input
              onChange={handleChange}
              label="Address*"
              type="text"
              name="address"
              value={address}
              placeholder="Address"
              required
            />
            <Input
              onChange={handleChange}
              label="Phone number*"
              type="text"
              name="phoneNumber"
              value={phoneNumber}
              placeholder="Phone number"
              required
            />

            <SelectInput
              onChange={handleChange}
              label="Business Type*"
              type="text"
              name="businessType"
              options={[
                { label: 'Select a host business type', value: '' },
                ...businessTypes.map((bType) => ({ label: bType, value: bType }))
              ]}
              value={businessType}
              required
            />
          </div>
          <div className="body-card mb-3">
            <label>Facilities</label>
            <div className="row row-cols-lg-auto g-4 align-items-center">
              {[
                'Food & drinks',
                'Parking',
                'Accommodation',
                'Swimming pool',
                'Wi-Fi',
                'Washroom',
                'Bar',
                'Chill space'
              ].map((t) => (
                <Checkbox
                  key={t}
                  inline
                  id={t}
                  name="facilities"
                  type="checkbox"
                  label={t}
                  value={t}
                  checked={facilities?.includes(t)}
                  onChange={handleChange}
                />
              ))}
            </div>
          </div>
          <div className="body-card mb-3">
            <Input
              onChange={handleChange}
              label="Latitude*"
              type="number"
              name="lat"
              value={coordinates[1]}
              placeholder="Latitude"
              required
            />
            <Input
              onChange={handleChange}
              label="Longitude*"
              type="number"
              name="lng"
              value={coordinates[0]}
              placeholder="Longitude"
              required
            />
          </div>

          <div className="body-card mb-3">
            <TextArea
              onChange={handleChange}
              label="Description"
              type="text"
              name="description"
              value={description}
              placeholder="Description"
            />
            <TextArea
              onChange={handleChange}
              label="Personal remarks"
              type="text"
              name="remarks"
              value={remarks}
              placeholder="Remarks"
            />
          </div>

          <div className="body-card mb-3">
            <Input
              onChange={handleChange}
              label="Website"
              name="website"
              value={website}
              placeholder="i.e. https://www.yatrimotorcycles.com"
            />
            <Input
              inputGroupText="@"
              onChange={handleChange}
              label="Instagram"
              name="instagram"
              value={instagram}
              placeholder="i.e. Instagram username"
            />
            <Input
              inputGroupText="@"
              onChange={handleChange}
              label="Facebook"
              name="facebook"
              value={facebook}
              placeholder="i.e. Facebook username"
            />
          </div>

          <div>
            {Object.keys(businessHours).map((key) => (
              <div className="body-card" key={key}>
                <label style={{ textTransform: 'capitalize' }} className="mb-3">
                  {key}
                </label>
                <Checkbox
                  switchMode
                  id="isEnabled"
                  onChange={handleChange}
                  label="Closed"
                  type="checkbox"
                  name={`businessHours-${key}-isClosed`}
                  checked={businessHours[key].isClosed}
                />
                {!businessHours[key].isClosed && (
                  <>
                    <Checkbox
                      switchMode
                      id="is24Hours"
                      onChange={handleChange}
                      label="24 hours"
                      type="checkbox"
                      name={`businessHours-${key}-is24Hours`}
                      checked={businessHours[key].is24Hours}
                    />

                    {!businessHours[key].is24Hours && (
                      <div style={{ display: 'flex' }}>
                        <Input
                          style={{ minWidth: 160 }}
                          onChange={handleChange}
                          label="Opening hour*"
                          type="time"
                          name={`businessHours-${key}-openingHour`}
                          value={businessHours[key].openingHour}
                          placeholder="openingHour"
                          required
                        />
                        <div className="mx-3" style={{ marginTop: '1.2rem' }}>
                          -
                        </div>
                        <Input
                          style={{ minWidth: 160 }}
                          onChange={handleChange}
                          label="Closing hour*"
                          type="time"
                          name={`businessHours-${key}-closingHour`}
                          value={businessHours[key].closingHour}
                          placeholder="closingHour"
                          required
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ChargeStationLocationForm;
