import React, { useState } from 'react';

const HiddenKey = ({ title, hiddenText }: { title: string; hiddenText: string }): JSX.Element => {
  const [visibility, toggleVisibility] = useState(false);
  const [copied, setCopied] = useState(false);

  return (
    <div>
      <strong>
        {title}
        <button className="btn btn-chromeless" onClick={() => toggleVisibility(!visibility)}>
          {visibility ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
        </button>{' '}
      </strong>
      <div
        className="clickable bg-dark text-white p-2 rounded-1 d-flex"
        style={{ flexWrap: 'nowrap' }}
        onClick={() => {
          navigator.clipboard.writeText(hiddenText);
          setCopied(true);
        }}
      >
        {visibility ? (
          <>
            <span className="me-2" style={{ wordBreak: 'break-all' }}>
              {hiddenText}
            </span>{' '}
            <i
              className={`fas fa-clipboard-${copied ? 'check' : 'list'}`}
              style={{ fontSize: '1.2rem' }}
            ></i>
          </>
        ) : (
          'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
        )}
      </div>
    </div>
  );
};

export default HiddenKey;
