import React, { SyntheticEvent } from 'react';
import { Checkbox, Input, TextArea, Form } from '../form';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    bike: string;
    bookingDate: Date | string;
    hasAttended: boolean;
    licenseNumber: string;
    remarks: string;
  };
}

const TestRideForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps } = props;
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    bookingDate,
    hasAttended,
    licenseNumber,
    remarks
  } = formProps;
  return (
    <Form submitting={submitting} onSubmit={onSubmit}>
      <div className="body-card">
        <Checkbox
          id="hasAttended"
          onChange={handleChange}
          label="Has attended"
          name="hasAttended"
          type="checkbox"
          checked={hasAttended}
        ></Checkbox>
        <Input
          onChange={handleChange}
          label="First name*"
          type="text"
          name="firstName"
          value={firstName}
          placeholder="First name"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Last name*"
          type="text"
          name="lastName"
          value={lastName}
          placeholder="Last name"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Email*"
          type="email"
          name="email"
          value={email}
          placeholder="Email"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Phone number*"
          type="phoneNumber"
          name="phoneNumber"
          value={phoneNumber}
          placeholder="Phone number"
          maxLength={10}
          pattern="[1-9]{1}[0-9]{9}"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="License number*"
          type="text"
          name="licenseNumber"
          value={licenseNumber}
          placeholder="License number"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Booking date*"
          type="datetime-local"
          name="bookingDate"
          value={bookingDate}
          placeholder="Booking date"
          required
        ></Input>

        <TextArea
          onChange={handleChange}
          label="Remarks"
          name="remarks"
          value={remarks}
          placeholder="Remarks"
        ></TextArea>
      </div>
    </Form>
  );
};

export default TestRideForm;
