import React, { useRef, useEffect, useState, SyntheticEvent, FormEvent } from 'react';
import { Form, SelectInput, TextArea, Input } from '../form';
import { Customer, Service, Servicing } from '../../interfaces';
import Loader from '../commons/Loader';
import { get } from '../../utils/axios';
import { ServicesForm } from './services-form';
import { TimeSlots } from './time-slots';
import CustomerPeepCard from '../customer-peep-card';

interface Props {
  handleChange: (e: FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: Servicing;
  serviceCenterId: string;
  handleServiceChange: (service: Service, index?: number) => void;
}

const ServicingAppointmentForm = (props: Props): JSX.Element => {
  const {
    onSubmit,
    handleChange,
    handleServiceChange,
    serviceCenterId,
    submitting,
    formProps
  } = props;
  const [timeSlots, setTimeSlot] = useState([]);
  const [timeSlotFetching, setTimeSlotFetching] = useState(false);
  const [customerData, setCustomerData] = useState<Customer | undefined>(undefined);

  const {
    bike,
    customer,
    servicingDate,
    servicingTime,
    servicingType,
    staffRemarks,
    services,
    discount
  } = formProps;

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) firstUpdate.current = false;
    else handleChange({ currentTarget: { name: 'servicingTime', value: '' } } as any);

    if (servicingDate) {
      setTimeSlotFetching(true);
      get(`/service-centers/${serviceCenterId}/appointments/time-slots/${servicingDate}`).then(
        (response) => {
          setTimeSlot(response.data.data.slots);
          setTimeSlotFetching(false);
        }
      );
    }
  }, [servicingDate]);

  const bikes = customerData?.bikes ?? [];

  return (
    <div className="row">
      <div className="col-sm-12 col-md-8 order-2 order-md-1">
        <Form submitting={submitting} onSubmit={onSubmit}>
          <div className="body-card mb-3">
            <Input
              onChange={handleChange}
              label="Customer id*"
              type="text"
              name="customer"
              value={customer}
              placeholder="Customer id"
              required
            />
            <SelectInput
              value={bike}
              onChange={handleChange}
              label="Bike id*"
              placeholder="Bike id"
              name="bike"
              options={[
                { value: '', label: 'Select bike (enter customer id first)' },
                ...bikes.map((b) => ({ value: b._id, label: `${b.bikeName} (${b._id})` }))
              ]}
              required
            />
          </div>
          <div className="body-card mb-3">
            <Input
              onChange={handleChange}
              label="Servicing date*"
              type="date"
              name="servicingDate"
              value={servicingDate}
              placeholder="Choose servicing date"
              required
            />
            {timeSlotFetching ? (
              <Loader />
            ) : (
              <TimeSlots
                handleChange={handleChange}
                timeSlots={timeSlots}
                servicingTime={servicingTime}
              />
            )}
          </div>

          <div className="body-card mb-3">
            <ServicesForm handleServiceChange={handleServiceChange} services={services} />
            <Input
              onChange={handleChange}
              label="Total discount"
              type="text"
              name="discount"
              value={discount}
              placeholder="Total discount"
              required
            />
          </div>

          <div className="body-card mb-3">
            <SelectInput
              value={servicingType}
              onChange={handleChange}
              label="Select appointment type*"
              placeholder="Select appointment type"
              name="servicingType"
              options={[
                { value: '', label: 'Select servicing type' },
                { value: 'Servicing', label: 'Servicing' },
                { value: 'Maintenance', label: 'Maintenance' },
                { value: 'Roadside assistance', label: 'Roadside assistance' }
              ]}
              required
            />

            <TextArea
              name="staffRemarks"
              value={staffRemarks}
              onChange={handleChange}
              label="Staff remarks (not visible to user)"
              placeholder="Staff remarks"
            />
          </div>
        </Form>
      </div>
      <div className="col-sm-12 col-md-4  order-1 order-md-2 mb-3">
        <CustomerPeepCard
          customerId={formProps.customer as string}
          callback={(customerProps) => setCustomerData(customerProps)}
        />
      </div>
    </div>
  );
};

export default ServicingAppointmentForm;
