import React, { SyntheticEvent } from 'react';
import { ENotificationTypes, Notification } from '../../interfaces';
import { Input, Form, TextArea, SelectInput } from '../form';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: Partial<Notification>;
}

const SettingsNotificationsForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps } = props;
  const { title, body, type } = formProps;
  return (
    <Form submitting={submitting} onSubmit={onSubmit}>
      <div className="body-card mb-3">
        <Input
          onChange={handleChange}
          label="Title*"
          type="text"
          name="title"
          value={title}
          placeholder="Title"
          required
        />
      </div>
      <div className="body-card mb-3">
        <TextArea
          onChange={handleChange}
          label="Body*"
          type="text"
          name="body"
          value={body}
          placeholder="Body"
          required
        />
      </div>
      <div className="body-card mb-3">
        <SelectInput
          label="Type*"
          name="type"
          value={type}
          onChange={handleChange}
          options={[
            { label: '-', value: '' },
            ...Object.values(ENotificationTypes).map((type) => ({
              label: type as string,
              value: type as string
            }))
          ]}
          required
        />
      </div>
    </Form>
  );
};

export default SettingsNotificationsForm;
