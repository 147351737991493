import React, { SyntheticEvent, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Alert } from '../../components/alerts';
import { Form, Input } from '../../components/form';
import CustomModal from '../../components/modal';
import Statsbox from '../../components/stats-box';
import { ROLES } from '../../constants';
import AuthorizeView from '../../hoc/authorizeView';
import { post } from '../../utils/axios';

const LoadCreditsModal = ({ customerId }: { customerId: string }): JSX.Element => {
  const [formProps, setFormProps] = useState({
    customerId,
    amount: 0
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setError('');
    setSubmitting(true);

    post(`/wallets/transactions/load-credits`, formProps)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.response.data.message);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, type, checked } = event.currentTarget;
    let { value } = event.currentTarget;
    if (type === 'checkbox') value = checked as any;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.finance]}>
      <Col sm={3} className="mb-3">
        <CustomModal
          headerText="Load wallet credits"
          ModalLauncher={(props) => (
            <button {...props} className="btn-chromeless w-100">
              <Statsbox
                bgClass="dark"
                title="Load credits"
                faIconName="fas fa-hand-holding-usd"
                iconColorClass="text-success"
              />
            </button>
          )}
        >
          {error && <Alert variant="danger">{error}</Alert>}

          <Form onSubmit={onSubmit} submitting={submitting} btnText="Load credits">
            <div className="body-card">
              <Input
                onChange={handleChange}
                label="Amount*"
                type="number"
                name="amount"
                value={formProps.amount}
                placeholder="Amount"
                min="1"
                max="1000"
                required
              />
            </div>
          </Form>
        </CustomModal>
      </Col>
    </AuthorizeView>
  );
};

export default LoadCreditsModal;
