import React, { useState, useEffect } from 'react';
import { BikeTelemetry } from '../../interfaces';
import Loader from '../commons/Loader';
import NotFound from '../commons/NotFound';
import { AxiosResponse } from 'axios';
import { get } from '../../utils/axios';
import VehicleModeOdoChart from '../charts/VehicleModeOdoChart';

interface Props {
  bikeId: string;
}

const BikeTelemetryCard = ({ bikeId }: Props): JSX.Element => {
  const [fetching, setFetching] = useState(true);
  const [bikeTelemetry, setBikeTelemetry] = useState<BikeTelemetry | null>(null);

  useEffect(() => {
    if (!bikeId) return;
    get(`/bikes/${bikeId}/telemetry`)
      .then((response: AxiosResponse) => {
        setBikeTelemetry(response.data.data.bikeTelemetry);
        setFetching(false);
      })
      .catch(() => setFetching(false));
  }, []);

  if (fetching) return <Loader />;
  if (!bikeTelemetry) return <NotFound />;

  const {
    avgSpeed,
    carbonOffset,
    distanceTravelled,
    susteOdo,
    thikkaOdo,
    babbalOdo,
    maxSpeed,
    totalEUnit,
    updatedAt
  } = bikeTelemetry;
  return (
    <>
      <div className="col-lg-7 mb-3">
        <ul className="list-group">
          <li className="list-group-item bg-light">
            <h5>Bike telemetry</h5>
            <small style={{ fontSize: '0.9rem' }}>
              Last updated at:{' '}
              <strong>{updatedAt ? new Date(updatedAt).toLocaleString() : 'NA'}</strong>
            </small>
          </li>
          <li className="list-group-item">Max speed: {maxSpeed} km/hr</li>
          <li className="list-group-item">Avg speed: {avgSpeed} km/hr</li>
          <li className="list-group-item">Odometer: {distanceTravelled} km</li>
          <li className="list-group-item">Suste Odo: {susteOdo} km</li>
          <li className="list-group-item">Thikka Odo: {thikkaOdo} km</li>
          <li className="list-group-item">Babbal Odo: {babbalOdo} km</li>
          <li className="list-group-item">Carbon Offset: {carbonOffset} kg</li>
          <li className="list-group-item">Total electricity consumed: {totalEUnit} kWh</li>
        </ul>
      </div>
      {susteOdo && thikkaOdo && babbalOdo ? (
        <div className="col-lg-5">
          <VehicleModeOdoChart
            vehicleModel="P1"
            data={[
              { name: 'Suste', value: susteOdo },
              { name: 'Thikka', value: thikkaOdo },
              { name: 'Babaal', value: babbalOdo }
            ]}
          />
        </div>
      ) : null}
    </>
  );
};

export default BikeTelemetryCard;
