import React, { SyntheticEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import CustomModal from '../../components/modal';
import { ROLES } from '../../constants';
import AuthorizeView from '../../hoc/authorizeView';
import { withDashboard } from '../../hoc';
import Statsbox from '../../components/stats-box';
import { useDispatch } from 'react-redux';
import { post } from '../../utils/axios';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';
import BikeOwnershipTransferForm from '../../components/bike-transfer-ownership-form';

function BikeOwnershipPage(): JSX.Element {
  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Bike ownership</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="col-md-6">
          <Link to="/bike-ownership/add">
            <Statsbox
              iconColorClass="text-success"
              bgClass="dark"
              title={'Add owner'}
              faIconName="fas fa-user-plus"
            />
          </Link>
        </div>
        <div className="col-md-6">
          <TransferOwnershipModal />
        </div>
      </Row>
    </Container>
  );
}

const TransferOwnershipModal = (): JSX.Element => {
  const [formProps, setFormProps] = useState({
    bikeId: '',
    newOwnerId: '',
    transferDate: new Date().toISOString().split('T')[0]
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    post('/bike-ownership/transfer', formProps)
      .then(() => {
        setSubmitting(false);
        navigate(`/bikes/${formProps.bikeId}`);
      })
      .catch((error) => {
        dispatch(
          pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
        );
        setSubmitting(false);
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  return (
    <AuthorizeView authorizedRoles={[ROLES.admin]}>
      <CustomModal
        headerText="Tranfer vehicle ownership"
        ModalLauncher={(props) => (
          <button {...props} className="btn-chromeless w-100">
            <Statsbox
              iconColorClass="text-warning"
              bgClass="dark"
              title={'Transfer owner'}
              faIconName="fas fa-exchange-alt"
            />
          </button>
        )}
      >
        <BikeOwnershipTransferForm
          submitting={submitting}
          onSubmit={onSubmit}
          handleChange={handleChange}
          formProps={formProps}
        />
      </CustomModal>
    </AuthorizeView>
  );
};

export default withDashboard(BikeOwnershipPage);
