export const toLocalDateAndFormatWithTime = (date: Date | string): string =>
  new Date(date).toLocaleString();

export const getDateOnly = (dateString: string): string => {
  const date = new Date(dateString);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
    date.getDate()
  ).padStart(2, '0')}`;
};

export const getTimeOnly = (dateString: string): string => {
  const date = new Date(dateString);
  return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(
    2,
    '0'
  )}`;
};
