import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { get } from '../../utils/axios';
import { Col } from 'react-bootstrap';
import Statsbox from '../stats-box';
import { Link } from 'react-router-dom';

const ServiceCentersCount = (): JSX.Element => {
  const [serviceCentersCount, setServiceCentersCount] = useState(0);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    get('/service-centers/count', { isEnabled: true }).then((response: AxiosResponse) => {
      const count: number = response.data.data.count;

      setServiceCentersCount(count);

      setFetching(false);
    });
  }, []);

  return (
    <Col xs={6} md={3}>
      <Link to="/service-centers">
        <Statsbox
          iconColorClass="text-warning"
          bgClass="dark"
          fetching={fetching}
          title={serviceCentersCount}
          caption="Total active service centers"
          faIconName="fas fa-wrench"
        />
      </Link>
    </Col>
  );
};

export default ServiceCentersCount;
