import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import BikeModelForm from '../../components/bike-model-form';
import { get, patch } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import Loader from '../../components/commons/Loader';
import { Col, Row } from 'react-bootstrap';
import { BikeModelFormProps } from '../../components/bike-model-form/types';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';
import { formatAxiosError } from '../../utils';

const UpdateBikeModelPage = (): JSX.Element => {
  const [image, setImage] = useState<File | null>(null);

  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const formik = useFormik<BikeModelFormProps>({
    initialValues: {
      _id: '',
      name: '',
      description: '',
      makeYear: new Date().getFullYear(),
      isEnabled: true,
      colors: [],
      currentVCUSoftwareVersion: '',
      currentVCUSoftwareChangeLog: ''
    },
    validationSchema: Yup.object({
      _id: Yup.string()
        .matches(/^[a-zA-Z0-9]*$/, 'Only alpha numeric or hypen is allowed for vehicle model')
        .required('Bike model is required'),
      name: Yup.string().max(20, 'Must be 20 characters or less').required('Name is required'),
      makeYear: Yup.date().required('Make year is required'),
      colors: Yup.array(),
      currentVCUSoftwareVersion: Yup.string()
        .matches(
          /^([(\d+)((\.{1}\d+)*)(\.{0})])*-([A-Za-z0-9,_=|])+$/,
          'Software version should have a format of "VERSION-BOARD_NAME=FILE_SIZE|CRC_NUM,BOARD_NAME=FILE_SIZE|CRC_NUM" i.e. 1.1.0-VCU=243164|98987,COMMUNICATION=220980|34878'
        )
        .required('Current VCU software version is required'),
      currentVCUSoftwareChangeLog: Yup.string().required(),
      description: Yup.string().required('Description is required')
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      setSubmitting(true);

      const formData = new FormData();
      formData.append('image', image as File);

      for (const key in values) {
        Array.isArray((values as any)[key])
          ? (values as any)[key].forEach((value: any) => formData.append(key + '[]', value))
          : formData.append(key, (values as any)[key]);
      }

      patch(`/bike-models/${id}`, formData)
        .then((response: AxiosResponse) => {
          setSubmitting(false);
          dispatch(
            pushToast({ headText: 'Success', bodyText: 'Action successful', variant: 'success' })
          );
          navigate(`/bike-models/${response.data.data.bikeModel._id}`);
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(
            pushToast({ headText: 'Error', bodyText: formatAxiosError(error), variant: 'error' })
          );
        });
    }
  });

  useEffect(() => {
    get(`/bike-models/${id}`)
      .then((response: AxiosResponse) => {
        // setFormProps(response.data.data.bikeModel);
        console.log(response.data.data.bikeModel);
        formik.setValues(response.data.data.bikeModel);
        setFetching(false);
      })
      .catch((error) => {
        setFetchError(error);
        setFetching(false);
      });
  }, []);

  function handleImageChange(event: React.FormEvent<HTMLInputElement>) {
    setImage(event.currentTarget.files![0]);
  }

  if (fetching) return <Loader />;
  if (fetchError) return <Navigate to="/error" />;

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Update Bike Model</h1>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <BikeModelForm
        isUpdate
        formik={formik}
        submitting={submitting}
        onSubmit={formik.handleSubmit}
        handleImageChange={handleImageChange}
        handleChange={formik.handleChange}
        formProps={formik.values}
      />
    </div>
  );
};

export default withDashboard(UpdateBikeModelPage);
