export enum EServicesOutlays {
  yatriFastCharge = 'Yatri fast charge',
  acCharge = 'AC charge',
  ccs = 'CCS',
  vehicleServicing = 'Vehicle servicing',
  vehicleMaintenance = 'Vehicle maintenance',
  store = 'Store'
}

export enum EServicesGains {
  topUp = 'Top Up',
  refund = 'Refund'
}

export type WalletServicesType = EServicesOutlays | EServicesGains;

export enum EPaymentMethods {
  wallet = 'Yatri Wallet',
  khalti = 'Khalti',
  card = 'Card',
  esewa = 'eSewa'
}

export enum EPaymentState {
  pending = 'Pending',
  refunded = 'Refunded',
  completed = 'Completed',
  credited = 'Credited'
}

interface ChargingPayment {
  bike?: string;
  createdAt: Date;
  updatedAt: Date;
  chargeStation: string;
  chargingDock: string;
  eUnit?: number;
  totalChargeTime?: number;
  startingCharge?: number;
  endingCharge?: number;
}

export interface WalletTransaction {
  _id: string;
  walletId: string;
  customerId: string;
  service: WalletServicesType;
  paymentMethod: EPaymentMethods;
  paymentState: EPaymentState;
  totalAmount: number;
  amount: number;
  serviceCharge: number;
  currentBalance: number;
  previousBalance: number;
  createdAt: Date;
  updatedAt: Date;
  thirdPartyTxnId?: string;
  remarks: string;
  chargingPayment?: ChargingPayment;
  createdBy?: string;
}
