import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Loader from '../../components/commons/Loader';
import { BikeModel } from '../../interfaces';
import { withDashboard } from '../../hoc';
import { usePromiseFetch } from '../../hooks';
import AuthorizeView from '../../hoc/authorizeView';
import { ROLES } from '../../constants';

function BikeModelPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const [fetching, response, error] = usePromiseFetch(`/bike-models/${id}`);

  if (fetching) return <Loader />;
  if (!response || error) return <Navigate to="/error" />;

  const bike = response.bikeModel as BikeModel;

  const {
    _id,
    name,
    makeYear,
    description,
    imageUrl,
    currentVCUSoftwareVersion,
    currentVCUSoftwareChangeLog,
    colors
  } = bike;
  return (
    <Container>
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Bike Model</h1>
            </div>
            <AuthorizeView
              authorizedRoles={[ROLES.admin, ROLES.level0, ROLES.productionMaintainer]}
            >
              <div className="head-right">
                <Link to={`${pathname}/edit`} className="btn btn-primary">
                  Update bike model
                </Link>
              </div>
            </AuthorizeView>
          </div>
        </Col>
      </Row>
      <div className="body-card">
        <Row>
          <Col sm="6">
            <img src={imageUrl} className="img-fluid" />
          </Col>
          <Col sm="6">
            <ul className="list-group">
              <li className="list-group-item bg-light">
                <h5>{_id}</h5>
              </li>
              <li className="list-group-item">Name: {name}</li>
              <li className="list-group-item">Make year: {makeYear}</li>

              <li className="list-group-item">
                Colors:
                <ul>
                  {colors.map((color) => (
                    <li key={color}>{color}</li>
                  ))}
                </ul>
              </li>
              <li className="list-group-item">Description: {description}</li>
            </ul>
            <ul className="list-group my-3">
              <li className="list-group-item bg-light">
                <strong>
                  Current VCU software version: v{currentVCUSoftwareVersion?.split('-')[0]}
                </strong>
              </li>
              <li className="list-group-item">
                <ul className="neutralize">
                  <li>
                    <strong>Updated boards:</strong>
                    <ul>
                      {currentVCUSoftwareVersion
                        ?.split('-')[1]
                        .split(',')
                        .map((board) => (
                          <li key={board}>{`${board.split('=')[0]} • ${board.split('=')[1]}`}</li>
                        ))}
                    </ul>
                  </li>
                  <li>
                    <div>
                      <strong>Change logs:</strong>
                    </div>
                    <div style={{ whiteSpace: 'pre-wrap' }}>{currentVCUSoftwareChangeLog}</div>
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default withDashboard(BikeModelPage);
