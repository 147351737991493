export enum ROLES {
  admin = 'ROLE_ADMIN',
  ecStaff = 'ROLE_EC_STAFF',
  serviceCenterStaff = 'ROLE_SERVICE_CENTER_STAFF',
  finance = 'ROLE_FINANCE',
  level1 = 'ROLE_LEVEL_1',
  level0 = 'ROLE_LEVEL_0',
  productionMaintainer = 'ROLE_PRODUCTION_MAINTAINER',
  manager = 'ROLE_MANAGER'
}

export const config = {
  yatrihubBikesS3BucketUrl: 'https://yatrihub.s3.ap-south-1.amazonaws.com/static/bikes'
};
