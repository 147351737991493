import React, { useState } from 'react';
import MonthlyChart from '../../components/monthly-chart';

const AllChargeStationsMonthlyEnergyConsumed = (): JSX.Element => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  function increment() {
    setCurrentYear(currentYear - 1);
  }

  function decrement() {
    setCurrentYear(currentYear + 1);
  }

  return (
    <>
      <MonthlyChart
        label="(kWh) All charge stations total energy consumed"
        url={`/stats/charge-stations/monthly-energy-total/year/${currentYear}`}
        currentYear={currentYear}
        type="Area"
        stroke="#00369c"
        fill="#00369c"
      >
        <h4 className="section-title">
          <button className="btn btn-light" onClick={increment}>
            <i className="fas fa-chevron-left"></i>
          </button>
          &nbsp;
          {currentYear}
          &nbsp;
          <button className="btn btn-light" onClick={decrement}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </h4>
      </MonthlyChart>
    </>
  );
};

export default AllChargeStationsMonthlyEnergyConsumed;
