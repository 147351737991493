import React, { SyntheticEvent } from 'react';
import { TextArea, Input, Checkbox, Form } from '../form';
import { ServiceCenter } from '../../interfaces';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: ServiceCenter;
}

const ServiceCenterForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps } = props;
  const {
    location: { coordinates },
    address,
    openingHour,
    closingHour,
    phoneNumber,
    isEnabled
  } = formProps;
  return (
    <Form submitting={submitting} onSubmit={onSubmit}>
      <div className="body-card">
        <Checkbox
          switchMode
          id="isEnabled"
          onChange={handleChange}
          label="Enabled"
          name="isEnabled"
          type="checkbox"
          checked={isEnabled}
        ></Checkbox>

        <Input
          onChange={handleChange}
          label="Address*"
          type="text"
          name="address"
          value={address}
          placeholder="Address"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Latitude*"
          type="number"
          name="lat"
          value={coordinates[1]}
          placeholder="Latitude"
          required
        ></Input>
        <Input
          onChange={handleChange}
          label="Longitude*"
          type="number"
          name="lng"
          value={coordinates[0]}
          placeholder="Longitude"
          required
        ></Input>

        <Input
          onChange={handleChange}
          label="Phone number*"
          type="text"
          name="phoneNumber"
          value={phoneNumber}
          placeholder="Phone number"
          required
        ></Input>

        <Input
          onChange={handleChange}
          label="Opening hour*"
          type="time"
          name="openingHour"
          value={openingHour}
          placeholder="Opening hour"
          required
        ></Input>

        <Input
          onChange={handleChange}
          label="Closing hour*"
          type="time"
          name="closingHour"
          value={closingHour}
          placeholder="Closing hour"
          required
        ></Input>
      </div>
    </Form>
  );
};

export default ServiceCenterForm;
