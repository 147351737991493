import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { get } from '../../utils/axios';
import { Col } from 'react-bootstrap';
import Statsbox from '../stats-box';
import { Link } from 'react-router-dom';

const ChargeStationsCount = (): JSX.Element => {
  const [chargeStationsCount, setChargeStationsCount] = useState(0);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    get('/charge-stations/count', { isEnabled: true }).then((response: AxiosResponse) => {
      const count: number = response.data.data.count;

      setChargeStationsCount(count);

      setFetching(false);
    });
  }, []);

  return (
    <Col xs={6} md={3}>
      <Link to="/charge-stations?isEnabled=true">
        <Statsbox
          iconColorClass="text-danger"
          bgClass="dark"
          fetching={fetching}
          title={chargeStationsCount}
          caption="Total active charge stations"
          faIconName="fas fa-bolt"
        />
      </Link>
    </Col>
  );
};

export default ChargeStationsCount;
