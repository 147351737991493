import { ChargeStationLocation } from './ChargeStationLocation';

export interface Dock {
  _id?: string;
  isAvailable: boolean;
  isEnabled: boolean;
  type: EChargingTypes;
  capacity: number;
  maxCapacity: number;
  isOutOfService: boolean;
}

export interface ChargeStation {
  _id?: string;
  owner?: string;
  uuid?: string;
  code?: number;
  softwareVersion: string;
  chargeStationLocale: string | ChargeStationLocation;
  docks: Dock[];
  apiKey?: string;
  isEnabled?: boolean;
  remarks?: string;
  status?: EChargeStationStatus;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  isCellularBalanceLow?: boolean;
  currentBalanceInSim?: number;
}

export enum EChargeStationStatus {
  online = 'Online',
  offline = 'Offline',
  underMaintenance = 'Under maintenance',
  outOfService = 'Out of service'
}

export enum EChargingTypes {
  yatriFastCharge = 'Yatri fast charge',
  acCharge = 'AC charge',
  discharge = 'Discharge',
  ccs = 'CCS',
  gbt = 'GBT',
  ccsGbtAc = 'CCS/GBT (AC)'
}
