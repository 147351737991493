import React, { SyntheticEvent } from 'react';
import { ChargeStation, EChargeStationStatus } from '../../interfaces';
import { TextArea, Checkbox, SelectInput, Form, Input } from '../form';

interface Props {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent) => void;
  submitting: boolean;
  formProps: Partial<ChargeStation>;
}

const ChargeStationForm = (props: Props): JSX.Element => {
  const { onSubmit, handleChange, submitting, formProps } = props;
  const { status, isEnabled, softwareVersion, remarks } = formProps;

  return (
    <div className="row">
      <div className="col-sm-12">
        <Form submitting={submitting} onSubmit={onSubmit}>
          <div className="body-card mb-3">
            <Checkbox
              switchMode
              id="isEnabled"
              onChange={handleChange}
              label="Enabled"
              name="isEnabled"
              type="checkbox"
              checked={isEnabled}
            />
          </div>
          <div className="body-card mb-3">
            <SelectInput
              onChange={handleChange}
              label="Status*"
              type="text"
              name="status"
              options={Object.values(EChargeStationStatus).map((s) => ({
                label: s,
                value: s
              }))}
              value={status}
              required
            />
          </div>
          <div className="body-card mb-3">
            <Input
              onChange={handleChange}
              label="Current software version*"
              placeholder="Current software version"
              type="text"
              name="softwareVersion"
              value={softwareVersion}
            />
          </div>

          <div className="body-card mb-3">
            <TextArea
              onChange={handleChange}
              label="Personal remarks"
              type="text"
              name="remarks"
              value={remarks}
              placeholder="Remarks"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ChargeStationForm;
