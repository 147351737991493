import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { get, patch } from '../../utils/axios';
import { withDashboard } from '../../hoc';
import Loader from '../../components/commons/Loader';
import { Col, Row } from 'react-bootstrap';
import UpdateBikeForm from '../../components/bike-form/update-bike-form';
import { formatAxiosError } from '../../utils';
import { useDispatch } from 'react-redux';
import { pushToast } from '../../actions/toast';

const UpdateStaffPage = (): JSX.Element => {
  const [formProps, setFormProps] = useState({
    vinNumber: '',
    chargerNumber: '',
    controllerNumber: '',
    motorNumber: '',
    simNumber: '',
    keyNumber: ''
  });
  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { bikeId } = useParams<{ bikeId: string }>();

  useEffect(() => {
    get(`/bikes/${bikeId}`)
      .then((response: AxiosResponse) => {
        setFormProps(response.data.data.bike);
        setFetching(false);
      })
      .catch((error) => {
        setFetchError(error);
        setFetching(false);
      });
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setSubmitting(true);
    patch(`/bikes/${bikeId}`, formProps)
      .then(() => {
        setSubmitting(false);
        dispatch(
          pushToast({
            headText: 'Success',
            bodyText: 'Action successful',
            variant: 'success'
          })
        );
        navigate(`/bikes/${bikeId}`);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          pushToast({
            headText: 'Error',
            bodyText: formatAxiosError(error),
            variant: 'error'
          })
        );
      });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;

    setFormProps({
      ...formProps,
      [name]: value
    });
  }

  if (fetching) return <Loader />;
  if (fetchError) return <Navigate to="/error" />;

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <h1 className="page-title">Update bike</h1>
              <span className="text-muted">Code: {bikeId}</span>
            </div>
            <div className="head-right"></div>
          </div>
        </Col>
      </Row>
      <UpdateBikeForm
        submitting={submitting}
        onSubmit={onSubmit}
        handleChange={handleChange}
        formProps={formProps}
      />
    </div>
  );
};

export default withDashboard(UpdateStaffPage);
